<script lang="ts" setup>
import { computed } from 'vue';
import type { MenuItemStyle } from '../types/menuItem';
import { cn } from '../helpers/common';

type Props = {
  menuItemStyle?: MenuItemStyle;
  enableContent?: boolean;
  menuItemClass?: string;
  textClass?: string;
  disabled?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  menuItemStyle: 'small',
});

//=================== Styles =====================
const menuItemClasses = computed(() => {
  const classes = getDefaultMenuItemClasses();
  switch (props.menuItemStyle) {
    case 'large':
      classes.push('text-16 p-12 leading-4 bg-light-300 mb-16');
      break;
    case 'small':
      classes.push(
        `h-40 bg-dark-300 p-8 ${!props.disabled ? 'hover:bg-light-100/20' : ''} cursor-pointer items-center`,
      );
      break;
  }
  if (props.menuItemClass) {
    classes.push(props.menuItemClass);
  }
  return cn(...classes);
});

const getDefaultMenuItemClasses = () => {
  return props.enableContent
    ? ['flex', 'flex-col', 'rounded-medium', 'outline-none', 'transition-colors', 'duration-200', 'text-dark-high']
    : [
        'flex',
        'flex-row',
        'items-center',
        'rounded-medium',
        'outline-none',
        'transition-colors',
        'duration-200',
        'text-dark-high',
        'justify-between',
      ];
};

const iConClasses = computed(() => {
  const icons = ['mr-8'];

  switch (props.menuItemStyle) {
    case 'large':
      icons.push('text-primary-300 pt-8');
      break;
    case 'small':
      icons.push('text-dark-high');
      break;
  }
  return icons.join(' ');
});

const textClasses = computed(() => {
  const text = [''];

  switch (props.menuItemStyle) {
    case 'large':
      text.push('text-14 font-medium text-light-high p-4 pb-0');
      break;
    case 'small':
      text.push('pl-0 text-14 font-medium text-dark-high');
      break;
  }
  return text.join(' ');
});

const descriptionClasses = computed(() => {
  const description = ['mr-8'];

  switch (props.menuItemStyle) {
    case 'large':
      description.push('text-12 font-regular text-light-low p-4');
      break;
    case 'small':
      description.push('pl-0 text-12 font-regular text-light-low');
      break;
  }
  return description.join(' ');
});

//=================== End Styles =====================
</script>
<template>
  <div v-if="!enableContent" :class="menuItemClasses" v-bind="$attrs">
    <div class="flex flex-row">
      <div :class="iConClasses">
        <slot name="icon"></slot>
      </div>
      <div class="flex flex-col justify-center">
        <div :class="cn(textClasses, textClass)"><slot name="text"></slot></div>
        <div :class="descriptionClasses"><slot name="description"></slot></div>
      </div>
    </div>
    <slot name="action"></slot>
  </div>
  <div v-else :class="menuItemClasses" v-bind="$attrs">
    <div class="flex justify-between">
      <div class="flex flex-row">
        <div :class="iConClasses">
          <slot name="icon"></slot>
        </div>
        <div class="flex flex-col justify-center">
          <div :class="cn(textClasses, textClass)"><slot name="text"></slot></div>
          <div :class="descriptionClasses"><slot name="description"></slot></div>
        </div>
      </div>
      <slot name="action"></slot>
    </div>
    <slot name="content"></slot>
  </div>
</template>
