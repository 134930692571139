<template>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M10.5045 3C9.67604 3 9.00447 3.67157 9.00447 4.5V12.5C9.00447 13.3284 9.67604 14 10.5045 14H12.5045C13.3329 14 14.0045 13.3284 14.0045 12.5V4.5C14.0045 3.67157 13.3329 3 12.5045 3H10.5045ZM10.0045 4.5C10.0045 4.22386 10.2283 4 10.5045 4H12.5045C12.7806 4 13.0045 4.22386 13.0045 4.5V12.5C13.0045 12.7761 12.7806 13 12.5045 13H10.5045C10.2283 13 10.0045 12.7761 10.0045 12.5V4.5Z"
    fill="#757575" />
  <path
    d="M4.11099 5.90396C4.3021 6.10328 4.29545 6.41979 4.09612 6.61091C3.86489 6.83262 3.30727 7.39012 2.80211 7.89767L2.70031 8L7.50057 8C7.77671 8 8.00057 8.22386 8.00057 8.5C8.00057 8.77614 7.77671 9 7.50057 9L2.70054 9L2.80213 9.10213C3.30729 9.60976 3.86491 10.1674 4.09616 10.3891C4.29546 10.5803 4.30209 10.8968 4.11095 11.0961C3.91982 11.2954 3.60331 11.302 3.404 11.1109C3.16229 10.8791 2.59491 10.3116 2.0933 9.80751C1.84132 9.55429 1.60417 9.31529 1.43003 9.13963L1.14493 8.85177C0.952228 8.65695 0.951907 8.34298 1.14462 8.14818L1.43006 7.86002C1.6042 7.68438 1.84135 7.44543 2.09334 7.19224C2.59494 6.68826 3.16233 6.12085 3.40404 5.88909C3.60336 5.69798 3.91987 5.70463 4.11099 5.90396Z"
    fill="#757575" />
</template>
