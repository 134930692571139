<template>
  <path
    fill="currentColor"
    d="M9.99988 1.23901C10.3451 1.23901 10.6249 1.51884 10.6249 1.86401V3.61401C10.6249 3.95919 10.3451 4.23901 9.99988 4.23901C9.6547 4.23901 9.37488 3.95919 9.37488 3.61401V1.86401C9.37488 1.51884 9.6547 1.23901 9.99988 1.23901Z" />
  <path
    fill="currentColor"
    d="M7.49988 16.875C7.49988 16.5298 7.7797 16.25 8.12488 16.25H11.8749C12.2201 16.25 12.4999 16.5298 12.4999 16.875C12.4999 17.2202 12.2201 17.5 11.8749 17.5H8.12488C7.7797 17.5 7.49988 17.2202 7.49988 16.875Z" />
  <path
    fill="currentColor"
    d="M1.96338 8.90076C1.6182 8.90076 1.33838 9.18058 1.33838 9.52576C1.33838 9.87093 1.6182 10.1508 1.96338 10.1508H3.96338C4.30856 10.1508 4.58838 9.87093 4.58838 9.52576C4.58838 9.18058 4.30856 8.90076 3.96338 8.90076H1.96338Z" />
  <path
    fill="currentColor"
    d="M15.4111 9.52576C15.4111 9.18058 15.6909 8.90076 16.0361 8.90076H18.0361C18.3813 8.90076 18.6611 9.18058 18.6611 9.52576C18.6611 9.87093 18.3813 10.1508 18.0361 10.1508H16.0361C15.6909 10.1508 15.4111 9.87093 15.4111 9.52576Z" />
  <path
    fill="currentColor"
    d="M14.3415 5.2507C14.0975 5.49478 14.0975 5.89051 14.3415 6.13459C14.5856 6.37867 14.9813 6.37867 15.2254 6.13459L16.4628 4.89715C16.7069 4.65307 16.7069 4.25735 16.4628 4.01327C16.2188 3.76919 15.823 3.76919 15.579 4.01327L14.3415 5.2507Z" />
  <path
    fill="currentColor"
    d="M5.65753 6.13433C5.90161 5.89026 5.90161 5.49453 5.65753 5.25045L4.42009 4.01301C4.17601 3.76894 3.78029 3.76894 3.53621 4.01301C3.29213 4.25709 3.29213 4.65282 3.53621 4.8969L4.77364 6.13433C5.01772 6.37841 5.41345 6.37841 5.65753 6.13433Z" />
  <path
    fill="currentColor"
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M9.99976 5.17151C7.51447 5.17151 5.49976 7.18623 5.49976 9.67151C5.49976 11.1779 6.24025 12.5108 7.37476 13.3269V15.0125C7.37476 15.3577 7.65458 15.6375 7.99976 15.6375H11.9998C12.3449 15.6375 12.6248 15.3577 12.6248 15.0125V13.3269C13.7593 12.5108 14.4998 11.1779 14.4998 9.67151C14.4998 7.18623 12.485 5.17151 9.99976 5.17151ZM6.74976 9.67151C6.74976 7.87658 8.20483 6.42151 9.99976 6.42151C11.7947 6.42151 13.2498 7.87658 13.2498 9.67151C13.2498 10.852 12.6208 11.8861 11.6767 12.4561C11.4893 12.5693 11.3748 12.7722 11.3748 12.9912V14.3875H8.62476V12.9912C8.62476 12.7722 8.51021 12.5693 8.3228 12.4561C7.37872 11.8861 6.74976 10.852 6.74976 9.67151Z" />
</template>
