<template>
  <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.49995 2.99741L14.4999 2.99609C14.7761 2.99606 15 3.2199 15 3.49604C15 3.77218 14.7762 3.99606 14.5001 3.99609L2.50005 3.99741C2.22391 3.99744 2.00003 3.77361 2 3.49746C1.99997 3.22132 2.2238 2.99744 2.49995 2.99741Z"
      fill="currentColor" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.49995 5.99936L14.4999 5.99805C14.7761 5.99802 15 6.22185 15 6.49799C15 6.77413 14.7762 6.99802 14.5001 6.99805L2.50005 6.99936C2.22391 6.99939 2.00003 6.77556 2 6.49942C1.99997 6.22327 2.2238 5.99939 2.49995 5.99936Z"
      fill="currentColor" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.49995 12.0029L11.5045 12.002C11.7806 12.0019 12.0045 12.2258 12.0045 12.5019C12.0045 12.778 11.7807 13.0019 11.5046 13.002L2.50005 13.0029C2.22391 13.003 2.00003 12.7791 2 12.503C1.99997 12.2269 2.2238 12.003 2.49995 12.0029Z"
      fill="currentColor" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.49995 9.00131L14.4999 9C14.7761 8.99997 15 9.2238 15 9.49995C15 9.77609 14.7762 9.99997 14.5001 10L2.50005 10.0013C2.22391 10.0013 2.00003 9.77751 2 9.50137C1.99997 9.22523 2.2238 9.00134 2.49995 9.00131Z"
      fill="currentColor" />
  </svg>
</template>
