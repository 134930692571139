<template>
  <path
    d="M11.4022 5.75354C11.5757 5.53872 11.5422 5.22391 11.3274 5.0504C11.1126 4.87688 10.7978 4.91037 10.6243 5.12519L7.01317 9.59591L5.3089 8.04725C5.10453 7.86155 4.78831 7.87667 4.6026 8.08104C4.4169 8.28541 4.43202 8.60163 4.63639 8.78734L6.73287 10.6924C6.83475 10.785 6.97029 10.8315 7.10753 10.8209C7.24478 10.8103 7.3716 10.7436 7.4581 10.6365L11.4022 5.75354Z"
    fill="currentColor" />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M3.05025 3.05025C5.78392 0.316582 10.2161 0.316582 12.9497 3.05025C15.6834 5.78392 15.6834 10.2161 12.9497 12.9497C10.2161 15.6834 5.78392 15.6834 3.05025 12.9497C0.316582 10.2161 0.316582 5.78392 3.05025 3.05025ZM3.75736 3.75736C6.10051 1.41421 9.89949 1.41421 12.2426 3.75736C14.5858 6.10051 14.5858 9.89949 12.2426 12.2426C9.89949 14.5858 6.10051 14.5858 3.75736 12.2426C1.41421 9.89949 1.41421 6.10051 3.75736 3.75736Z"
    fill="currentColor" />
</template>
