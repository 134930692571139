<template>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M6.50426 3L3.00001 3L3.00001 6.50425L6.50426 6.50425L6.50426 3ZM3.00001 2C2.44773 2 2.00001 2.44771 2.00001 3V6.50425C2.00001 7.05653 2.44772 7.50425 3.00001 7.50425H6.50426C7.05654 7.50425 7.50426 7.05654 7.50426 6.50425V3C7.50426 2.44772 7.05655 2 6.50426 2H3.00001Z" />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M13.0043 3L9.5 3L9.5 6.50425L13.0042 6.50425L13.0043 3ZM9.5 2C8.94772 2 8.5 2.44771 8.5 3V6.50425C8.5 7.05653 8.94771 7.50425 9.5 7.50425H13.0042C13.5565 7.50425 14.0042 7.05654 14.0042 6.50425V3C14.0042 2.44772 13.5565 2 13.0043 2H9.5Z" />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M6.50425 9.49571L3 9.49571L3 13L6.50425 13L6.50425 9.49571ZM3 8.49571C2.44772 8.49571 2 8.94343 2 9.49571V13C2 13.5522 2.44771 14 3 14H6.50425C7.05653 14 7.50425 13.5523 7.50425 13V9.49571C7.50425 8.94343 7.05654 8.49571 6.50425 8.49571H3Z" />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M13.0043 9.49573L9.5 9.49573L9.5 13L13.0042 13L13.0043 9.49573ZM9.5 8.49573C8.94772 8.49573 8.5 8.94344 8.5 9.49573V13C8.5 13.5523 8.94771 14 9.5 14H13.0042C13.5565 14 14.0042 13.5523 14.0042 13V9.49573C14.0042 8.94344 13.5565 8.49573 13.0043 8.49573H9.5Z" />
</template>
