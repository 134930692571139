<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7 5.05862C6.72386 5.05862 6.5 5.28248 6.5 5.55862C6.5 5.83477 6.72386 6.05862 7 6.05862H9C9.27614 6.05862 9.5 5.83477 9.5 5.55862C9.5 5.28248 9.27614 5.05862 9 5.05862H7Z"
      fill="currentColor" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.60645 3.23828C1.77802 3.23828 1.10645 3.90985 1.10645 4.73828V11.2627C1.10645 12.0911 1.77802 12.7627 2.60644 12.7627H13.3931C14.2215 12.7627 14.8931 12.0911 14.8931 11.2627V4.73828C14.8931 3.90985 14.2215 3.23828 13.3931 3.23828H2.60645ZM2.10645 4.73828C2.10645 4.46214 2.3303 4.23828 2.60645 4.23828H13.3931C13.6692 4.23828 13.8931 4.46214 13.8931 4.73828V11.2627C13.8931 11.5389 13.6692 11.7627 13.3931 11.7627H2.60644C2.3303 11.7627 2.10645 11.5389 2.10645 11.2627V4.73828Z"
      fill="currentColor" />
  </svg>
</template>
