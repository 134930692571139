<script lang="ts" setup>
import GBaseIcon from '../../base/GBaseIcon.vue';
import GDropdown from '../../elements/Dropdown.vue';
import GTooltip from '../../base/GTooltip.vue';
import GInput from '../../base/GInput.vue';
import { computed } from 'vue';

type Props = {
  pageType?: string;
  pageName?: string;
};

const props = withDefaults(defineProps<Props>(), {
  pageType: '',
  pageName: 'Untitled',
});

const emit = defineEmits<{
  (e: 'back'): void;
  (e: 'rename', name: string): void;
}>();

const pageTypeName = computed(() => {
  return upperFirstLetter(props.pageType);
});

const upperFirstLetter = (s?: string) => {
  return (s?.charAt(0)?.toUpperCase() ?? '') + (s?.slice(1)?.toLowerCase() ?? '');
};

const onBack = () => {
  emit('back');
};

const onChangePageName = (pageName: string, onClose: Function) => {
  onClose();
  handleChangePageName(pageName);
};

const handleChangePageName = (value: string) => {
  emit('rename', value);
};
</script>

<template>
  <div class="rounded-medium inline-flex h-40 max-w-[326px] items-center justify-center after:flex-row">
    <template v-if="pageType">
      <g-tooltip placement="bottom" class="flex py-4 pl-4">
        <div
          class="rounded-medium flex h-32 cursor-pointer select-none items-center whitespace-nowrap transition-all duration-200"
          @click="onBack">
          <span class="text-14 text-dark-low font-regular px-8 text-center">{{ pageTypeName }}</span>
        </div>
        <template #content>
          <span class="text-12 flex items-center">Back to {{ pageType?.toLowerCase() }} list</span>
        </template>
      </g-tooltip>

      <div class="flex items-center px-4">
        <span class="text-dark-low font-regular inline-flex text-[14px]"> / </span>
      </div>
    </template>
    <div class="static flex items-center">
      <g-dropdown trigger="click" placement="left">
        <template #default>
          <div class="rounded-medium flex h-32 max-w-[100px] items-center min-[1200px]:max-w-[223px]">
            <span class="text-14 text-dark-high font-regular cursor-pointer truncate text-center">
              {{ pageName }}
            </span>
          </div>
        </template>
        <template #content="{ close }">
          <div class="w-[320px] px-4 sm:px-0">
            <div class="rounded-t-medium flex h-40 flex-row items-center justify-between overflow-hidden">
              <h3 class="text-14 text-dark-high p-12 font-semibold">Page Title</h3>
              <span
                class="text-dark-low hover:text-dark-high cursor-pointer p-14 transition-colors duration-200"
                @click="close">
                <g-base-icon name="close-tooltip" view-box="0 0 16 16" width="16" height="16"></g-base-icon>
              </span>
            </div>
            <div class="p-12">
              <g-input
                class="outline-none"
                input-style="secondary"
                size="medium"
                type="text"
                :value="pageName"
                @change="(text) => onChangePageName(text, close)"></g-input>
            </div>
          </div>
        </template>
      </g-dropdown>
      <slot name="more"></slot>
    </div>
  </div>
</template>
