<template>
  <Transition
    @before-appear="beforeAppear"
    @appear="appear"
    @after-appear="afterAppear"
    @appear-cancelled="appearCancelled"
    @before-enter="beforeEnter"
    @enter="enter"
    @after-enter="afterEnter"
    @enter-cancelled="enterCancelled"
    @before-leave="beforeLeave"
    @leave="leave"
    @after-leave="afterLeave"
    @leave-cancelled="leaveCancelled">
    <slot></slot>
  </Transition>
</template>
<script setup lang="ts">
import type { RendererElement } from 'vue';
import { watch, computed, ref } from 'vue';
type Props = {
  dimension?: 'height' | 'width';
  duration?: number;
  easing?: string;
};
const props = withDefaults(defineProps<Props>(), {
  duration: 300,
  easing: 'ease-in-out',
  dimension: 'height',
});
const cachedStyles = ref<{ [key: string]: any } | null>();
const emit = defineEmits<{
  (e: 'before-appear', el: RendererElement): void;
  (e: 'appear', el: RendererElement, done?: () => void): void;
  (e: 'leave', el: RendererElement, done: () => void): void;
  (e: 'enter', el: RendererElement, done: () => void): void;
  (e: 'after-appear', el: RendererElement): void;
  (e: 'appear-cancelled', el: RendererElement): void;
  (e: 'before-enter', el: RendererElement): void;
  (e: 'after-enter', el: RendererElement): void;
  (e: 'enter-cancelled', el: RendererElement): void;
  (e: 'before-leave', el: RendererElement): void;
  (e: 'after-leave', el: RendererElement): void;
  (e: 'leave-cancelled', el: RendererElement): void;
}>();
const transition = computed(() => {
  const transitions: string[] = [];
  Object.keys(cachedStyles.value ?? {}).forEach((key) => {
    transitions.push(`${convertToCssProperty(key)} ${props.duration}ms ${props.easing}`);
  });
  return transitions.join(', ');
});
watch(
  () => props.dimension,
  () => {
    clearCachedDimensions();
  },
);
function beforeAppear(el: RendererElement) {
  // Emit the event to the parent
  emit('before-appear', el);
}
function appear(el: RendererElement) {
  // Emit the event to the parent
  emit('appear', el);
}
function afterAppear(el: RendererElement) {
  // Emit the event to the parent
  emit('after-appear', el);
}
function appearCancelled(el: RendererElement) {
  // Emit the event to the parent
  emit('appear-cancelled', el);
}
function beforeEnter(el: RendererElement) {
  // Emit the event to the parent
  emit('before-enter', el);
}
function enter(el: RendererElement, done: () => void) {
  // Because width and height may be 'auto',
  // first detect and cache the dimensions
  detectAndCacheDimensions(el);
  // The order of applying styles is important:
  // - 1. Set styles for state before transition
  // - 2. Force repaint
  // - 3. Add transition style
  // - 4. Set styles for state after transition
  // If the order is not right and you open any 2nd level submenu
  // for the first time, the transition will not work.
  setClosedDimensions(el);
  hideOverflow(el);
  forceRepaint(el);
  setTransition(el);
  setOpenedDimensions(el);
  // Emit the event to the parent
  emit('enter', el, done);
  // Call done() when the transition ends
  // to trigger the @after-enter event.
  setTimeout(done, props.duration);
}
function afterEnter(el: RendererElement) {
  // Clean up inline styles
  unsetOverflow(el);
  unsetTransition(el);
  unsetDimensions(el);
  clearCachedDimensions();
  // Emit the event to the parent
  emit('after-enter', el);
}
function enterCancelled(el: RendererElement) {
  // Emit the event to the parent
  emit('enter-cancelled', el);
}
function beforeLeave(el: RendererElement) {
  // Emit the event to the parent
  emit('before-leave', el);
}
function leave(el: RendererElement, done: () => void) {
  // For some reason, @leave triggered when starting
  // from open state on page load. So for safety,
  // check if the dimensions have been cached.
  detectAndCacheDimensions(el);
  // The order of applying styles is less important
  // than in the enter phase, as long as we repaint
  // before setting the closed dimensions.
  // But it is probably best to use the same
  // order as the enter phase.
  setOpenedDimensions(el);
  hideOverflow(el);
  forceRepaint(el);
  setTransition(el);
  setClosedDimensions(el);
  // Emit the event to the parent
  emit('leave', el, done);
  // Call done() when the transition ends
  // to trigger the @after-leave event.
  // This will also cause v-show
  // to reapply 'display: none'.
  setTimeout(done, props.duration);
}
function afterLeave(el: RendererElement) {
  // Clean up inline styles
  unsetOverflow(el);
  unsetTransition(el);
  unsetDimensions(el);
  clearCachedDimensions();
  // Emit the event to the parent
  emit('after-leave', el);
}
function leaveCancelled(el: RendererElement) {
  // Emit the event to the parent
  emit('leave-cancelled', el);
}
function detectAndCacheDimensions(el: RendererElement) {
  // Cache actual dimensions
  // only once to void invalid values when
  // triggering during a transition
  if (cachedStyles.value) return;
  const visibility = el.style.visibility;
  const display = el.style.display;
  // Trick to get the width and
  // height of a hidden element
  el.style.visibility = 'hidden';
  el.style.display = '';
  cachedStyles.value = detectRelevantDimensions(el);
  // Restore any original styling
  el.style.visibility = visibility;
  el.style.display = display;
}
function clearCachedDimensions() {
  cachedStyles.value = null;
}
function detectRelevantDimensions(el: RendererElement) {
  // These properties will be transitioned
  if (props.dimension === 'height') {
    return {
      height: el.offsetHeight + 'px',
      paddingTop: el.style.paddingTop || getCssValue(el, 'padding-top'),
      paddingBottom: el.style.paddingBottom || getCssValue(el, 'padding-bottom'),
    };
  }
  if (props.dimension === 'width') {
    return {
      width: el.offsetWidth + 'px',
      paddingLeft: el.style.paddingLeft || getCssValue(el, 'padding-left'),
      paddingRight: el.style.paddingRight || getCssValue(el, 'padding-right'),
    };
  }
  return {};
}
function setTransition(el: RendererElement) {
  el.style.transition = transition.value;
}
function unsetTransition(el: RendererElement) {
  el.style.transition = '';
}
function hideOverflow(el: RendererElement) {
  el.style.overflow = 'hidden';
}
function unsetOverflow(el: RendererElement) {
  el.style.overflow = '';
}
function setClosedDimensions(el: RendererElement) {
  Object.keys(cachedStyles.value ?? {}).forEach((key) => {
    el.style[key] = '0';
  });
}
function setOpenedDimensions(el: RendererElement) {
  Object.keys(cachedStyles.value ?? {}).forEach((key) => {
    el.style[key] = cachedStyles.value?.[key];
  });
}
function unsetDimensions(el: RendererElement) {
  Object.keys(cachedStyles.value ?? {}).forEach((key) => {
    el.style[key] = '';
  });
}
function forceRepaint(el: RendererElement) {
  // Force repaint to make sure the animation is triggered correctly.
  // Thanks: https://markus.oberlehner.net/blog/transition-to-height-auto-with-vue/
  if (props.dimension) getComputedStyle(el as Element)[props.dimension];
}
function getCssValue(el: RendererElement, style: string) {
  return getComputedStyle(el as Element, null).getPropertyValue(style);
}
function convertToCssProperty(style: string) {
  // Example: convert 'paddingTop' to 'padding-top'
  // Thanks: https://gist.github.com/tan-yuki/3450323
  const upperChars = style.match(/([A-Z])/g);
  if (!upperChars) {
    return style;
  }
  for (let i = 0, n = upperChars.length; i < n; i++) {
    style = style.replace(new RegExp(upperChars[i]), '-' + upperChars[i].toLowerCase());
  }
  if (style.slice(0, 1) === '-') {
    style = style.slice(1);
  }
  return style;
}
</script>
