<template>
  <path
    d="M7.02311 4C6.36526 4 5.85645 4.45433 5.85645 5.16635C5.85645 5.44249 5.63259 5.66635 5.35645 5.66635C5.0803 5.66635 4.85645 5.44249 4.85645 5.16635C4.85645 3.86625 5.84946 3 7.02311 3C8.24842 3 9.27006 3.87515 9.27006 5.16635C9.27006 5.63873 9.07227 6.03866 8.8485 6.34916C8.62544 6.65868 8.34929 6.91638 8.13981 7.10543C7.6614 7.53716 7.52343 8.01099 7.52344 8.49999C7.52344 8.77613 7.29959 8.99999 7.02345 9C6.74731 9.00001 6.52344 8.77616 6.52344 8.50001C6.52342 7.7814 6.74469 7.01744 7.46985 6.36303C7.67483 6.17805 7.88129 5.98088 8.03722 5.7645C8.19246 5.54909 8.27006 5.3521 8.27006 5.16635C8.27006 4.48981 7.76064 4 7.02311 4Z"
    fill="currentColor" />
  <path
    d="M7.02344 10C6.74729 10 6.52344 10.2239 6.52344 10.5C6.52344 10.7761 6.74729 11 7.02344 11C7.29958 11 7.52344 10.7761 7.52344 10.5C7.52344 10.2239 7.29958 10 7.02344 10Z"
    fill="currentColor" />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M0 7C0 3.13401 3.13401 0 7 0C10.866 0 14 3.13401 14 7C14 10.866 10.866 14 7 14C3.13401 14 0 10.866 0 7ZM7 1C3.68629 1 1 3.68629 1 7C1 10.3137 3.68629 13 7 13C10.3137 13 13 10.3137 13 7C13 3.68629 10.3137 1 7 1Z"
    fill="currentColor" />
</template>
