<script lang="ts" setup>
import { ref, watch } from 'vue';

type CheckBoxData = {
  id?: string;
  value?: boolean;
  name?: string;
  countItem?: number;
  isParent?: boolean;
  isCheckedAll?: boolean;
  parentId?: string;
};

type MultiCheckBoxProps = {
  data: CheckBoxData[];
  defaultValue?: string[];
  classes?: string;
  isComingSoon?: boolean;
  upcomingDesc: string;
};

const emit = defineEmits<{
  (e: 'clear'): void;
  (e: 'change', ids?: string[]): void;
}>();

const props = withDefaults(defineProps<MultiCheckBoxProps>(), {
  data: () => [],
});

const dataSrc = ref(props.data);

const change = (index: number, value: boolean, isParent?: boolean) => {
  if (dataSrc.value?.[index]) {
    dataSrc.value[index].value = value;
    if (isParent) {
      const parentID = dataSrc.value?.[index]?.id;

      const cloneData = dataSrc.value.map((e) => {
        if (e.parentId === parentID) {
          e.value = value;
        }
        return e;
      });
      dataSrc.value = cloneData;
    } else {
      const parentID = dataSrc.value?.[index]?.parentId;
      const parentIndex = dataSrc.value?.findIndex((e) => e?.id === parentID);
      const child = dataSrc.value?.filter((e) => e?.parentId === parentID);

      if (dataSrc.value?.[parentIndex]) {
        if (child.some((e) => e?.value === true)) {
          dataSrc.value[parentIndex].value = true;
        } else dataSrc.value[parentIndex].value = false;

        if (child.every((e) => e?.value === true)) {
          dataSrc.value[parentIndex].isCheckedAll = true;
        } else dataSrc.value[parentIndex].isCheckedAll = false;
      }
    }
  }

  const categoriesChecked = dataSrc.value?.filter((e) => e?.value === true);
  const filterUseCaseIds = categoriesChecked?.map((e) => e?.id ?? '');
  emit('change', filterUseCaseIds);
};

watch(
  () => props.data,
  (v) => {
    dataSrc.value = v;
  },
);

const checkMinus = (parentID?: string) => {
  const children = dataSrc.value?.filter((e) => e.parentId === parentID);
  return children?.every((d) => d.value === true);
};
</script>
<template>
  <div class="w-full pb-24">
    <slot name="title"></slot>
    <div v-if="isComingSoon" class="text-light-low text-12 font-regular leading-[150%]">
      {{
        upcomingDesc ||
        'The suggested use cases will help you choose a suitable template for your sales campaign easier'
      }}
    </div>
    <div
      v-for="(item, index) in dataSrc"
      v-else
      :key="index"
      class="flex w-full select-none items-center align-baseline"
      :class="[item.isParent && dataSrc?.[index - 1]?.isParent ? 'mt-12' : item.isParent ? 'mt-24' : 'mt-8']">
      <label
        :for="`multi-checkbox-value-${index}`"
        :class="[
          classes,
          'text-14 relative ml-0 flex cursor-pointer items-center',
          item.isParent
            ? 'text-light-high [&>span]:hover:bg-dark-high [&>span]:hover:border-light-low: font-medium'
            : 'text-light-low font-regular [&>span]:hover:bg-dark-high  [&>span]:hover:border-light-low hover:text-light-high ',
        ]">
        <input
          :id="`multi-checkbox-value-${index}`"
          class="gemx-checkbox peer h-16 w-16"
          type="checkbox"
          :name="item.name"
          :value="item.value"
          :checked="item.value"
          @change="change(index, ($event.target as HTMLInputElement).checked, item.isParent)" />
        <span
          class="gemx-checkmark peer-checked:bg-primary-300 bg-light-100 mr-8 flex items-center justify-center peer-checked:border-none [&>svg]:hidden peer-checked:[&>svg]:block">
          <svg
            v-if="item?.isParent && !checkMinus(item.id)"
            width="10"
            height="4"
            viewBox="0 0 10 4"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <rect x="0.625" y="0.90625" width="8.75" height="2.1875" rx="1.09375" fill="white" />
          </svg>
          <svg v-else width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M10.4121 1.32094C10.9023 1.66406 11.0215 2.33955 10.6784 2.8297L5.59409 10.0926C5.59376 10.0931 5.59343 10.0936 5.5931 10.094C5.01714 10.92 3.88074 11.1234 3.05403 10.548C2.89348 10.4363 2.75194 10.2995 2.63483 10.1428C2.63451 10.1424 2.63419 10.142 2.63388 10.1415L1.34177 8.41897C0.982756 7.94034 1.07972 7.2613 1.55834 6.90229C2.03697 6.54327 2.71601 6.64024 3.07502 7.11886L4.08679 8.4677L8.90339 1.58716C9.24651 1.09701 9.922 0.977821 10.4121 1.32094ZM4.3704 8.84581L4.36936 8.84442L4.3704 8.84581Z"
              fill="white" />
          </svg>
        </span>
        <div class="truncate">
          {{ item.name }}
        </div>
      </label>
      <div
        v-if="item.countItem"
        :class="['text-light-high bg-light-400 text-10  ml-auto rounded-[6px] px-4 leading-3']">
        {{ item.countItem }}
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.gemx-checkbox {
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.gemx-checkmark {
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  border: 1.25px solid #9e9e9e;
  border-radius: 3px;
}
.gemx-checkbox:checked {
  background: white;
}
</style>
