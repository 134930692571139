<script lang="ts" setup>
import { computed } from 'vue';

type Props = {
  type?: 'primary' | 'warning';
};

const props = defineProps<Props>();

const classes = computed(() => {
  const { type } = props;
  const className: string[] = [];

  switch (type) {
    case 'primary':
      className.push('bg-primary-325 text-white');
      break;
    case 'warning':
      className.push('bg-amber-300 text-light-high');
      break;
  }

  return className.join(' ');
});
</script>

<template>
  <span class="rounded-medium text-12 inline-block px-4 font-medium" :class="classes">
    <slot></slot>
  </span>
</template>
