<template>
  <path
    d="M3 3C3 2.72386 3.22386 2.5 3.5 2.5H12.5C12.7761 2.5 13 2.72386 13 3C13 3.27614 12.7761 3.5 12.5 3.5H3.5C3.22386 3.5 3 3.27614 3 3Z"
    fill="#676767" />
  <path
    d="M8.51067 5.02167C8.51067 4.74553 8.28682 4.52167 8.01067 4.52167C7.73453 4.52167 7.51067 4.74553 7.51067 5.02167L7.51067 9.83178L7.50379 9.82493C7.05243 9.3757 6.55743 8.88058 6.36091 8.67562C6.16979 8.4763 5.85328 8.46965 5.65396 8.66076C5.45463 8.85188 5.44798 9.16839 5.63909 9.36771C5.84565 9.58314 6.35057 10.088 6.79836 10.5337C7.02344 10.7577 7.23588 10.9686 7.39202 11.1234L7.6479 11.3768C7.7467 11.4746 7.87638 11.523 8.00531 11.5216L8.01067 11.5217C8.17931 11.5217 8.32845 11.4382 8.41901 11.3103L8.60767 11.1234C8.76383 10.9686 8.9763 10.7578 9.20142 10.5337C9.64928 10.0881 10.1543 9.58318 10.3609 9.36775C10.552 9.16844 10.5454 8.85193 10.3461 8.66079C10.1468 8.46966 9.83026 8.47628 9.63913 8.67559C9.44468 8.87835 8.95812 9.36503 8.51067 9.81034L8.51067 5.02167Z"
    fill="#676767" />
  <path
    d="M4.5 12.5C4.22386 12.5 4 12.7239 4 13C4 13.2761 4.22386 13.5 4.5 13.5H5.375C5.65114 13.5 5.875 13.2761 5.875 13C5.875 12.7239 5.65114 12.5 5.375 12.5H4.5Z"
    fill="#676767" />
  <path
    d="M7.125 12.5C6.84886 12.5 6.625 12.7239 6.625 13C6.625 13.2761 6.84886 13.5 7.125 13.5H8.875C9.15114 13.5 9.375 13.2761 9.375 13C9.375 12.7239 9.15114 12.5 8.875 12.5H7.125Z"
    fill="#676767" />
  <path
    d="M10.625 12.5C10.3489 12.5 10.125 12.7239 10.125 13C10.125 13.2761 10.3489 13.5 10.625 13.5H11.5C11.7761 13.5 12 13.2761 12 13C12 12.7239 11.7761 12.5 11.5 12.5H10.625Z"
    fill="#676767" />
</template>
