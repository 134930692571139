<template>
  <path
    fill="currentColor"
    d="M10 1.875C10.3452 1.875 10.625 2.15482 10.625 2.5L10.625 17.5C10.625 17.8452 10.3452 18.125 10 18.125C9.65482 18.125 9.375 17.8452 9.375 17.5L9.375 2.5C9.375 2.15482 9.65482 1.875 10 1.875Z" />
  <path
    fill="currentColor"
    d="M16.6918 6.46455C16.9359 6.70863 16.9359 7.10436 16.6918 7.34844L14.0401 10.0001L16.6918 12.6517C16.9359 12.8958 16.9359 13.2915 16.6918 13.5356C16.4477 13.7797 16.052 13.7797 15.8079 13.5356L12.7143 10.442C12.4702 10.1979 12.4702 9.80222 12.7143 9.55814L15.8079 6.46455C16.052 6.22047 16.4477 6.22047 16.6918 6.46455Z" />
  <path
    fill="currentColor"
    d="M4.19194 6.46455C3.94786 6.22047 3.55214 6.22047 3.30806 6.46455C3.06398 6.70863 3.06398 7.10436 3.30806 7.34844L5.95971 10.0001L3.30806 12.6517C3.06398 12.8958 3.06398 13.2915 3.30806 13.5356C3.55214 13.7797 3.94786 13.7797 4.19194 13.5356L7.28553 10.442C7.40274 10.3248 7.46859 10.1658 7.46859 10.0001C7.46859 9.83433 7.40274 9.67535 7.28553 9.55814L4.19194 6.46455Z" />
</template>
