<template>
  <path
    d="M5 13.75C5 13.4048 5.27982 13.125 5.625 13.125H14.375C14.7202 13.125 15 13.4048 15 13.75C15 14.0952 14.7202 14.375 14.375 14.375H5.625C5.27982 14.375 5 14.0952 5 13.75Z"
    fill="currentColor" />
  <path
    d="M5.3125 11.25C5.83027 11.25 6.25 10.8303 6.25 10.3125C6.25 9.79473 5.83027 9.375 5.3125 9.375C4.79473 9.375 4.375 9.79473 4.375 10.3125C4.375 10.8303 4.79473 11.25 5.3125 11.25Z"
    fill="currentColor" />
  <path
    d="M9.375 10.3125C9.375 10.8303 8.95527 11.25 8.4375 11.25C7.91973 11.25 7.5 10.8303 7.5 10.3125C7.5 9.79473 7.91973 9.375 8.4375 9.375C8.95527 9.375 9.375 9.79473 9.375 10.3125Z"
    fill="currentColor" />
  <path
    d="M11.5625 11.25C12.0803 11.25 12.5 10.8303 12.5 10.3125C12.5 9.79473 12.0803 9.375 11.5625 9.375C11.0447 9.375 10.625 9.79473 10.625 10.3125C10.625 10.8303 11.0447 11.25 11.5625 11.25Z"
    fill="currentColor" />
  <path
    d="M15.625 10.3125C15.625 10.8303 15.2053 11.25 14.6875 11.25C14.1697 11.25 13.75 10.8303 13.75 10.3125C13.75 9.79473 14.1697 9.375 14.6875 9.375C15.2053 9.375 15.625 9.79473 15.625 10.3125Z"
    fill="currentColor" />
  <path
    d="M4.375 8.125C4.89277 8.125 5.3125 7.70527 5.3125 7.1875C5.3125 6.66973 4.89277 6.25 4.375 6.25C3.85723 6.25 3.4375 6.66973 3.4375 7.1875C3.4375 7.70527 3.85723 8.125 4.375 8.125Z"
    fill="currentColor" />
  <path
    d="M8.125 7.1875C8.125 7.70527 7.70527 8.125 7.1875 8.125C6.66973 8.125 6.25 7.70527 6.25 7.1875C6.25 6.66973 6.66973 6.25 7.1875 6.25C7.70527 6.25 8.125 6.66973 8.125 7.1875Z"
    fill="currentColor" />
  <path
    d="M10 8.125C10.5178 8.125 10.9375 7.70527 10.9375 7.1875C10.9375 6.66973 10.5178 6.25 10 6.25C9.48223 6.25 9.0625 6.66973 9.0625 7.1875C9.0625 7.70527 9.48223 8.125 10 8.125Z"
    fill="currentColor" />
  <path
    d="M13.75 7.1875C13.75 7.70527 13.3303 8.125 12.8125 8.125C12.2947 8.125 11.875 7.70527 11.875 7.1875C11.875 6.66973 12.2947 6.25 12.8125 6.25C13.3303 6.25 13.75 6.66973 13.75 7.1875Z"
    fill="currentColor" />
  <path
    d="M15.625 8.125C16.1428 8.125 16.5625 7.70527 16.5625 7.1875C16.5625 6.66973 16.1428 6.25 15.625 6.25C15.1072 6.25 14.6875 6.66973 14.6875 7.1875C14.6875 7.70527 15.1072 8.125 15.625 8.125Z"
    fill="currentColor" />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M1.25 5C1.25 3.96447 2.08947 3.125 3.125 3.125H16.875C17.9105 3.125 18.75 3.96447 18.75 5V15C18.75 16.0355 17.9105 16.875 16.875 16.875H3.125C2.08947 16.875 1.25 16.0355 1.25 15V5ZM3.125 4.375C2.77982 4.375 2.5 4.65482 2.5 5V15C2.5 15.3452 2.77982 15.625 3.125 15.625H16.875C17.2202 15.625 17.5 15.3452 17.5 15V5C17.5 4.65482 17.2202 4.375 16.875 4.375H3.125Z"
    fill="currentColor" />
</template>
