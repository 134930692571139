<template>
  <path
    d="M1 2.5C1 1.67157 1.67157 1 2.5 1H4C4.27614 1 4.5 1.22386 4.5 1.5C4.5 1.77614 4.27614 2 4 2H2.5C2.22386 2 2 2.22386 2 2.5V4C2 4.27614 1.77614 4.5 1.5 4.5C1.22386 4.5 1 4.27614 1 4V2.5Z" />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M9.75017 7.99857C9.74965 7.03229 8.96595 6.24939 7.9997 6.24983C7.03345 6.25016 6.25017 7.03356 6.25017 7.99983C6.25017 8.96633 7.03367 9.74983 8.00017 9.74983C8.96649 9.74983 9.75017 8.96684 9.75017 8.00017V7.99857ZM8.0001 7.24983C8.41404 7.24961 8.7498 7.58493 8.75017 7.99883V8.00017C8.75017 8.41421 8.41456 8.74983 8.00017 8.74983C7.58595 8.74983 7.25017 8.41405 7.25017 7.99983C7.25017 7.5857 7.58597 7.24994 8.0001 7.24983Z" />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M14.4192 7.72718C14.4193 7.72735 14.4194 7.72751 14.0002 8C14.4194 8.2725 14.4193 8.27265 14.4192 8.27282L14.4182 8.2743L14.4162 8.27744L14.4094 8.28767L14.3853 8.32333C14.3646 8.35362 14.3347 8.39669 14.2958 8.45071C14.2181 8.5587 14.1044 8.71077 13.957 8.89222C13.6627 9.25443 13.2311 9.73749 12.6803 10.2217C11.5873 11.1825 9.97343 12.1923 8.00017 12.1923C6.0269 12.1923 4.413 11.1825 3.32004 10.2217C2.76928 9.73749 2.33766 9.25443 2.04336 8.89222C1.89593 8.71077 1.7822 8.5587 1.70449 8.45071C1.66561 8.39669 1.63569 8.35362 1.61502 8.32333L1.59096 8.28767L1.58418 8.27744L1.58212 8.2743L1.58142 8.27323C1.58131 8.27306 1.58095 8.2725 2.00017 8C1.58095 7.72751 1.58105 7.72735 1.58116 7.72718L1.58212 7.72571L1.58418 7.72256L1.59096 7.71233L1.61502 7.67667C1.63569 7.64638 1.66561 7.60331 1.70449 7.54929C1.7822 7.4413 1.89593 7.28924 2.04336 7.10778C2.33766 6.74557 2.76928 6.26251 3.32004 5.77833C4.413 4.81748 6.0269 3.80769 8.00017 3.80769C9.97343 3.80769 11.5873 4.81748 12.6803 5.77833C13.2311 6.26251 13.6627 6.74557 13.957 7.10778C14.1044 7.28924 14.2181 7.4413 14.2958 7.54929C14.3347 7.60331 14.3646 7.64638 14.3853 7.67667L14.4094 7.71233L14.4162 7.72256L14.4182 7.72571L14.4192 7.72718ZM2.81947 8.26163C2.73961 8.16333 2.67112 8.07512 2.6145 8C2.67112 7.92488 2.73961 7.83667 2.81947 7.73837C3.08768 7.40828 3.48105 6.96826 3.9803 6.52937C4.98733 5.64406 6.37343 4.80769 8.00017 4.80769C9.6269 4.80769 11.013 5.64406 12.02 6.52937C12.5193 6.96826 12.9127 7.40828 13.1809 7.73837C13.2607 7.83667 13.3292 7.92488 13.3858 8C13.3292 8.07512 13.2607 8.16334 13.1809 8.26163C12.9127 8.59173 12.5193 9.03174 12.02 9.47063C11.013 10.3559 9.6269 11.1923 8.00017 11.1923C6.37343 11.1923 4.98733 10.3559 3.9803 9.47063C3.48105 9.03174 3.08768 8.59173 2.81947 8.26163Z" />
  <path d="M14.0002 8L14.4192 7.72718C14.5269 7.89288 14.5269 8.10712 14.4192 8.27282L14.0002 8Z" />
  <path d="M1.58116 7.72718L2.00017 8L1.58142 8.27323C1.47372 8.10753 1.47345 7.89288 1.58116 7.72718Z" />
  <path
    d="M12 1C11.7239 1 11.5 1.22386 11.5 1.5C11.5 1.77614 11.7239 2 12 2H13.5C13.7761 2 14 2.22386 14 2.5V4C14 4.27614 14.2239 4.5 14.5 4.5C14.7761 4.5 15 4.27614 15 4V2.5C15 1.67157 14.3284 1 13.5 1H12Z" />
  <path
    d="M1.50008 11.4998C1.77623 11.4998 2.00008 11.7236 2.00008 11.9998V13.4998C2.00008 13.7759 2.22394 13.9998 2.50008 13.9998H4.00008C4.27623 13.9998 4.50008 14.2236 4.50008 14.4998C4.50008 14.7759 4.27623 14.9998 4.00008 14.9998H2.50008C1.67166 14.9998 1.00008 14.3282 1.00008 13.4998V11.9998C1.00008 11.7236 1.22394 11.4998 1.50008 11.4998Z" />
  <path
    d="M15.0002 11.9998C15.0002 11.7237 14.7763 11.4998 14.5002 11.4998C14.224 11.4998 14.0002 11.7237 14.0002 11.9998V13.4998C14.0002 13.7759 13.7763 13.9998 13.5002 13.9998H12.0002C11.724 13.9998 11.5002 14.2237 11.5002 14.4998C11.5002 14.7759 11.724 14.9998 12.0002 14.9998H13.5002C14.3286 14.9998 15.0002 14.3282 15.0002 13.4998V11.9998Z" />
</template>
