<template>
  <path
    d="M1 2.50024C1 1.67182 1.67157 1.00024 2.5 1.00024H4C4.27614 1.00024 4.5 1.2241 4.5 1.50024C4.5 1.77639 4.27614 2.00024 4 2.00024H2.5C2.22386 2.00024 2 2.2241 2 2.50024V4.00024C2 4.27639 1.77614 4.50024 1.5 4.50024C1.22386 4.50024 1 4.27639 1 4.00024V2.50024Z" />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M9.75017 7.99881C9.74965 7.03254 8.96595 6.24963 7.9997 6.25008C7.03345 6.25041 6.25017 7.0338 6.25017 8.00008C6.25017 8.96658 7.03367 9.75008 8.00017 9.75008C8.96649 9.75008 9.75017 8.96708 9.75017 8.00041V7.99881ZM8.0001 7.25008C8.41404 7.24986 8.7498 7.58517 8.75017 7.99908V8.00041C8.75017 8.41445 8.41456 8.75008 8.00017 8.75008C7.58595 8.75008 7.25017 8.41429 7.25017 8.00008C7.25017 7.58594 7.58597 7.25019 8.0001 7.25008Z" />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M14.4192 7.72743C14.4193 7.7276 14.4194 7.72775 14.0002 8.00025C14.4194 8.27274 14.4193 8.27289 14.4192 8.27306L14.4182 8.27454L14.4162 8.27768L14.4094 8.28792L14.3853 8.32357C14.3646 8.35387 14.3347 8.39693 14.2958 8.45096C14.2181 8.55895 14.1044 8.71101 13.957 8.89246C13.6627 9.25467 13.2311 9.73774 12.6803 10.2219C11.5873 11.1828 9.97343 12.1926 8.00017 12.1926C6.0269 12.1926 4.413 11.1828 3.32004 10.2219C2.76928 9.73774 2.33766 9.25467 2.04336 8.89246C1.89593 8.71101 1.7822 8.55895 1.70449 8.45096C1.66561 8.39693 1.63569 8.35387 1.61502 8.32357L1.59096 8.28792L1.58418 8.27768L1.58212 8.27454L1.58142 8.27347C1.58131 8.2733 1.58095 8.27274 2.00017 8.00025C1.58095 7.72775 1.58105 7.7276 1.58116 7.72743L1.58212 7.72595L1.58418 7.72281L1.59096 7.71257L1.61502 7.67692C1.63569 7.64663 1.66561 7.60356 1.70449 7.54953C1.7822 7.44154 1.89593 7.28948 2.04336 7.10803C2.33766 6.74582 2.76928 6.26275 3.32004 5.77857C4.413 4.81773 6.0269 3.80794 8.00017 3.80794C9.97343 3.80794 11.5873 4.81773 12.6803 5.77857C13.2311 6.26275 13.6627 6.74582 13.957 7.10803C14.1044 7.28948 14.2181 7.44154 14.2958 7.54953C14.3347 7.60356 14.3646 7.64663 14.3853 7.67692L14.4094 7.71257L14.4162 7.72281L14.4182 7.72595L14.4192 7.72743ZM2.81947 8.26187C2.73961 8.16358 2.67112 8.07537 2.6145 8.00025C2.67112 7.92512 2.73961 7.83691 2.81947 7.73862C3.08768 7.40852 3.48105 6.96851 3.9803 6.52961C4.98733 5.6443 6.37343 4.80794 8.00017 4.80794C9.6269 4.80794 11.013 5.6443 12.02 6.52961C12.5193 6.96851 12.9127 7.40852 13.1809 7.73862C13.2607 7.83691 13.3292 7.92512 13.3858 8.00024C13.3292 8.07537 13.2607 8.16358 13.1809 8.26187C12.9127 8.59197 12.5193 9.03198 12.02 9.47088C11.013 10.3562 9.6269 11.1926 8.00017 11.1926C6.37343 11.1926 4.98733 10.3562 3.9803 9.47088C3.48105 9.03198 3.08768 8.59197 2.81947 8.26187Z" />
  <path d="M14.0002 8.00025L14.4192 7.72743C14.5269 7.89313 14.5269 8.10736 14.4192 8.27306L14.0002 8.00025Z" />
  <path d="M1.58116 7.72743L2.00017 8.00025L1.58142 8.27347C1.47372 8.10777 1.47345 7.89313 1.58116 7.72743Z" />
  <path
    d="M12 1.00024C11.7239 1.00024 11.5 1.2241 11.5 1.50024C11.5 1.77639 11.7239 2.00024 12 2.00024H13.5C13.7761 2.00024 14 2.2241 14 2.50024V4.00024C14 4.27639 14.2239 4.50024 14.5 4.50024C14.7761 4.50024 15 4.27639 15 4.00024V2.50024C15 1.67182 14.3284 1.00024 13.5 1.00024H12Z" />
  <path
    d="M1.50008 11.5C1.77623 11.5 2.00008 11.7239 2.00008 12V13.5C2.00008 13.7761 2.22394 14 2.50008 14H4.00008C4.27623 14 4.50008 14.2239 4.50008 14.5C4.50008 14.7761 4.27623 15 4.00008 15H2.50008C1.67166 15 1.00008 14.3284 1.00008 13.5V12C1.00008 11.7239 1.22394 11.5 1.50008 11.5Z" />
  <path
    d="M15.0002 12C15.0002 11.7239 14.7763 11.5 14.5002 11.5C14.224 11.5 14.0002 11.7239 14.0002 12V13.5C14.0002 13.7762 13.7763 14 13.5002 14H12.0002C11.724 14 11.5002 14.2239 11.5002 14.5C11.5002 14.7762 11.724 15 12.0002 15H13.5002C14.3286 15 15.0002 14.3285 15.0002 13.5V12Z" />
</template>
