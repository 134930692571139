<template>
  <path
    fill="currentColor"
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M11.0114 1.85455C10.8246 2.04115 10.7253 2.29383 10.7294 2.55293L10.7468 3.61392C7.66041 4.39203 5.71245 7.43913 6.32952 10.6518C6.57257 11.9172 7.198 13.0867 8.11838 14.002C8.36289 14.2452 8.75815 14.2444 9.00165 14.0002C9.24515 13.756 9.24488 13.3608 9.00104 13.1169C7.83844 11.9543 7.82933 10.1284 8.91967 9.03803C9.4227 8.53577 10.1048 8.25455 10.8235 8.24662L10.8481 9.79199C10.8572 10.3229 11.2891 10.755 11.8201 10.7634C12.0791 10.7675 12.3317 10.6682 12.5182 10.4816L15.834 7.16582C16.3612 6.6386 16.3266 5.79826 15.8177 5.28938L12.3916 1.86319C12.0221 1.49368 11.4039 1.46226 11.0114 1.85455ZM8.03606 8.15387C7.83466 8.35527 7.65925 8.57287 7.51017 8.80216C7.7881 6.7598 9.34112 5.08099 11.4791 4.73827C11.7861 4.68907 12.0102 4.42174 12.0051 4.11091L11.9907 3.2301L14.9339 6.17326C14.9559 6.19532 14.9643 6.22017 14.9646 6.24122C14.9649 6.26177 14.9582 6.27391 14.9501 6.28194L12.088 9.1441L12.0644 7.66092C12.0596 7.35989 11.8408 7.10505 11.544 7.05469C10.2559 6.83613 8.95111 7.23996 8.03606 8.15387Z" />
  <path
    fill="currentColor"
    d="M2.5 10.6248C2.5 10.2796 2.77982 9.99981 3.125 9.99981H4.375C4.72018 9.99981 5 9.71999 5 9.37481C5 9.02963 4.72018 8.74981 4.375 8.74981H3.125C2.08947 8.74981 1.25 9.58928 1.25 10.6248V16.8748C1.25 17.9103 2.08947 18.7498 3.125 18.7498H16.875C17.9105 18.7498 18.75 17.9103 18.75 16.8748V10.6248C18.75 9.58928 17.9105 8.74981 16.875 8.74981H15.625C15.2798 8.74981 15 9.02963 15 9.37481C15 9.71999 15.2798 9.99981 15.625 9.99981H16.875C17.2202 9.99981 17.5 10.2796 17.5 10.6248V16.8748C17.5 17.22 17.2202 17.4998 16.875 17.4998H3.125C2.77982 17.4998 2.5 17.22 2.5 16.8748V10.6248Z" />
</template>
