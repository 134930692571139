<template>
  <path
    fill="currentColor"
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M5 6.14331C5.53276 5.95501 5.95501 5.53276 6.14331 5H13.8567C14.1141 5.72825 14.8086 6.25 15.625 6.25C16.6605 6.25 17.5 5.41053 17.5 4.375C17.5 3.33947 16.6605 2.5 15.625 2.5C14.8086 2.5 14.1141 3.02175 13.8567 3.75H6.14331C5.88591 3.02175 5.19139 2.5 4.375 2.5C3.33947 2.5 2.5 3.33947 2.5 4.375C2.5 5.19139 3.02175 5.88591 3.75 6.14331L3.75 13.8567C3.02175 14.1141 2.5 14.8086 2.5 15.625C2.5 16.6605 3.33947 17.5 4.375 17.5C5.41053 17.5 6.25 16.6605 6.25 15.625C6.25 14.8086 5.72825 14.1141 5 13.8567L5 6.14331ZM3.75 4.375C3.75 4.02982 4.02982 3.75 4.375 3.75C4.72018 3.75 5 4.02982 5 4.375C5 4.72018 4.72018 5 4.375 5C4.02982 5 3.75 4.72018 3.75 4.375ZM3.75 15.625C3.75 15.2798 4.02982 15 4.375 15C4.72018 15 5 15.2798 5 15.625C5 15.9702 4.72018 16.25 4.375 16.25C4.02982 16.25 3.75 15.9702 3.75 15.625ZM15.625 3.75C15.2798 3.75 15 4.02982 15 4.375C15 4.72018 15.2798 5 15.625 5C15.9702 5 16.25 4.72018 16.25 4.375C16.25 4.02982 15.9702 3.75 15.625 3.75Z" />
  <path
    fill="currentColor"
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M15 9.375V13.8567C14.4672 14.045 14.045 14.4672 13.8567 15H9.375V16.25H13.8567C14.1141 16.9782 14.8086 17.5 15.625 17.5C16.6605 17.5 17.5 16.6605 17.5 15.625C17.5 14.8086 16.9782 14.1141 16.25 13.8567V9.375H15ZM15 15.625C15 15.2798 15.2798 15 15.625 15C15.9702 15 16.25 15.2798 16.25 15.625C16.25 15.9702 15.9702 16.25 15.625 16.25C15.2798 16.25 15 15.9702 15 15.625Z" />
</template>
