<template>
  <path
    d="M17.1033 8.6302C17.3635 8.30797 17.3133 7.83576 16.9911 7.57549C16.6689 7.31522 16.1967 7.36544 15.9364 7.68767L10.5198 14.3938L7.96336 12.0708C7.6568 11.7922 7.18247 11.8149 6.90391 12.1215C6.62534 12.428 6.64803 12.9023 6.95459 13.1809L10.0993 16.0385C10.2521 16.1774 10.4554 16.2471 10.6613 16.2312C10.8672 16.2154 11.0574 16.1153 11.1871 15.9547L17.1033 8.6302Z"
    fill="currentColor" />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M4.57538 4.57538C8.67589 0.474873 15.3241 0.474873 19.4246 4.57538C23.5251 8.67589 23.5251 15.3241 19.4246 19.4246C15.3241 23.5251 8.67589 23.5251 4.57538 19.4246C0.474873 15.3241 0.474873 8.67589 4.57538 4.57538ZM5.63604 5.63604C9.15076 2.12132 14.8492 2.12132 18.364 5.63604C21.8787 9.15076 21.8787 14.8492 18.364 18.364C14.8492 21.8787 9.15076 21.8787 5.63604 18.364C2.12132 14.8492 2.12132 9.15076 5.63604 5.63604Z"
    fill="currentColor" />
</template>
