<template>
  <path
    fill="currentColor"
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M2.875 2.5C1.98078 2.5 1.25 3.22034 1.25 4.12103V14.375L1.25006 14.3836C1.26401 15.3932 2.08969 16.2617 3.13505 16.25H10.625C10.9702 16.25 11.25 15.9702 11.25 15.625C11.25 15.2798 10.9702 15 10.625 15H5.20486C5.25066 14.9072 5.28794 14.823 5.31757 14.7561C5.33671 14.7129 5.35269 14.6768 5.36568 14.6504C5.89663 13.5686 6.9535 10.7321 7.41879 9.46592C7.47376 9.31634 7.61514 9.21774 7.77416 9.21774H17.2418C17.4489 9.21774 17.6168 9.38564 17.6168 9.59274V11.9705C17.6168 12.3157 17.8966 12.5955 18.2418 12.5955C18.5869 12.5955 18.8668 12.3157 18.8668 11.9705V9.59274C18.8668 8.69528 18.1392 7.96774 17.2418 7.96774H16.7161V6.31209C16.7161 5.41688 15.9936 4.6871 15.0939 4.6871H7.96773V4.125C7.96773 3.22754 7.2402 2.5 6.34273 2.5H2.875ZM3.12686 15L3.13135 14.9999C3.61111 14.9999 3.79176 14.8632 3.88677 14.7604C3.99258 14.6459 4.0436 14.5332 4.12351 14.3565C4.15659 14.2834 4.19462 14.1993 4.24355 14.0996C4.73844 13.0913 5.76884 10.3319 6.2455 9.03476C6.48112 8.39359 7.09072 7.96774 7.77416 7.96774H15.4661V6.31209C15.4661 6.10274 15.2987 5.9371 15.0939 5.9371H7.34273C6.99756 5.9371 6.71773 5.65727 6.71773 5.3121V4.125C6.71773 3.91789 6.54984 3.75 6.34273 3.75H2.875C2.66465 3.75 2.5 3.91716 2.5 4.12103V14.37C2.50681 14.7225 2.79694 14.999 3.11337 15.0001L3.125 15H3.12686Z" />
  <path
    fill="currentColor"
    d="M15.625 12.5C15.9702 12.5 16.25 12.7798 16.25 13.125V15H18.125C18.4702 15 18.75 15.2798 18.75 15.625C18.75 15.9702 18.4702 16.25 18.125 16.25H16.25V18.125C16.25 18.4702 15.9702 18.75 15.625 18.75C15.2798 18.75 15 18.4702 15 18.125V16.25H13.125C12.7798 16.25 12.5 15.9702 12.5 15.625C12.5 15.2798 12.7798 15 13.125 15H15V13.125C15 12.7798 15.2798 12.5 15.625 12.5Z" />
</template>
