<template>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M15.5 8.5H8.5V15.5H15.5V8.5ZM7 7V17H17V7H7Z" fill="#A6A6A6" />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M11.4697 0.46967C11.7626 0.176777 12.2374 0.176777 12.5303 0.46967L14.5303 2.46967C14.8232 2.76256 14.8232 3.23744 14.5303 3.53033C14.2374 3.82322 13.7626 3.82322 13.4697 3.53033L12.75 2.81066V5C12.75 5.41421 12.4142 5.75 12 5.75C11.5858 5.75 11.25 5.41421 11.25 5V2.81066L10.5303 3.53033C10.2374 3.82322 9.76256 3.82322 9.46967 3.53033C9.17678 3.23744 9.17678 2.76256 9.46967 2.46967L11.4697 0.46967Z"
    fill="#A6A6A6" />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M11.4697 23.5303C11.7626 23.8232 12.2374 23.8232 12.5303 23.5303L14.5303 21.5303C14.8232 21.2374 14.8232 20.7626 14.5303 20.4697C14.2374 20.1768 13.7626 20.1768 13.4697 20.4697L12.75 21.1893V19C12.75 18.5858 12.4142 18.25 12 18.25C11.5858 18.25 11.25 18.5858 11.25 19V21.1893L10.5303 20.4697C10.2374 20.1768 9.76256 20.1768 9.46967 20.4697C9.17678 20.7626 9.17678 21.2374 9.46967 21.5303L11.4697 23.5303Z"
    fill="#A6A6A6" />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M0.25 12C0.25 12.1989 0.329018 12.3897 0.46967 12.5303L2.46967 14.5303C2.76256 14.8232 3.23744 14.8232 3.53033 14.5303C3.82322 14.2374 3.82322 13.7626 3.53033 13.4697L2.81066 12.75L5 12.75C5.41421 12.75 5.75 12.4142 5.75 12C5.75 11.5858 5.41421 11.25 5 11.25L2.81066 11.25L3.53033 10.5303C3.82322 10.2374 3.82322 9.76256 3.53033 9.46967C3.23744 9.17678 2.76256 9.17678 2.46967 9.46967L0.46967 11.4697C0.329018 11.6103 0.25 11.8011 0.25 12Z"
    fill="#A6A6A6" />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M23.75 12C23.75 12.1989 23.671 12.3897 23.5303 12.5303L21.5303 14.5303C21.2374 14.8232 20.7626 14.8232 20.4697 14.5303C20.1768 14.2374 20.1768 13.7626 20.4697 13.4697L21.1893 12.75L19 12.75C18.5858 12.75 18.25 12.4142 18.25 12C18.25 11.5858 18.5858 11.25 19 11.25L21.1893 11.25L20.4697 10.5303C20.1768 10.2374 20.1768 9.76256 20.4697 9.46967C20.7626 9.17678 21.2374 9.17678 21.5303 9.46967L23.5303 11.4697C23.671 11.6103 23.75 11.8011 23.75 12Z"
    fill="#A6A6A6" />
</template>
