<template>
  <path
    fill="currentColor"
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M5.80916 1.92539C6.35545 1.3791 7.21804 1.2146 7.92704 1.52151C8.67483 1.84521 9.13951 2.55412 9.41266 3.22862C9.69186 3.91807 9.81995 4.68795 9.81995 5.31118C9.81995 5.65636 9.54012 5.93618 9.19495 5.93618C8.61815 5.93618 7.84722 5.84933 7.14182 5.58682C6.44944 5.32914 5.71468 4.85973 5.39867 4.02779C5.10078 3.32176 5.26684 2.46772 5.80916 1.92539ZM7.43047 2.66865C7.19206 2.56545 6.87674 2.62558 6.69304 2.80928C6.50935 2.99297 6.44921 3.30829 6.55242 3.5467C6.55676 3.55674 6.56084 3.56689 6.56465 3.57714C6.69605 3.93048 7.03978 4.21508 7.5778 4.41531C7.87571 4.52618 8.20083 4.59807 8.5125 4.63972C8.46153 4.32636 8.37612 3.99922 8.25406 3.69781C8.03345 3.15305 7.74003 2.80265 7.43047 2.66865Z" />
  <path
    fill="currentColor"
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M12.145 1.52163C12.854 1.21472 13.7166 1.37921 14.2629 1.92551C14.8053 2.46785 14.9713 3.32194 14.6734 4.028C14.3516 4.87392 13.5633 5.33823 12.8694 5.58898C12.1529 5.8479 11.3859 5.93629 10.8771 5.93629C10.532 5.93629 10.2521 5.65647 10.2521 5.31129C10.2521 4.81195 10.384 4.04655 10.6552 3.33985C10.9156 2.66148 11.3738 1.85549 12.145 1.52163ZM13.379 2.80939C13.1953 2.62569 12.88 2.56556 12.6416 2.66876C12.3555 2.79261 12.0556 3.17993 11.8222 3.78782C11.7131 4.07213 11.634 4.36468 11.5817 4.62794C11.8603 4.58496 12.1608 4.51592 12.4446 4.41338C13.018 4.20617 13.3813 3.9164 13.5074 3.57726C13.5112 3.56701 13.5153 3.55686 13.5197 3.54682C13.6229 3.30841 13.5627 2.99309 13.379 2.80939Z" />
  <path
    fill="currentColor"
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M1.25 8.40943C1.25 9.44497 2.08947 10.2844 3.125 10.2844L3.1251 17.4092C3.1251 18.3067 3.85263 19.0342 4.75009 19.0342H7.52042L7.53601 19.0344L7.55161 19.0342H12.5204L12.536 19.0344L12.5516 19.0342H15.2501C16.1476 19.0342 16.8751 18.3067 16.8751 17.4092V10.2844C17.9106 10.2844 18.75 9.44494 18.75 8.40943V8.01087C18.75 6.97534 17.9105 6.13587 16.875 6.13587H3.125C2.08947 6.13587 1.25 6.97534 1.25 8.01087V8.40943ZM13.161 7.38587V9.03424H16.2501L16.2657 9.03443H16.875C17.2202 9.03443 17.5 8.75461 17.5 8.40943V8.01087C17.5 7.6657 17.2202 7.38587 16.875 7.38587H13.161ZM13.161 10.2844V17.7842H15.2501C15.4572 17.7842 15.6251 17.6163 15.6251 17.4092V10.2844H13.161ZM11.911 7.38587H8.16101V9.03424H11.911V7.38587ZM11.911 10.2844H8.16101L8.16101 17.7842H11.911V10.2844ZM6.91101 7.38587H3.125C2.77982 7.38587 2.5 7.6657 2.5 8.01087V8.40943C2.5 8.75461 2.77982 9.03443 3.125 9.03443H3.7345L3.7501 9.03424H6.91101V7.38587ZM6.91101 10.2844H4.3751V17.4092C4.3751 17.6164 4.54299 17.7842 4.75009 17.7842H6.91101L6.91101 10.2844Z" />
</template>
