<template>
  <path
    fill="currentColor"
    d="M9.37524 7.08019V14.4281C9.37524 14.7733 9.65507 15.0531 10.0002 15.0531C10.3454 15.0531 10.6252 14.7733 10.6252 14.4281V7.08038L12.5134 8.96654C12.7576 9.21049 13.1533 9.21029 13.3972 8.96608C13.6412 8.72188 13.641 8.32615 13.3968 8.0822L10.4484 5.13687C10.4224 5.11013 10.394 5.08572 10.3637 5.06396C10.3427 5.049 10.3209 5.03529 10.2982 5.02294C10.2096 4.97484 10.1081 4.94751 10.0002 4.94751C9.98946 4.94751 9.97873 4.94778 9.96808 4.94832C9.90883 4.95133 9.85175 4.96258 9.79797 4.98097C9.71047 5.0108 9.62826 5.06059 9.55844 5.13034L6.60351 8.0822C6.35931 8.32615 6.35911 8.72188 6.60306 8.96608C6.84701 9.21029 7.24274 9.21049 7.48694 8.96654L9.37524 7.08019Z" />
  <path
    fill="currentColor"
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M1.24902 9.99995C1.24902 5.17092 5.16761 1.25732 10.0001 1.25732C14.8325 1.25732 18.7511 5.17092 18.7511 9.99995C18.7511 14.829 14.8325 18.7426 10.0001 18.7426C5.16761 18.7426 1.24902 14.829 1.24902 9.99995ZM10.0001 2.50732C5.85674 2.50732 2.49902 5.8625 2.49902 9.99995C2.49902 14.1374 5.85674 17.4926 10.0001 17.4926C14.1434 17.4926 17.5011 14.1374 17.5011 9.99995C17.5011 5.8625 14.1434 2.50732 10.0001 2.50732Z" />
</template>
