<template>
  <path
    fill="currentColor"
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M8.9469 1.44653C7.49715 1.44653 6.3219 2.62179 6.3219 4.07153V5.44678H5.69629C4.66076 5.44678 3.82129 6.28624 3.82129 7.32178V13.6453C3.82129 14.6808 4.66076 15.5203 5.69629 15.5203H10.9721C11.3173 15.5203 11.5971 15.2404 11.5971 14.8953C11.5971 14.5501 11.3173 14.2703 10.9721 14.2703H5.69629C5.35111 14.2703 5.07129 13.9904 5.07129 13.6453V7.32178C5.07129 6.9766 5.35111 6.69678 5.69629 6.69678H13.4475C13.7926 6.69678 14.0725 6.9766 14.0725 7.32178V11.1466C14.0725 11.4918 14.3523 11.7716 14.6975 11.7716C15.0426 11.7716 15.3225 11.4918 15.3225 11.1466V7.32178C15.3225 6.28624 14.483 5.44678 13.4475 5.44678H12.8219V4.07153C12.8219 2.62179 11.6466 1.44653 10.1969 1.44653H8.9469ZM11.5719 5.44678V4.07153C11.5719 3.31214 10.9563 2.69653 10.1969 2.69653H8.9469C8.18751 2.69653 7.5719 3.31214 7.5719 4.07153V5.44678H11.5719Z" />
  <path
    fill="currentColor"
    d="M8.3684 9.64254C8.24506 9.56013 8.10006 9.51614 7.95172 9.51614C7.75281 9.51614 7.56204 9.59516 7.42139 9.73581C7.28074 9.87646 7.20172 10.0672 7.20172 10.2661C7.20172 10.4145 7.24571 10.5595 7.32812 10.6828C7.41053 10.8062 7.52766 10.9023 7.66471 10.9591C7.80175 11.0158 7.95255 11.0307 8.09804 11.0017C8.24353 10.9728 8.37716 10.9014 8.48205 10.7965C8.58694 10.6916 8.65837 10.5579 8.68731 10.4125C8.71625 10.267 8.7014 10.1162 8.64463 9.97913C8.58786 9.84209 8.49173 9.72495 8.3684 9.64254Z" />
  <path
    fill="currentColor"
    d="M11.6088 9.64254C11.4854 9.56013 11.3404 9.51614 11.1921 9.51614C10.9932 9.51614 10.8024 9.59516 10.6617 9.73581C10.5211 9.87646 10.4421 10.0672 10.4421 10.2661C10.4421 10.4145 10.4861 10.5595 10.5685 10.6828C10.6509 10.8062 10.768 10.9023 10.9051 10.9591C11.0421 11.0158 11.1929 11.0307 11.3384 11.0017C11.4839 10.9728 11.6175 10.9014 11.7224 10.7965C11.8273 10.6916 11.8987 10.5579 11.9277 10.4125C11.9566 10.267 11.9418 10.1162 11.885 9.97913C11.8282 9.84209 11.7321 9.72495 11.6088 9.64254Z" />
  <path
    fill="currentColor"
    d="M16.6918 13.462C16.9359 13.2179 16.9359 12.8222 16.6918 12.5781C16.4477 12.334 16.052 12.334 15.8079 12.5781L14.3749 14.0111L12.9418 12.5781C12.6977 12.334 12.302 12.334 12.0579 12.5781C11.8139 12.8222 11.8139 13.2179 12.0579 13.462L13.491 14.895L12.0579 16.3281C11.8139 16.5722 11.8139 16.9679 12.0579 17.212C12.302 17.456 12.6977 17.456 12.9418 17.212L14.3749 15.7789L15.8079 17.212C16.052 17.456 16.4477 17.456 16.6918 17.212C16.9359 16.9679 16.9359 16.5722 16.6918 16.3281L15.2588 14.895L16.6918 13.462Z" />
</template>
