<template>
  <g clip-path="url(#clip0_148_259)">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.4697 2.46967C11.7626 2.17678 12.2374 2.17678 12.5303 2.46967L14.5303 4.46967C14.8232 4.76256 14.8232 5.23744 14.5303 5.53033C14.2374 5.82322 13.7626 5.82322 13.4697 5.53033L12.75 4.81066V7C12.75 7.41421 12.4142 7.75 12 7.75C11.5858 7.75 11.25 7.41421 11.25 7V4.81066L10.5303 5.53033C10.2374 5.82322 9.76256 5.82322 9.46967 5.53033C9.17678 5.23744 9.17678 4.76256 9.46967 4.46967L11.4697 2.46967Z"
      fill="#A6A6A6" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.4697 21.5303C11.7626 21.8232 12.2374 21.8232 12.5303 21.5303L14.5303 19.5303C14.8232 19.2374 14.8232 18.7626 14.5303 18.4697C14.2374 18.1768 13.7626 18.1768 13.4697 18.4697L12.75 19.1893V17C12.75 16.5858 12.4142 16.25 12 16.25C11.5858 16.25 11.25 16.5858 11.25 17V19.1893L10.5303 18.4697C10.2374 18.1768 9.76256 18.1768 9.46967 18.4697C9.17678 18.7626 9.17678 19.2374 9.46967 19.5303L11.4697 21.5303Z"
      fill="#A6A6A6" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.25 12C2.25 12.1989 2.32902 12.3897 2.46967 12.5303L4.46967 14.5303C4.76256 14.8232 5.23744 14.8232 5.53033 14.5303C5.82322 14.2374 5.82322 13.7626 5.53033 13.4697L4.81066 12.75L7 12.75C7.41421 12.75 7.75 12.4142 7.75 12C7.75 11.5858 7.41421 11.25 7 11.25L4.81066 11.25L5.53033 10.5303C5.82322 10.2374 5.82322 9.76256 5.53033 9.46967C5.23744 9.17678 4.76256 9.17678 4.46967 9.46967L2.46967 11.4697C2.32902 11.6103 2.25 11.8011 2.25 12Z"
      fill="#A6A6A6" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M21.75 12C21.75 12.1989 21.671 12.3897 21.5303 12.5303L19.5303 14.5303C19.2374 14.8232 18.7626 14.8232 18.4697 14.5303C18.1768 14.2374 18.1768 13.7626 18.4697 13.4697L19.1893 12.75L17 12.75C16.5858 12.75 16.25 12.4142 16.25 12C16.25 11.5858 16.5858 11.25 17 11.25L19.1893 11.25L18.4697 10.5303C18.1768 10.2374 18.1768 9.76256 18.4697 9.46967C18.7626 9.17678 19.2374 9.17678 19.5303 9.46967L21.5303 11.4697C21.671 11.6103 21.75 11.8011 21.75 12Z"
      fill="#A6A6A6" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M22.5 1.5H1.5V22.5H22.5V1.5ZM0 0V24H24V0H0Z" fill="#A6A6A6" />
  </g>
  <defs>
    <clipPath id="clip0_148_259">
      <rect width="24" height="24" fill="white" />
    </clipPath>
  </defs>
</template>
;
