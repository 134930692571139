<template>
  <path
    d="M9.10509 2.39163C8.16618 2.00222 7.13307 1.90035 6.13637 2.09886C5.13967 2.29737 4.22405 2.78738 3.50534 3.50701C2.78662 4.22666 2.2971 5.14362 2.09877 6.14198C1.90044 7.14034 2.00223 8.17516 2.39126 9.11555C2.78028 10.0559 3.43902 10.8596 4.28407 11.425C5.12912 11.9903 6.12257 12.2921 7.13878 12.2921C7.41492 12.2921 7.63878 12.5159 7.63878 12.7921C7.63878 13.0682 7.41492 13.2921 7.13878 13.2921C5.9245 13.2921 4.73754 12.9315 3.72801 12.2561C2.71848 11.5807 1.93175 10.6208 1.4672 9.49782C1.00265 8.37485 0.881118 7.13922 1.11793 5.94713C1.35475 4.75504 1.93931 3.65994 2.79778 2.80036C3.65626 1.94078 4.75011 1.35532 5.94104 1.11812C7.13197 0.880925 8.3664 1.00267 9.48819 1.46793C10.61 1.93319 11.5687 2.72103 12.2431 3.73173C12.9176 4.74243 13.2775 5.93061 13.2775 7.14604C13.2775 7.42218 13.0537 7.64604 12.7775 7.64604C12.5014 7.64604 12.2775 7.42218 12.2775 7.14604C12.2775 6.12811 11.9761 5.13309 11.4113 4.28681C10.8466 3.44054 10.044 2.78105 9.10509 2.39163Z" />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M7.69058 7.00701C7.85049 6.98581 8.01307 7.01295 8.15742 7.08491L14.5549 10.2878C14.7016 10.36 14.8224 10.4756 14.9011 10.6188C14.9795 10.76 15.0128 10.9217 14.9966 11.0824C14.9808 11.2437 14.9162 11.3964 14.8114 11.5201C14.702 11.6528 14.5532 11.7475 14.3864 11.7905L14.3831 11.7914L12.2999 12.3128L11.779 14.399L11.7786 14.4005C11.7388 14.5578 11.651 14.6986 11.5275 14.8035C11.4051 14.9129 11.2508 14.9803 11.087 14.9955C10.9314 15.0113 10.7747 14.9803 10.6371 14.9066C10.4862 14.8315 10.3648 14.708 10.2921 14.5559L7.08895 8.15319C7.01721 8.00889 6.99018 7.84647 7.0113 7.68675L7.01148 7.68544C7.03462 7.51389 7.11337 7.35458 7.23578 7.23202C7.35819 7.10945 7.5175 7.03042 7.68927 7.00719L7.69058 7.00701ZM8.25712 8.25314L13.6379 10.947L11.766 11.4156C11.5869 11.4604 11.4471 11.6004 11.4023 11.7795L10.9425 13.621L8.25712 8.25314Z" />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M7.83643 4.4675C7.36838 4.34482 6.87648 4.3465 6.40929 4.47235L4.46957 7.86628C4.59502 8.33451 4.84127 8.7614 5.18352 9.10409C5.52577 9.44678 5.95197 9.69323 6.41931 9.81876C6.68599 9.8904 6.84411 10.1647 6.77248 10.4314C6.70084 10.698 6.42657 10.8562 6.15988 10.7845C5.52292 10.6134 4.94218 10.2776 4.47596 9.81074C4.00974 9.34392 3.67444 8.76257 3.50364 8.12508C3.24575 7.1624 3.37864 6.13663 3.87339 5.27164C4.36815 4.40662 5.18467 3.7725 6.14491 3.50793L6.14744 3.50723C6.78329 3.33562 7.45289 3.33319 8.08997 3.50018C8.72705 3.66716 9.30949 3.99776 9.77971 4.45921L9.78174 4.46119C10.251 4.92699 10.5904 5.50755 10.7662 6.14518C10.8396 6.41139 10.6833 6.6867 10.4171 6.76011C10.1509 6.83352 9.87562 6.67722 9.80221 6.41102C9.67283 5.94182 9.42325 5.5147 9.07829 5.17196C8.73251 4.83296 8.30446 4.59017 7.83643 4.4675ZM4.74143 5.76813C5.10494 5.13257 5.70453 4.66689 6.40929 4.47235L4.46957 7.86628C4.27993 7.15835 4.37769 6.40407 4.74143 5.76813Z" />
</template>
