<template>
  <path
    d="M0.916993 0C1.19314 0 1.41699 0.223858 1.41699 0.5L1.41699 13.5C1.41699 13.7761 1.19313 14 0.916992 14C0.64085 14 0.416992 13.7761 0.416992 13.5V0.5C0.416992 0.223858 0.64085 0 0.916993 0Z"
    fill="currentColor" />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M1.91699 4C1.91699 3.17157 2.58857 2.5 3.41699 2.5H8.41699C9.24542 2.5 9.91699 3.17157 9.91699 4V5C9.91699 5.82843 9.24542 6.5 8.41699 6.5H3.41699C2.58857 6.5 1.91699 5.82843 1.91699 5V4ZM3.41699 3.5C3.14085 3.5 2.91699 3.72386 2.91699 4V5C2.91699 5.27614 3.14085 5.5 3.41699 5.5H8.41699C8.69314 5.5 8.91699 5.27614 8.91699 5V4C8.91699 3.72386 8.69314 3.5 8.41699 3.5H3.41699Z"
    fill="currentColor" />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M3.41699 7.5C2.58857 7.5 1.91699 8.17157 1.91699 9V10C1.91699 10.8284 2.58857 11.5 3.41699 11.5H11.417C12.2454 11.5 12.917 10.8284 12.917 10V9C12.917 8.17157 12.2454 7.5 11.417 7.5H3.41699ZM2.91699 9C2.91699 8.72386 3.14085 8.5 3.41699 8.5H11.417C11.6931 8.5 11.917 8.72386 11.917 9V10C11.917 10.2761 11.6931 10.5 11.417 10.5H3.41699C3.14085 10.5 2.91699 10.2761 2.91699 10V9Z"
    fill="currentColor" />
</template>
