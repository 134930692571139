<template>
  <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.0833 15C14.3594 15 14.5833 14.7761 14.5833 14.5L14.5833 1.5C14.5833 1.22386 14.3594 1 14.0833 1C13.8071 1 13.5833 1.22386 13.5833 1.5L13.5833 14.5C13.5833 14.7761 13.8071 15 14.0833 15Z"
      fill="currentColor" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.08325 6C5.08325 6.82843 5.75482 7.5 6.58325 7.5H11.5833C12.4117 7.5 13.0833 6.82843 13.0833 6V5C13.0833 4.17157 12.4117 3.5 11.5833 3.5L6.58325 3.5C5.75482 3.5 5.08325 4.17157 5.08325 5V6ZM6.58325 6.5C6.30711 6.5 6.08325 6.27614 6.08325 6V5C6.08325 4.72386 6.30711 4.5 6.58325 4.5L11.5833 4.5C11.8594 4.5 12.0833 4.72386 12.0833 5V6C12.0833 6.27614 11.8594 6.5 11.5833 6.5H6.58325Z"
      fill="currentColor" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.58325 12.5C2.75482 12.5 2.08325 11.8284 2.08325 11L2.08325 10C2.08325 9.17157 2.75483 8.5 3.58325 8.5H11.5833C12.4117 8.5 13.0833 9.17157 13.0833 10V11C13.0833 11.8284 12.4117 12.5 11.5833 12.5L3.58325 12.5ZM3.08325 11C3.08325 11.2761 3.30711 11.5 3.58325 11.5L11.5833 11.5C11.8594 11.5 12.0833 11.2761 12.0833 11V10C12.0833 9.72386 11.8594 9.5 11.5833 9.5L3.58325 9.5C3.30711 9.5 3.08325 9.72386 3.08325 10L3.08325 11Z"
      fill="currentColor" />
  </svg>
</template>
