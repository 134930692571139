<template>
  <path
    fill="currentColor"
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M8.4532 12.1407C9.30748 11.2864 10.6925 11.2864 11.5468 12.1407C12.4011 12.995 12.4011 14.38 11.5468 15.2343C10.6925 16.0886 9.30748 16.0886 8.4532 15.2343C7.59893 14.38 7.59893 12.995 8.4532 12.1407ZM9.33709 13.0246C9.7032 12.6585 10.2968 12.6585 10.6629 13.0246C11.029 13.3907 11.029 13.9843 10.6629 14.3504C10.2968 14.7165 9.7032 14.7165 9.33709 14.3504C8.97097 13.9843 8.97097 13.3907 9.33709 13.0246Z" />
  <path
    fill="currentColor"
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M4.375 8.75H6.25V3.875C6.25 2.42525 7.42525 1.25 8.875 1.25H11.125C12.5747 1.25 13.75 2.42525 13.75 3.875V8.75H15.625C16.3154 8.75 16.875 9.30964 16.875 10V17.5C16.875 18.1904 16.3154 18.75 15.625 18.75H4.375C3.68464 18.75 3.125 18.1904 3.125 17.5V10C3.125 9.30964 3.68464 8.75 4.375 8.75ZM12.5 8.75H7.5V3.875C7.5 3.11561 8.11561 2.5 8.875 2.5H11.125C11.8844 2.5 12.5 3.11561 12.5 3.875V8.75ZM15.625 10V17.5H4.375L4.375 10H15.625Z" />
</template>
