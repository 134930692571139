<script lang="ts" setup>
type Props = {
  isShow?: boolean;
  status?: 'success' | 'error' | 'pending';
  progressClass?: string;
};

type Emits = {
  (event: 'close'): void;
};

defineProps<Props>();
const emit = defineEmits<Emits>();

const closeProgress = () => {
  emit('close');
};
</script>
<template>
  <Transition
    enter-active-class="duration-300 ease-out"
    enter-from-class="opacity-0"
    enter-to-class="opacity-100"
    leave-active-class="duration-300 ease-in"
    leave-from-class="opacity-100"
    leave-to-class="opacity-0">
    <div
      v-if="isShow"
      class="rounded-medium w-full"
      :class="[
        {
          'bg-primary-100': status === 'pending',
          'bg-green-100': status === 'success',
          'bg-red-100': status === 'error',
        },
        progressClass,
      ]">
      <div class="flex items-start justify-between gap-8 p-16">
        <div class="flex items-start gap-8">
          <div
            class="flex h-20 w-20 flex-none items-center justify-center"
            :class="{
              'text-primary-300': status === 'pending',
              'text-green-400': status === 'success',
              'text-red-300': status === 'error',
            }">
            <g-base-icon
              v-if="status === 'success'"
              name="check-fill"
              width="16"
              height="16"
              view-box="0 0 16 16"></g-base-icon>
            <g-base-icon
              v-else-if="status === 'error'"
              name="error"
              width="16"
              height="16"
              view-box="0 0 16 16"></g-base-icon>
            <g-base-icon
              v-else
              class="animate-spin"
              name="tiny-loading"
              width="16"
              height="17"
              view-box="0 0 16 17"></g-base-icon>
          </div>
          <span class="text-14 text-light-high">
            <slot name="content"></slot>
          </span>
        </div>
        <div
          class="rounded-medium hover:bg-light-100 text-light-high -mr-4 flex h-24 w-24 flex-none cursor-pointer items-center justify-center hover:bg-opacity-20"
          @click="closeProgress">
          <g-base-icon name="close" view-box="0 0 20 20" width="16" height="16"></g-base-icon>
        </div>
      </div>
    </div>
  </Transition>
</template>
