<template>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M10.4998 7.99817C10.499 6.61779 9.37945 5.49937 7.99909 5.50003C6.6187 5.50047 5.49975 6.61962 5.49975 8.00003C5.49975 9.38074 6.61904 10.5 7.99975 10.5C9.38021 10.5 10.4996 9.38159 10.4998 8.00083V7.99817ZM7.99949 6.50003C8.82762 6.49959 9.49931 7.17056 9.49975 7.9987V8.0003C9.49975 8.82855 8.82836 9.50003 7.99975 9.50003C7.17133 9.50003 6.49975 8.82846 6.49975 8.00003C6.49975 7.17176 7.17122 6.50025 7.99949 6.50003Z" />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M7.99869 3.50073C4.53098 3.50082 2.24443 6.11617 1.43599 7.20785C1.0847 7.68222 1.08696 8.31997 1.43971 8.79213C2.25325 9.88107 4.55461 12.4993 7.9987 12.4993C11.5041 12.4993 13.7751 9.87612 14.5731 8.78465C14.9175 8.31357 14.915 7.68376 14.5684 7.2152C13.7641 6.12749 11.474 3.50065 7.99869 3.50073ZM2.23962 7.80298C3.016 6.75459 5.04126 4.5008 7.99871 4.50073C10.9629 4.50066 12.9916 6.76477 13.7644 7.80979C13.8513 7.92726 13.8516 8.07715 13.7658 8.19448C13.0028 9.23811 10.9929 11.4993 7.9987 11.4993C5.0648 11.4993 3.025 9.24324 2.24082 8.19362C2.15178 8.07443 2.15162 7.92181 2.23962 7.80298Z" />
</template>
