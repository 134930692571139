<template>
  <path
    d="M10.3596 3.25527C10.6146 3.00021 10.9733 2.85156 11.3339 2.85156C11.7176 2.85156 12.1778 3.06835 12.5571 3.44689C12.9362 3.82532 13.1541 4.28522 13.1541 4.67123C13.1541 5.03653 13.009 5.38687 12.7507 5.6452L10.7588 7.6374C10.5636 7.83268 10.5636 8.14926 10.7589 8.34451C10.9542 8.53976 11.2708 8.53973 11.466 8.34445L13.4579 6.35224C13.9036 5.90638 14.1541 5.30171 14.1541 4.67123C14.1541 3.94153 13.7669 3.24159 13.2635 2.73912C12.7602 2.23675 12.0603 1.85156 11.3339 1.85156C10.7081 1.85156 10.095 2.10558 9.65247 2.54821L7.66089 4.53932C7.46561 4.73456 7.46557 5.05114 7.66081 5.24642C7.85605 5.44171 8.17263 5.44175 8.36792 5.24651L10.3596 3.25527Z"
    fill="currentColor" />
  <path
    d="M5.17898 8.50977C5.37422 8.3145 5.3742 7.99791 5.17892 7.80267C4.98364 7.60742 4.66706 7.60745 4.47181 7.80273L2.52868 9.74618C2.09154 10.1834 1.84595 10.7764 1.84595 11.3947C1.84595 12.3621 2.45495 12.9702 2.92312 13.4377L2.9603 13.4748C3.86124 14.3753 5.35727 14.3754 6.25821 13.4749L8.20114 11.532C8.3964 11.3367 8.3964 11.0201 8.20114 10.8249C8.00587 10.6296 7.68929 10.6296 7.49403 10.8249L5.55129 12.7676C5.04085 13.2777 4.17784 13.2778 3.6674 12.7677C3.15005 12.2504 2.84595 11.9153 2.84595 11.3947C2.84595 11.0416 2.98619 10.7029 3.23585 10.4532L5.17898 8.50977Z"
    fill="currentColor" />
  <path
    d="M3.09702 6.52148C3.09702 6.24534 3.32088 6.02148 3.59702 6.02148H5.09692C5.37307 6.02148 5.59692 6.24534 5.59692 6.52148C5.59692 6.79763 5.37307 7.02148 5.09692 7.02148H3.59702C3.32088 7.02148 3.09702 6.79763 3.09702 6.52148Z"
    fill="currentColor" />
  <path
    d="M9.86816 10.6699C9.86816 10.3938 9.64431 10.1699 9.36816 10.1699C9.09202 10.1699 8.86816 10.3938 8.86816 10.6699V12.1699C8.86816 12.4461 9.09202 12.6699 9.36816 12.6699C9.64431 12.6699 9.86816 12.4461 9.86816 12.1699V10.6699Z"
    fill="currentColor" />
  <path
    d="M6.36816 3.16992C6.64431 3.16992 6.86816 3.39378 6.86816 3.66992V5.16992C6.86816 5.44606 6.64431 5.66992 6.36816 5.66992C6.09202 5.66992 5.86816 5.44606 5.86816 5.16992V3.66992C5.86816 3.39378 6.09202 3.16992 6.36816 3.16992Z"
    fill="currentColor" />
  <path
    d="M10.597 9.02148C10.3209 9.02148 10.097 9.24534 10.097 9.52148C10.097 9.79763 10.3209 10.0215 10.597 10.0215H12.0969C12.3731 10.0215 12.5969 9.79763 12.5969 9.52148C12.5969 9.24534 12.3731 9.02148 12.0969 9.02148H10.597Z"
    fill="currentColor" />
</template>
