<template>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M3.89744 3.48717C3.67086 3.48717 3.48718 3.67085 3.48718 3.89743V12.1026C3.48718 12.3291 3.67086 12.5128 3.89743 12.5128H12.1026C12.3291 12.5128 12.5128 12.3291 12.5128 12.1026V9.64102H13.3333V12.1026C13.3333 12.7823 12.7823 13.3333 12.1026 13.3333H3.89743C3.2177 13.3333 2.66667 12.7823 2.66667 12.1026V3.89743C2.66667 3.21769 3.2177 2.66666 3.89744 2.66666H6.35897V3.48717H3.89744Z"
    fill="currentColor" />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M8.41025 2.66666H12.9231C13.1496 2.66666 13.3333 2.85033 13.3333 3.07691V7.58973H12.5128V4.06736L6.64906 9.93111L6.06887 9.35092L11.9326 3.48717H8.41025V2.66666Z"
    fill="currentColor" />
</template>
