<template>
  <path
    fill="currentColor"
    d="M8.75 3.97938C8.40482 3.97938 8.125 4.2592 8.125 4.60438C8.125 4.94956 8.40482 5.22938 8.75 5.22938H11.25C11.5952 5.22938 11.875 4.94956 11.875 4.60438C11.875 4.2592 11.5952 3.97938 11.25 3.97938H8.75Z" />
  <path
    fill="currentColor"
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M6.25 1.875C5.21447 1.875 4.375 2.71447 4.375 3.75V16.25C4.375 17.2855 5.21447 18.125 6.25 18.125H13.75C14.7855 18.125 15.625 17.2855 15.625 16.25V3.75C15.625 2.71447 14.7855 1.875 13.75 1.875H6.25ZM5.625 3.75C5.625 3.40482 5.90482 3.125 6.25 3.125H13.75C14.0952 3.125 14.375 3.40482 14.375 3.75V16.25C14.375 16.5952 14.0952 16.875 13.75 16.875H6.25C5.90482 16.875 5.625 16.5952 5.625 16.25V3.75Z" />
</template>
