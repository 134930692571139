<template>
  <path
    d="M5.39645 5.10351C5.20118 4.90825 5.20118 4.59166 5.39645 4.3964L7.64627 2.14678C7.74395 2.04911 7.87232 1.99996 8.00033 2C8.14396 2 8.2736 2.06056 8.36479 2.15753L10.6036 4.3964C10.7989 4.59166 10.7989 4.90825 10.6036 5.10351C10.4084 5.29877 10.0918 5.29877 9.89651 5.10351L8.50049 3.70746L8.50049 8.5C8.50049 8.77614 8.27663 9 8.00049 9C7.72435 9 7.50049 8.77614 7.50049 8.5L7.50049 3.70669L6.10355 5.10351C5.90829 5.29877 5.59171 5.29877 5.39645 5.10351Z"
    fill="#757575" />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M4 10C3.17157 10 2.5 10.6716 2.5 11.5V13.5C2.5 14.3284 3.17157 15 4 15H12C12.8284 15 13.5 14.3284 13.5 13.5V11.5C13.5 10.6716 12.8284 10 12 10H4ZM3.5 11.5C3.5 11.2239 3.72386 11 4 11H12C12.2761 11 12.5 11.2239 12.5 11.5V13.5C12.5 13.7761 12.2761 14 12 14H4C3.72386 14 3.5 13.7761 3.5 13.5V11.5Z"
    fill="#757575" />
</template>
