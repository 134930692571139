<template>
  <path
    d="M1 7.25C1 6.97386 1.22386 6.75 1.5 6.75H4.3C4.57614 6.75 4.8 6.97386 4.8 7.25V8.75C4.8 9.02614 4.57614 9.25 4.3 9.25H1.5C1.22386 9.25 1 9.02614 1 8.75V7.25Z"
    fill="currentColor" />
  <path
    d="M6.1001 7.25C6.1001 6.97386 6.32396 6.75 6.6001 6.75H9.4001C9.67624 6.75 9.9001 6.97386 9.9001 7.25V8.75C9.9001 9.02614 9.67624 9.25 9.4001 9.25H6.6001C6.32395 9.25 6.1001 9.02614 6.1001 8.75V7.25Z"
    fill="currentColor" />
  <path
    d="M11.2 7.25C11.2 6.97386 11.4238 6.75 11.7 6.75H14.5C14.7761 6.75 15 6.97386 15 7.25V8.75C15 9.02614 14.7761 9.25 14.5 9.25H11.7C11.4238 9.25 11.2 9.02614 11.2 8.75V7.25Z"
    fill="currentColor" />
</template>
