<template>
  <path
    fill="currentColor"
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M9.13314 4.19849C8.09896 4.19849 7.2673 5.04107 7.2673 6.07216C7.2673 7.10325 8.09896 7.94583 9.13314 7.94583C10.1673 7.94583 10.999 7.10325 10.999 6.07216C10.999 5.04107 10.1673 4.19849 9.13314 4.19849ZM8.5173 6.07216C8.5173 5.724 8.79672 5.44849 9.13314 5.44849C9.46955 5.44849 9.74898 5.724 9.74898 6.07216C9.74898 6.42032 9.46955 6.69583 9.13314 6.69583C8.79672 6.69583 8.5173 6.42032 8.5173 6.07216Z" />
  <path
    fill="currentColor"
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M11.9851 9.12709C11.9851 8.096 12.8167 7.25342 13.8509 7.25342C14.8851 7.25342 15.7168 8.096 15.7168 9.12709C15.7168 10.1582 14.8851 11.0008 13.8509 11.0008C12.8167 11.0008 11.9851 10.1582 11.9851 9.12709ZM13.8509 8.50342C13.5145 8.50342 13.2351 8.77893 13.2351 9.12709C13.2351 9.47525 13.5145 9.75076 13.8509 9.75076C14.1873 9.75076 14.4668 9.47525 14.4668 9.12709C14.4668 8.77893 14.1873 8.50342 13.8509 8.50342Z" />
  <path
    fill="currentColor"
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M13.1107 12.314C12.0765 12.314 11.2448 13.1566 11.2448 14.1876C11.2448 15.2187 12.0765 16.0613 13.1107 16.0613C14.1449 16.0613 14.9765 15.2187 14.9765 14.1876C14.9765 13.1566 14.1449 12.314 13.1107 12.314ZM12.4948 14.1876C12.4948 13.8395 12.7743 13.564 13.1107 13.564C13.4471 13.564 13.7265 13.8395 13.7265 14.1876C13.7265 14.5358 13.4471 14.8113 13.1107 14.8113C12.7743 14.8113 12.4948 14.5358 12.4948 14.1876Z" />
  <path
    fill="currentColor"
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M9.79767 2C3.64884 2 -0.0873505 7.79037 1.2825 13.0216C1.65424 14.4412 2.42828 15.6837 3.41129 16.406C4.41916 17.1464 5.69756 17.355 6.85402 16.5303C7.12671 16.3358 7.35009 16.2682 7.53887 16.2572C7.73254 16.246 7.94168 16.2915 8.18737 16.4035C8.4377 16.5177 8.7026 16.6901 9.00302 16.9096C9.14805 17.0156 9.29499 17.1277 9.45028 17.2462L9.46285 17.2558C9.62091 17.3764 9.78705 17.503 9.95755 17.6272C10.6297 18.1167 11.4513 18.6321 12.4487 18.7334C13.4764 18.8377 14.5733 18.4944 15.7826 17.5193C17.3867 16.2259 17.9946 14.6373 18.3213 12.9844C18.8101 10.5112 18.2932 7.78061 16.8501 5.6571C15.395 3.51591 13.0006 2 9.79767 2ZM2.49173 12.7049C1.31068 8.19474 4.53993 3.25 9.79767 3.25C12.5607 3.25 14.5789 4.5389 15.8163 6.35968C17.0656 8.19814 17.521 10.5867 17.095 12.7421C16.7993 14.2381 16.2877 15.5064 14.998 16.5462C13.9721 17.3734 13.1927 17.5525 12.5749 17.4898C11.9268 17.424 11.3335 17.0829 10.6934 16.6167C10.5358 16.502 10.3803 16.3835 10.2211 16.262L10.2044 16.2493C10.0524 16.1333 9.8961 16.014 9.74042 15.9003C9.41994 15.6661 9.07356 15.4339 8.7062 15.2663C8.33419 15.0966 7.91922 14.983 7.46635 15.0093C7.00858 15.0359 6.56297 15.2025 6.12822 15.5126C5.50935 15.9539 4.8297 15.897 4.15139 15.3986C3.44821 14.882 2.80791 13.9124 2.49173 12.7049Z" />
</template>
