<template>
  <path
    fill="currentColor"
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M5.34917 6.43616H6.7941L7.89962 13.5638H6.83289L6.63894 12.1485V12.1689H5.42675L5.2328 13.5638H4.24365L5.34917 6.43616ZM6.51287 11.2015L6.0377 7.67841H6.0183L5.55282 11.2015H6.51287Z" />
  <path
    fill="currentColor"
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M14.1166 6.43616H12.5068V13.5638H14.1845C14.7146 13.5638 15.1155 13.4213 15.387 13.1362C15.665 12.8443 15.804 12.4234 15.804 11.8736V11.2626C15.804 10.4616 15.5389 9.96606 15.0088 9.77599V9.75562C15.2415 9.6538 15.4129 9.48749 15.5228 9.25668C15.6391 9.02588 15.6973 8.7272 15.6973 8.36063V8.07552C15.6973 7.51889 15.5713 7.1082 15.3191 6.84345C15.067 6.57192 14.6662 6.43616 14.1166 6.43616ZM14.4657 9.16504C14.3623 9.28044 14.2039 9.33814 13.9906 9.33814H13.5736V7.4544H14.0875C14.275 7.4544 14.4108 7.5121 14.4948 7.6275C14.5853 7.7429 14.6306 7.92958 14.6306 8.18753V8.58465C14.6306 8.85618 14.5756 9.04964 14.4657 9.16504ZM14.6015 12.3929C14.511 12.4947 14.372 12.5456 14.1845 12.5456H13.5736V10.3564H14.0487C14.2944 10.3564 14.469 10.4209 14.5724 10.5498C14.6823 10.672 14.7373 10.8961 14.7373 11.2219V11.843C14.7373 12.101 14.692 12.2843 14.6015 12.3929Z" />
  <path
    fill="currentColor"
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M3.125 3.125C2.08947 3.125 1.25 3.96447 1.25 5V15C1.25 16.0355 2.08947 16.875 3.125 16.875H16.875C17.9105 16.875 18.75 16.0355 18.75 15V5C18.75 3.96447 17.9105 3.125 16.875 3.125H3.125ZM2.5 5C2.5 4.65482 2.77982 4.375 3.125 4.375H9.375V15.625H3.125C2.77982 15.625 2.5 15.3452 2.5 15V5ZM10.625 15.625H16.875C17.2202 15.625 17.5 15.3452 17.5 15V5C17.5 4.65482 17.2202 4.375 16.875 4.375L10.625 4.375V15.625Z" />
</template>
