<template>
  <rect
    x="17.375"
    y="2.625"
    width="14.75"
    height="14.75"
    transform="rotate(90 17.375 2.625)"
    stroke="#494949"
    stroke-width="1.25" />
  <rect x="2" y="18" width="16" height="1.25" transform="rotate(-90 2 18)" fill="#E2E2E2" />
</template>
