<template>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M19.405 14.1198C19.8855 13.1798 20.1257 12.0565 20.1257 10.75C20.1257 9.44357 19.8855 8.3223 19.405 7.38626C18.9245 6.44623 18.2658 5.72527 17.4289 5.22339C16.596 4.71753 15.649 4.4646 14.5879 4.4646C13.5228 4.4646 12.5718 4.71753 11.7349 5.22339C10.9021 5.72527 10.2454 6.44623 9.76486 7.38626C9.28435 8.3223 9.0441 9.44357 9.0441 10.75C9.0441 12.0525 9.28435 13.1738 9.76486 14.1138C10.2454 15.0539 10.9021 15.7768 11.7349 16.2827C12.5718 16.7846 13.5228 17.0355 14.5879 17.0355C15.649 17.0355 16.596 16.7846 17.4289 16.2827C18.2658 15.7768 18.9245 15.0559 19.405 14.1198ZM17.8013 8.23467C18.1256 8.9158 18.2878 9.75426 18.2878 10.75C18.2878 11.7458 18.1256 12.5863 17.8013 13.2714C17.481 13.9525 17.0405 14.4703 16.4799 14.8248C15.9233 15.1754 15.2927 15.3506 14.5879 15.3506C13.8792 15.3506 13.2465 15.1754 12.6899 14.8248C12.1333 14.4703 11.6929 13.9525 11.3685 13.2714C11.0482 12.5863 10.888 11.7458 10.888 10.75C10.888 9.75426 11.0482 8.9158 11.3685 8.23467C11.6929 7.54957 12.1333 7.03176 12.6899 6.68124C13.2465 6.32673 13.8792 6.14948 14.5879 6.14948C15.2927 6.14948 15.9233 6.32673 16.4799 6.68124C17.0405 7.03176 17.481 7.54957 17.8013 8.23467Z"
    fill="currentColor" />
  <path
    d="M24.0914 16.8682V11.4192C24.0914 10.9333 24.1835 10.519 24.3677 10.1765C24.5559 9.82994 24.8102 9.56705 25.1305 9.3878C25.4548 9.20856 25.8252 9.11894 26.2417 9.11894C26.8503 9.11894 27.3288 9.30814 27.6772 9.68654C28.0255 10.061 28.1997 10.5808 28.1997 11.246V16.8682H29.9956V11.0309C29.9956 10.2741 29.8655 9.63874 29.6052 9.12491C29.3449 8.61108 28.9785 8.22472 28.506 7.96581C28.0335 7.70292 27.483 7.57148 26.8543 7.57148C26.1616 7.57148 25.587 7.71885 25.1305 8.01361C24.678 8.30836 24.3457 8.69871 24.1334 9.18466H24.0193V7.69097H22.2955V16.8682H24.0914Z"
    fill="currentColor" />
  <path
    d="M2.57694 10.0918C1.70332 10.0918 0.995117 10.8 0.995117 11.6736C0.995117 12.5472 1.70332 13.2555 2.57694 13.2555C3.45055 13.2555 4.15876 12.5472 4.15876 11.6736C4.15876 10.8 3.45055 10.0918 2.57694 10.0918Z"
    fill="currentColor" />
</template>
