<template>
  <path
    fill="currentColor"
    d="M5.625 7.5H6.25V12.5H5.625C5.27982 12.5 5 12.7798 5 13.125C5 13.4702 5.27982 13.75 5.625 13.75H8.125C8.47018 13.75 8.75 13.4702 8.75 13.125C8.75 12.7798 8.47018 12.5 8.125 12.5H7.5V7.5H8.125C8.47018 7.5 8.75 7.22018 8.75 6.875C8.75 6.52982 8.47018 6.25 8.125 6.25H5.625C5.27982 6.25 5 6.52982 5 6.875C5 7.22018 5.27982 7.5 5.625 7.5Z" />
  <path
    fill="currentColor"
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M2.95833 3.75C1.93388 3.75 1.25 4.67643 1.25 5.625V14.375C1.25 15.3236 1.93388 16.25 2.95833 16.25H17.0417C18.0661 16.25 18.75 15.3236 18.75 14.375V5.625C18.75 4.67643 18.0661 3.75 17.0417 3.75H2.95833ZM2.5 5.625C2.5 5.19287 2.78618 5 2.95833 5H17.0417C17.2138 5 17.5 5.19287 17.5 5.625V14.375C17.5 14.8071 17.2138 15 17.0417 15H2.95833C2.78618 15 2.5 14.8071 2.5 14.375V5.625Z" />
</template>
