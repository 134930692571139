<template>
  <path
    fill="currentColor"
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M14.2245 3.37988C14.2245 3.0347 13.9447 2.75488 13.5995 2.75488C13.2544 2.75488 12.9745 3.0347 12.9745 3.37988V4.02515L2.76509 7.55586C2.65053 7.42496 2.48222 7.34229 2.2946 7.34229C1.94943 7.34229 1.6696 7.62211 1.6696 7.96729V8.36532C1.66938 8.37498 1.66938 8.38467 1.6696 8.39438V10.8654C1.66938 10.8751 1.66938 10.8848 1.6696 10.8944V11.2568C1.6696 11.6019 1.94943 11.8818 2.2946 11.8818C2.46931 11.8818 2.62728 11.8101 2.74069 11.6945L3.91744 12.0997C3.91609 12.7814 3.92602 13.6912 4.16954 14.4587C4.31337 14.912 4.55259 15.3672 4.9615 15.7075C5.37959 16.0553 5.91761 16.2352 6.56498 16.2322C7.45548 16.2317 8.14028 15.8454 8.58409 15.249C8.87949 14.8521 9.05608 14.3803 9.13708 13.8971L12.9745 15.2186V15.8799C12.9745 16.2251 13.2544 16.5049 13.5995 16.5049C13.9447 16.5049 14.2245 16.2251 14.2245 15.8799V3.37988ZM7.93063 13.4817L5.1708 12.5313C5.17995 13.0822 5.21687 13.6264 5.36101 14.0807C5.45961 14.3915 5.59414 14.6077 5.76101 14.7466C5.91835 14.8775 6.15759 14.9843 6.56007 14.9822L6.56334 14.9822C7.05414 14.9822 7.36983 14.7869 7.58129 14.5028C7.77284 14.2454 7.89621 13.8873 7.93063 13.4817ZM12.9745 13.8965L2.9196 10.4341V8.82506L12.9745 5.34779V13.8965Z" />
  <path
    fill="currentColor"
    d="M17.7054 6.64147C18.0314 6.5279 18.2035 6.17159 18.09 5.84563C17.9764 5.51967 17.6201 5.3475 17.2941 5.46107L15.6336 6.03963C15.3077 6.1532 15.1355 6.50951 15.2491 6.83547C15.3627 7.16143 15.719 7.3336 16.0449 7.22003L17.7054 6.64147Z" />
  <path
    fill="currentColor"
    d="M15.2492 12.4386C15.1357 12.7646 15.3078 13.1209 15.6338 13.2345L17.2943 13.813C17.6202 13.9266 17.9766 13.7544 18.0901 13.4285C18.2037 13.1025 18.0315 12.7462 17.7056 12.6326L16.0451 12.0541C15.7191 11.9405 15.3628 12.1127 15.2492 12.4386Z" />
  <path
    fill="currentColor"
    d="M17.4999 9.01221C17.8451 9.01221 18.1249 9.29203 18.1249 9.63721C18.1249 9.98239 17.8451 10.2622 17.4999 10.2622H15.8394C15.4943 10.2622 15.2144 9.98239 15.2144 9.63721C15.2144 9.29203 15.4943 9.01221 15.8394 9.01221H17.4999Z" />
</template>
