const MAX_DOMAIN_CHARACTERS = 63;
const SATISFIED_DOMAIN_REGEX =
  // eslint-disable-next-line no-useless-escape
  /^(((?!\-))(xn\-\-)?[a-z0-9\-_]{0,61}[a-z0-9]{1,1}\.)*(xn\-\-)?([a-z0-9\-]{1,61}|[a-z0-9\-]{1,30})\.[a-z]{2,}$/;
// eslint-disable-next-line no-useless-escape
const REJECT_CHARACTER_DOMAIN_REGEX = /[^-\.a-z0-9]/;

export const validateDomainNameFormat = (domainName: string): { isValid: boolean; message: string } => {
  if (domainName === '') {
    return { isValid: true, message: '' };
  }
  if (isDomainContainUppercaseAndSpecialCharacters(domainName)) {
    return { isValid: false, message: 'Domain only allows lowercase letters, digits, and hyphens (-)' };
  }
  if (isDomainStartOrEndWithHyphens(domainName)) {
    return { isValid: false, message: 'Domain name must not start or end with a hyphens' };
  }
  if (isDomainTooLong(domainName)) {
    return { isValid: false, message: `Domain name should be between 1 and ${MAX_DOMAIN_CHARACTERS} characters` };
  }
  if (isNotDomainFormat(domainName)) {
    return { isValid: false, message: 'Missing domain name extension (e.g.: net, org, com,...)' };
  }
  return { isValid: true, message: '' };
};

const isDomainContainUppercaseAndSpecialCharacters = (domainName: string) => {
  return REJECT_CHARACTER_DOMAIN_REGEX.test(domainName);
};

const isDomainStartOrEndWithHyphens = (domainName: string) => {
  return (
    domainName.startsWith('-') ||
    domainName.endsWith('-') ||
    domainName.split('.').find((item) => item.startsWith('-') || item.endsWith('-'))
  );
};

const isDomainTooLong = (domainName: string) => {
  return domainName.length > MAX_DOMAIN_CHARACTERS;
};

const isNotDomainFormat = (domainName: string) => {
  return !SATISFIED_DOMAIN_REGEX.test(domainName);
};
