<template>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M13.1869 9.7476C13.1859 7.9867 11.7563 6.56116 9.99519 6.56201C8.234 6.56257 6.8052 7.98904 6.8052 9.74998C6.8052 11.5113 8.23444 12.938 9.99604 12.938C11.7574 12.938 13.1869 11.5122 13.1869 9.75067V9.7476ZM9.99569 7.81201C11.0679 7.81144 11.9363 8.67899 11.9369 9.74827V9.75067C11.9369 10.8201 11.0688 11.688 9.99604 11.688C8.92348 11.688 8.0552 10.8196 8.0552 9.74998C8.0552 8.68053 8.92334 7.8123 9.99569 7.81201Z"
    fill="currentColor" />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M9.99495 4C5.53883 4.00011 2.6045 7.3697 1.57732 8.75973C1.13893 9.35296 1.14137 10.1493 1.58181 10.74C2.61541 12.1265 5.56906 15.5001 9.99497 15.5001C14.5001 15.5001 17.4144 12.1196 18.4277 10.7303C18.8575 10.141 18.8544 9.35559 18.4219 8.76948C17.4003 7.38492 14.4612 3.99989 9.99495 4ZM2.58261 9.50261C3.57037 8.16594 6.17801 5.25009 9.99498 5.25C13.821 5.24991 16.4332 8.17966 17.4161 9.51165C17.5251 9.65938 17.5254 9.84616 17.4178 9.99369C16.4475 11.324 13.8596 14.2501 9.99497 14.2501C6.20813 14.2501 3.58151 11.331 2.58396 9.99291C2.47237 9.84323 2.4721 9.65215 2.58261 9.50261Z"
    fill="currentColor" />
</template>
