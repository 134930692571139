import warningIcon from './Warning.vue';
import abTestingTabIcon from './ab-testing-tab.vue';
import abTestingIcon from './ab-testing.vue';
import accountIcon from './account.vue';
import addCircleIcon from './add-circle.vue';
import agencyIcon from './agency.vue';
import alignBottomIcon from './align-bottom.vue';
import alignCenterHozIcon from './align-center-hoz.vue';
import alignCenterIcon from './align-center.vue';
import alignFlexBaselineIcon from './align-flex-baseline.vue';
import alignFlexCenterIcon from './align-flex-center.vue';
import alignFlexFlexEndIcon from './align-flex-flex-end.vue';
import alignFlexFlexStartIcon from './align-flex-flex-start.vue';
import alignFlexNormalIcon from './align-flex-normal.vue';
import alignFlexStretchIcon from './align-flex-stretch.vue';
import alignJustifyIcon from './align-justify.vue';
import alignLeftIcon from './align-left.vue';
import alignRightIcon from './align-right.vue';
import alignTopIcon from './align-top.vue';
import alignVBottomIcon from './align-v-bottom.vue';
import alignVCenterIcon from './align-v-center.vue';
import alignVTopIcon from './align-v-top.vue';
import animationIcon from './animation.vue';
import arrangeHorizontalIcon from './arrange-horizontal.vue';
import arrangeVerticalIcon from './arrange-vertical.vue';
import arrowLeftIcon from './arrow-left.vue';
import arrowUpIcon from './arrow-up.vue';
import articleIcon from './article.vue';
import awardFillIcon from './award-fill.vue';
import backIcon from './back.vue';
import bgColorIcon from './bg-color.vue';
import bgImageIcon from './bg-image.vue';
import bigDotIcon from './big-dot.vue';
import borderDashedIcon from './border-dashed.vue';
import borderDottedIcon from './border-dotted.vue';
import borderNoneIcon from './border-none.vue';
import borderPositionAllIcon from './border-position-all.vue';
import borderPositionBottomIcon from './border-position-bottom.vue';
import borderPositionLeftIcon from './border-position-left.vue';
import borderPositionRightIcon from './border-position-right.vue';
import borderPositionTopIcon from './border-position-top.vue';
import borderRadiusBottomLeftIcon from './border-radius-bottom-left.vue';
import borderSolidIcon from './border-solid.vue';
import borderWidthIcon from './border-width.vue';
import boxAlignBottomCenterIcon from './box-align-bottom-center.vue';
import boxAlignBottomLeftIcon from './box-align-bottom-left.vue';
import boxAlignLeftIcon from './box-align-left.vue';
import boxAlignRightIcon from './box-align-right.vue';
import calendarIcon from './calendar.vue';
import chatIcon from './chat.vue';
import checkFillIcon from './check-fill.vue';
import checkOutlineIcon from './check-outline.vue';
import checkRoundIcon from './check-round.vue';
import checkIcon from './check.vue';
import chervonIcon from './chervon.vue';
import chevronDownIcon from './chevron-down.vue';
import chevronLeftSmallIcon from './chevron-left-small.vue';
import chevronLeftIcon from './chevron-left.vue';
import chevronRightSmallIcon from './chevron-right-small.vue';
import chevronUpIcon from './chevron-up.vue';
import circleCloseIcon from './circle-close.vue';
import circleMinusIcon from './circle-minus.vue';
import circlePlusIcon from './circle-plus.vue';
import clearSearchIcon from './clear-search.vue';
import clockIcon from './clock.vue';
import closeMoreSettingIcon from './close-more-setting.vue';
import closeRoundIcon from './close-round.vue';
import closeTooltipIcon from './close-tooltip.vue';
import closeIcon from './close.vue';
import collectionPageIcon from './collection-page.vue';
import colorPaletteIcon from './color-palette.vue';
import colorSettingIcon from './color-setting.vue';
import copyIcon from './copy.vue';
import cornerStyleIcon from './corner-style.vue';
import cursorTargetIcon from './cursor-target.vue';
import cursorIcon from './cursor.vue';
import customizeLayoutIcon from './customize-layout.vue';
import dashboardIcon from './dashboard.vue';
import defaultImageIcon from './default-image.vue';
import desktopIcon from './desktop.vue';
import dotCircleBoldIcon from './dot-circle-bold.vue';
import dotCircleThinIcon from './dot-circle-thin.vue';
import doubleCheckIcon from './double-check.vue';
import duplicatePageIcon from './duplicate-page.vue';
import dynamicContentIcon from './dynamic-content.vue';
import editOnShopifyIcon from './edit-on-shopify.vue';
import editIcon from './edit.vue';
import errorTransparentIcon from './error-transparent.vue';
import errorIcon from './error.vue';
import exportIcon from './export.vue';
import externalLinkIcon from './external-link.vue';
import filterIcon from './filter.vue';
import fireIcon from './fire.vue';
import flowActionIcon from './flow-action.vue';
import generalBase20Icon from './general-base-20.vue';
import generalLiveViewIcon from './general-live-view.vue';
import generalRedoIcon from './general-redo.vue';
import generalUndoIcon from './general-undo.vue';
import generalUploadIcon from './general-upload.vue';
import getStartedEditorIcon from './get-started-editor.vue';
import getStartedGuideIcon from './get-started-guide.vue';
import globalStyleIcon from './global-style.vue';
import groupIcon from './group.vue';
import headerFooterIcon from './header-footer.vue';
import helpWheelIcon from './help-wheel.vue';
import homepageIcon from './homepage.vue';
import hotkeysIcon from './hotkeys.vue';
import iconIsNotExistIcon from './icon-is-not-exist.vue';
import imageSettingIcon from './image-setting.vue';
import import1Icon from './import-1.vue';
import importIcon from './import.vue';
import infoIcon from './info.vue';
import info24PxIcon from './info24px.vue';
import instantArrowDownIcon from './instant-arrow-down.vue';
import instantArrowRightIcon from './instant-arrow-right.vue';
import instantBackIcon from './instant-back.vue';
import instantCopy24WelcomeIcon from './instant-copy-24-welcome.vue';
import instantCopy24Icon from './instant-copy-24.vue';
import instantDownloadIcon from './instant-download.vue';
import instantEye16Icon from './instant-eye-16.vue';
import instantEyeMediumIcon from './instant-eye-medium.vue';
import instantEyePreviewIcon from './instant-eye-preview.vue';
import instantEyeSmallIcon from './instant-eye-small.vue';
import instantEyeViewCurrentIcon from './instant-eye-view-current.vue';
import instantFlowActionIcon from './instant-flow-action.vue';
import instantGlobalStyleIcon from './instant-global-style.vue';
import instantLibraryIcon from './instant-library.vue';
import instantLogoIcon from './instant-logo.vue';
import instantMoreIcon from './instant-more.vue';
import instantShortcutsIcon from './instant-shortcuts.vue';
import instantWelcomeImageIcon from './instant-welcome-image.vue';
import integrationIcon from './integration.vue';
import introductionIcon from './introduction.vue';
import itemDragIcon from './item-drag.vue';
import justifyCenterIcon from './justify-center.vue';
import justifyFlexEndIcon from './justify-flex-end.vue';
import justifyFlexStartIcon from './justify-flex-start.vue';
import justifyNormalIcon from './justify-normal.vue';
import justifySpaceAroundIcon from './justify-space-around.vue';
import justifySpaceBetweenIcon from './justify-space-between.vue';
import justifySpaceEvenlyIcon from './justify-space-evenly.vue';
import landingPageIcon from './landing-page.vue';
import laptopIcon from './laptop.vue';
import layoutAlignBottomIcon from './layout-align-bottom.vue';
import layoutAlignLeftIcon from './layout-align-left.vue';
import layoutAlignRightIcon from './layout-align-right.vue';
import layoutAlignTopIcon from './layout-align-top.vue';
import layoutHorizontalIcon from './layout-horizontal.vue';
import layoutVerticalIcon from './layout-vertical.vue';
import libraryEyePreviewIcon from './library-eye-preview.vue';
import linkBrokenIcon from './link-broken.vue';
import linkImageIcon from './link-image.vue';
import linkSpacingIcon from './link-spacing.vue';
import linkIcon from './link.vue';
import livePageIcon from './live-page.vue';
import liveViewIcon from './live-view.vue';
import loadingIcon from './loading.vue';
import lockIcon from './lock.vue';
import loginIcon from './login.vue';
import logoIcon from './logo.vue';
import logoutIcon from './logout.vue';
import longLeftIcon from './long-left.vue';
import mailUnsubscribeIcon from './mail-unsubscribe.vue';
import mailIcon from './mail.vue';
import marginBottomIcon from './margin-bottom.vue';
import marginLeftIcon from './margin-left.vue';
import marginRightIcon from './margin-right.vue';
import marginTopIcon from './margin-top.vue';
import minusCircleIcon from './minus-circle.vue';
import minusGemxIcon from './minus-gemx.vue';
import minusIcon from './minus.vue';
import mobileIcon from './mobile.vue';
import moreSetting20Icon from './more-setting-20.vue';
import moveDirectionIcon from './move-direction.vue';
import navigationLeftCircleIcon from './navigation-left-circle.vue';
import newGroupIcon from './new-group.vue';
import noCheckIcon from './no-check.vue';
import noImageIcon from './no-image.vue';
import noneIcon from './none.vue';
import normalDomainFillIcon from './normal-domain-fill.vue';
import normalDomainIcon from './normal-domain.vue';
import noticeIcon from './notice.vue';
import notificationIcon from './notification.vue';
import onIcon from './on.vue';
import openNewTabIcon from './open-new-tab.vue';
import paddingBottomIcon from './padding-bottom.vue';
import paddingLeftIcon from './padding-left.vue';
import paddingRightIcon from './padding-right.vue';
import paddingTopIcon from './padding-top.vue';
import pageSpeedIcon from './page-speed.vue';
import peopleIcon from './people.vue';
import pickActionIcon from './pick-action.vue';
import pickActionsIcon from './pick-actions.vue';
import pickLinkIcon from './pick-link.vue';
import plusBoldIcon from './plus-bold.vue';
import plusGemuiIcon from './plus-gemui.vue';
import plusMediumIcon from './plus-medium.vue';
import plusIcon from './plus.vue';
import previewIcon from './preview.vue';
import pricingCheckedIcon from './pricing-checked.vue';
import pricingCreditCardIcon from './pricing-credit-card.vue';
import pricingLockIcon from './pricing-lock.vue';
import primaryDomainIcon from './primary-domain.vue';
import productOneLayoutIcon from './product-one-layout.vue';
import productPageIcon from './product-page.vue';
import productTwoLayoutIcon from './product-two-layout.vue';
import publishIcon from './publish.vue';
import puzzleIcon from './puzzle.vue';
import qrcodeIcon from './qrcode.vue';
import questionIcon from './question.vue';
import redoIcon from './redo.vue';
import refreshMediumIcon from './refresh-medium.vue';
import refreshIcon from './refresh.vue';
import reloadOutlineIcon from './reload-outline.vue';
import reloadIcon from './reload.vue';
import renameIcon from './rename.vue';
import repeatTileIcon from './repeat-tile.vue';
import resetIcon from './reset.vue';
import roundIcon from './round.vue';
import schedulePublishIcon from './schedule-publish.vue';
import searchIcon from './search.vue';
import searchIcon16 from './search16.vue';
import selectProductIcon from './select-product.vue';
import selectIcon from './select.vue';
import setGroupIcon from './set-group.vue';
import settingIcon from './setting.vue';
import shareIcon from './share.vue';
import shieldIcon from './shield.vue';
import smallDotIcon from './small-dot.vue';
import smallLoadingIcon from './small-loading.vue';
import spacingBottomIcon from './spacing-bottom.vue';
import spacingLeftIcon from './spacing-left.vue';
import spacingLinkIcon from './spacing-link.vue';
import spacingMIcon from './spacing-m.vue';
import spacingPIcon from './spacing-p.vue';
import spacingRightIcon from './spacing-right.vue';
import spacingTopIcon from './spacing-top.vue';
import spacingUnLinkIcon from './spacing-un-link.vue';
import spacingIcon from './spacing.vue';
import squareIcon from './square.vue';
import startIcon from './start.vue';
import statusCheckIcon from './status-check.vue';
import statusErrorIcon from './status-error.vue';
import stopIcon from './stop.vue';
import storeManagerIcon from './store-manager.vue';
import strikethroughIcon from './strikethrough.vue';
import successTransparentIcon from './success-transparent.vue';
import successIcon from './success.vue';
import supportIcon from './support.vue';
import syncPublishIcon from './sync-publish.vue';
import syncIcon from './sync.vue';
import tabletIcon from './tablet.vue';
import textCenterIcon from './text-center.vue';
import textJustifyIcon from './text-justify.vue';
import textLeftIcon from './text-left.vue';
import textRightIcon from './text-right.vue';
import textStyleIcon from './text-style.vue';
import thankYouPageIcon from './thank-you-page.vue';
import thumbUpIcon from './thumb-up.vue';
import ticketIcon from './ticket.vue';
import tinyLoadingIcon from './tiny-loading.vue';
import tipsLightbulbIcon from './tips-lightbulb.vue';
import trashIcon from './trash.vue';
import tutorialIcon from './tutorial.vue';
import underlineIcon from './underline.vue';
import undoIcon from './undo.vue';
import ungroupIcon from './ungroup.vue';
import unlinkIcon from './unlink.vue';
import unpublishIcon from './unpublish.vue';
import upgradeVersionIcon from './upgrade-version.vue';
import upgradeIcon from './upgrade.vue';
import uploadIcon from './upload.vue';
import variantStyleColorLightIcon from './variant-style-color-light.vue';
import variantStyleColorIcon from './variant-style-color.vue';
import variantStyleDropdownLightIcon from './variant-style-dropdown-light.vue';
import variantStyleDropdownIcon from './variant-style-dropdown.vue';
import variantStyleImageLightIcon from './variant-style-image-light.vue';
import variantStyleImageShopifyLightIcon from './variant-style-image-shopify-light.vue';
import variantStyleImageShopifyIcon from './variant-style-image-shopify.vue';
import variantStyleImageIcon from './variant-style-image.vue';
import variantStyleListLightIcon from './variant-style-list-light.vue';
import variantStyleListIcon from './variant-style-list.vue';
import warningSegmentIcon from './warning-segment.vue';

export const icons = {
  Warning: warningIcon,
  'ab-testing-tab': abTestingTabIcon,
  'ab-testing': abTestingIcon,
  account: accountIcon,
  'add-circle': addCircleIcon,
  agency: agencyIcon,
  'align-bottom': alignBottomIcon,
  'align-center-hoz': alignCenterHozIcon,
  'align-center': alignCenterIcon,
  'align-flex-baseline': alignFlexBaselineIcon,
  'align-flex-center': alignFlexCenterIcon,
  'align-flex-flex-end': alignFlexFlexEndIcon,
  'align-flex-flex-start': alignFlexFlexStartIcon,
  'align-flex-normal': alignFlexNormalIcon,
  'align-flex-stretch': alignFlexStretchIcon,
  'align-justify': alignJustifyIcon,
  'align-left': alignLeftIcon,
  'align-right': alignRightIcon,
  'align-top': alignTopIcon,
  'align-v-bottom': alignVBottomIcon,
  'align-v-center': alignVCenterIcon,
  'align-v-top': alignVTopIcon,
  animation: animationIcon,
  'arrange-horizontal': arrangeHorizontalIcon,
  'arrange-vertical': arrangeVerticalIcon,
  'arrow-left': arrowLeftIcon,
  'arrow-up': arrowUpIcon,
  article: articleIcon,
  'award-fill': awardFillIcon,
  back: backIcon,
  'bg-color': bgColorIcon,
  'bg-image': bgImageIcon,
  'big-dot': bigDotIcon,
  'border-dashed': borderDashedIcon,
  'border-dotted': borderDottedIcon,
  'border-none': borderNoneIcon,
  'border-position-all': borderPositionAllIcon,
  'border-position-bottom': borderPositionBottomIcon,
  'border-position-left': borderPositionLeftIcon,
  'border-position-right': borderPositionRightIcon,
  'border-position-top': borderPositionTopIcon,
  'border-radius-bottom-left': borderRadiusBottomLeftIcon,
  'border-solid': borderSolidIcon,
  'border-width': borderWidthIcon,
  'box-align-bottom-center': boxAlignBottomCenterIcon,
  'box-align-bottom-left': boxAlignBottomLeftIcon,
  'box-align-left': boxAlignLeftIcon,
  'box-align-right': boxAlignRightIcon,
  calendar: calendarIcon,
  chat: chatIcon,
  'check-fill': checkFillIcon,
  'check-outline': checkOutlineIcon,
  'check-round': checkRoundIcon,
  check: checkIcon,
  chervon: chervonIcon,
  'chevron-down': chevronDownIcon,
  'chevron-left-small': chevronLeftSmallIcon,
  'chevron-left': chevronLeftIcon,
  'chevron-right-small': chevronRightSmallIcon,
  'chevron-up': chevronUpIcon,
  'circle-close': circleCloseIcon,
  'circle-minus': circleMinusIcon,
  'circle-plus': circlePlusIcon,
  'clear-search': clearSearchIcon,
  clock: clockIcon,
  'close-more-setting': closeMoreSettingIcon,
  'close-round': closeRoundIcon,
  'close-tooltip': closeTooltipIcon,
  close: closeIcon,
  'collection-page': collectionPageIcon,
  'color-palette': colorPaletteIcon,
  'color-setting': colorSettingIcon,
  copy: copyIcon,
  'corner-style': cornerStyleIcon,
  'cursor-target': cursorTargetIcon,
  cursor: cursorIcon,
  'customize-layout': customizeLayoutIcon,
  dashboard: dashboardIcon,
  'default-image': defaultImageIcon,
  desktop: desktopIcon,
  'dot-circle-bold': dotCircleBoldIcon,
  'dot-circle-thin': dotCircleThinIcon,
  'double-check': doubleCheckIcon,
  'duplicate-page': duplicatePageIcon,
  'dynamic-content': dynamicContentIcon,
  'edit-on-shopify': editOnShopifyIcon,
  edit: editIcon,
  'error-transparent': errorTransparentIcon,
  error: errorIcon,
  export: exportIcon,
  'external-link': externalLinkIcon,
  filter: filterIcon,
  fire: fireIcon,
  'flow-action': flowActionIcon,
  'general-base-20': generalBase20Icon,
  'general-live-view': generalLiveViewIcon,
  'general-redo': generalRedoIcon,
  'general-undo': generalUndoIcon,
  'general-upload': generalUploadIcon,
  'get-started-editor': getStartedEditorIcon,
  'get-started-guide': getStartedGuideIcon,
  'global-style': globalStyleIcon,
  group: groupIcon,
  'header-footer': headerFooterIcon,
  'help-wheel': helpWheelIcon,
  homepage: homepageIcon,
  hotkeys: hotkeysIcon,
  'icon-is-not-exist': iconIsNotExistIcon,
  'image-setting': imageSettingIcon,
  'import-1': import1Icon,
  import: importIcon,
  info: infoIcon,
  info24px: info24PxIcon,
  'instant-arrow-down': instantArrowDownIcon,
  'instant-arrow-right': instantArrowRightIcon,
  'instant-back': instantBackIcon,
  'instant-copy-24-welcome': instantCopy24WelcomeIcon,
  'instant-copy-24': instantCopy24Icon,
  'instant-download': instantDownloadIcon,
  'instant-eye-16': instantEye16Icon,
  'instant-eye-medium': instantEyeMediumIcon,
  'instant-eye-preview': instantEyePreviewIcon,
  'instant-eye-small': instantEyeSmallIcon,
  'instant-eye-view-current': instantEyeViewCurrentIcon,
  'instant-flow-action': instantFlowActionIcon,
  'instant-global-style': instantGlobalStyleIcon,
  'instant-library': instantLibraryIcon,
  'instant-logo': instantLogoIcon,
  'instant-more': instantMoreIcon,
  'instant-shortcuts': instantShortcutsIcon,
  'instant-welcome-image': instantWelcomeImageIcon,
  integration: integrationIcon,
  introduction: introductionIcon,
  'item-drag': itemDragIcon,
  'justify-center': justifyCenterIcon,
  'justify-flex-end': justifyFlexEndIcon,
  'justify-flex-start': justifyFlexStartIcon,
  'justify-normal': justifyNormalIcon,
  'justify-space-around': justifySpaceAroundIcon,
  'justify-space-between': justifySpaceBetweenIcon,
  'justify-space-evenly': justifySpaceEvenlyIcon,
  'landing-page': landingPageIcon,
  laptop: laptopIcon,
  'layout-align-bottom': layoutAlignBottomIcon,
  'layout-align-left': layoutAlignLeftIcon,
  'layout-align-right': layoutAlignRightIcon,
  'layout-align-top': layoutAlignTopIcon,
  'layout-horizontal': layoutHorizontalIcon,
  'layout-vertical': layoutVerticalIcon,
  'library-eye-preview': libraryEyePreviewIcon,
  'link-broken': linkBrokenIcon,
  'link-image': linkImageIcon,
  'link-spacing': linkSpacingIcon,
  link: linkIcon,
  'live-page': livePageIcon,
  'live-view': liveViewIcon,
  loading: loadingIcon,
  lock: lockIcon,
  login: loginIcon,
  logo: logoIcon,
  logout: logoutIcon,
  'long-left': longLeftIcon,
  'mail-unsubscribe': mailUnsubscribeIcon,
  mail: mailIcon,
  'margin-bottom': marginBottomIcon,
  'margin-left': marginLeftIcon,
  'margin-right': marginRightIcon,
  'margin-top': marginTopIcon,
  'minus-circle': minusCircleIcon,
  'minus-gemx': minusGemxIcon,
  minus: minusIcon,
  mobile: mobileIcon,
  'more-setting-20': moreSetting20Icon,
  'move-direction': moveDirectionIcon,
  'navigation-left-circle': navigationLeftCircleIcon,
  'new-group': newGroupIcon,
  'no-check': noCheckIcon,
  'no-image': noImageIcon,
  none: noneIcon,
  'normal-domain-fill': normalDomainFillIcon,
  'normal-domain': normalDomainIcon,
  notice: noticeIcon,
  notification: notificationIcon,
  on: onIcon,
  'open-new-tab': openNewTabIcon,
  'padding-bottom': paddingBottomIcon,
  'padding-left': paddingLeftIcon,
  'padding-right': paddingRightIcon,
  'padding-top': paddingTopIcon,
  'page-speed': pageSpeedIcon,
  people: peopleIcon,
  'pick-action': pickActionIcon,
  'pick-actions': pickActionsIcon,
  'pick-link': pickLinkIcon,
  'plus-bold': plusBoldIcon,
  'plus-gemui': plusGemuiIcon,
  'plus-medium': plusMediumIcon,
  plus: plusIcon,
  preview: previewIcon,
  'pricing-checked': pricingCheckedIcon,
  'pricing-credit-card': pricingCreditCardIcon,
  'pricing-lock': pricingLockIcon,
  'primary-domain': primaryDomainIcon,
  'product-one-layout': productOneLayoutIcon,
  'product-page': productPageIcon,
  'product-two-layout': productTwoLayoutIcon,
  publish: publishIcon,
  puzzle: puzzleIcon,
  qrcode: qrcodeIcon,
  question: questionIcon,
  redo: redoIcon,
  'refresh-medium': refreshMediumIcon,
  refresh: refreshIcon,
  'reload-outline': reloadOutlineIcon,
  reload: reloadIcon,
  rename: renameIcon,
  'repeat-tile': repeatTileIcon,
  reset: resetIcon,
  round: roundIcon,
  'schedule-publish': schedulePublishIcon,
  search: searchIcon,
  search16: searchIcon16,
  'select-product': selectProductIcon,
  select: selectIcon,
  'set-group': setGroupIcon,
  setting: settingIcon,
  share: shareIcon,
  shield: shieldIcon,
  'small-dot': smallDotIcon,
  'small-loading': smallLoadingIcon,
  'spacing-bottom': spacingBottomIcon,
  'spacing-left': spacingLeftIcon,
  'spacing-link': spacingLinkIcon,
  'spacing-m': spacingMIcon,
  'spacing-p': spacingPIcon,
  'spacing-right': spacingRightIcon,
  'spacing-top': spacingTopIcon,
  'spacing-un-link': spacingUnLinkIcon,
  spacing: spacingIcon,
  square: squareIcon,
  start: startIcon,
  'status-check': statusCheckIcon,
  'status-error': statusErrorIcon,
  stop: stopIcon,
  'store-manager': storeManagerIcon,
  strikethrough: strikethroughIcon,
  'success-transparent': successTransparentIcon,
  success: successIcon,
  support: supportIcon,
  'sync-publish': syncPublishIcon,
  sync: syncIcon,
  tablet: tabletIcon,
  'text-center': textCenterIcon,
  'text-justify': textJustifyIcon,
  'text-left': textLeftIcon,
  'text-right': textRightIcon,
  'text-style': textStyleIcon,
  'thank-you-page': thankYouPageIcon,
  'thumb-up': thumbUpIcon,
  ticket: ticketIcon,
  'tiny-loading': tinyLoadingIcon,
  'tips-lightbulb': tipsLightbulbIcon,
  trash: trashIcon,
  tutorial: tutorialIcon,
  underline: underlineIcon,
  undo: undoIcon,
  ungroup: ungroupIcon,
  unlink: unlinkIcon,
  unpublish: unpublishIcon,
  'upgrade-version': upgradeVersionIcon,
  upgrade: upgradeIcon,
  upload: uploadIcon,
  'variant-style-color-light': variantStyleColorLightIcon,
  'variant-style-color': variantStyleColorIcon,
  'variant-style-dropdown-light': variantStyleDropdownLightIcon,
  'variant-style-dropdown': variantStyleDropdownIcon,
  'variant-style-image-light': variantStyleImageLightIcon,
  'variant-style-image-shopify-light': variantStyleImageShopifyLightIcon,
  'variant-style-image-shopify': variantStyleImageShopifyIcon,
  'variant-style-image': variantStyleImageIcon,
  'variant-style-list-light': variantStyleListLightIcon,
  'variant-style-list': variantStyleListIcon,
  'warning-segment': warningSegmentIcon,
};
