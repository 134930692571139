<template>
  <path
    d="M5.5 4.5C6.32843 4.5 7 3.82843 7 3C7 2.17157 6.32843 1.5 5.5 1.5C4.67157 1.5 4 2.17157 4 3C4 3.82843 4.67157 4.5 5.5 4.5Z"
    fill="#E2E2E2" />
  <path
    d="M5.5 9.5C6.32843 9.5 7 8.82843 7 8C7 7.17157 6.32843 6.5 5.5 6.5C4.67157 6.5 4 7.17157 4 8C4 8.82843 4.67157 9.5 5.5 9.5Z"
    fill="#E2E2E2" />
  <path
    d="M7 13C7 13.8284 6.32843 14.5 5.5 14.5C4.67157 14.5 4 13.8284 4 13C4 12.1716 4.67157 11.5 5.5 11.5C6.32843 11.5 7 12.1716 7 13Z"
    fill="#E2E2E2" />
  <path
    d="M10.5 4.5C11.3284 4.5 12 3.82843 12 3C12 2.17157 11.3284 1.5 10.5 1.5C9.67157 1.5 9 2.17157 9 3C9 3.82843 9.67157 4.5 10.5 4.5Z"
    fill="#E2E2E2" />
  <path
    d="M12 8C12 8.82843 11.3284 9.5 10.5 9.5C9.67157 9.5 9 8.82843 9 8C9 7.17157 9.67157 6.5 10.5 6.5C11.3284 6.5 12 7.17157 12 8Z"
    fill="#E2E2E2" />
  <path
    d="M10.5 14.5C11.3284 14.5 12 13.8284 12 13C12 12.1716 11.3284 11.5 10.5 11.5C9.67157 11.5 9 12.1716 9 13C9 13.8284 9.67157 14.5 10.5 14.5Z"
    fill="#E2E2E2" />
</template>
