<template>
  <path
    d="M15 2.25C15 2.52614 14.7761 2.75 14.5 2.75L1.5 2.75C1.22386 2.75 1 2.52614 1 2.25C1 1.97386 1.22386 1.75 1.5 1.75L14.5 1.75C14.7761 1.75 15 1.97386 15 2.25Z"
    fill="currentColor" />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M11 3.25C11.8284 3.25 12.5 3.92157 12.5 4.75L12.5 9.75C12.5 10.5784 11.8284 11.25 11 11.25L10 11.25C9.17157 11.25 8.5 10.5784 8.5 9.75L8.5 4.75C8.5 3.92157 9.17157 3.25 10 3.25L11 3.25ZM11.5 4.75C11.5 4.47386 11.2761 4.25 11 4.25L10 4.25C9.72386 4.25 9.5 4.47386 9.5 4.75L9.5 9.75C9.5 10.0261 9.72386 10.25 10 10.25L11 10.25C11.2761 10.25 11.5 10.0261 11.5 9.75L11.5 4.75Z"
    fill="currentColor" />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M7.5 4.75C7.5 3.92157 6.82843 3.25 6 3.25L5 3.25C4.17157 3.25 3.5 3.92157 3.5 4.75L3.5 12.75C3.5 13.5784 4.17157 14.25 5 14.25L6 14.25C6.82843 14.25 7.5 13.5784 7.5 12.75L7.5 4.75ZM6 4.25C6.27614 4.25 6.5 4.47386 6.5 4.75L6.5 12.75C6.5 13.0261 6.27614 13.25 6 13.25L5 13.25C4.72386 13.25 4.5 13.0261 4.5 12.75L4.5 4.75C4.5 4.47386 4.72386 4.25 5 4.25L6 4.25Z"
    fill="currentColor" />
</template>
