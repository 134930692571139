<template>
  <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.91675 1C2.19289 1 2.41675 1.22386 2.41675 1.5L2.41675 14.5C2.41675 14.7761 2.19289 15 1.91675 15C1.64061 15 1.41675 14.7761 1.41675 14.5V1.5C1.41675 1.22386 1.64061 1 1.91675 1Z"
      fill="currentColor" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.91675 5C2.91675 4.17157 3.58832 3.5 4.41675 3.5H9.41675C10.2452 3.5 10.9167 4.17157 10.9167 5V6C10.9167 6.82843 10.2452 7.5 9.41675 7.5H4.41675C3.58832 7.5 2.91675 6.82843 2.91675 6V5ZM4.41675 4.5C4.14061 4.5 3.91675 4.72386 3.91675 5V6C3.91675 6.27614 4.14061 6.5 4.41675 6.5H9.41675C9.69289 6.5 9.91675 6.27614 9.91675 6V5C9.91675 4.72386 9.69289 4.5 9.41675 4.5H4.41675Z"
      fill="currentColor" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.41675 8.5C3.58832 8.5 2.91675 9.17157 2.91675 10V11C2.91675 11.8284 3.58832 12.5 4.41675 12.5H12.4167C13.2452 12.5 13.9167 11.8284 13.9167 11V10C13.9167 9.17157 13.2452 8.5 12.4167 8.5H4.41675ZM3.91675 10C3.91675 9.72386 4.14061 9.5 4.41675 9.5H12.4167C12.6929 9.5 12.9167 9.72386 12.9167 10V11C12.9167 11.2761 12.6929 11.5 12.4167 11.5H4.41675C4.14061 11.5 3.91675 11.2761 3.91675 11V10Z"
      fill="currentColor" />
  </svg>
</template>
