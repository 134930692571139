<template>
  <path
    d="M12.084 14C12.3601 14 12.584 13.7761 12.584 13.5L12.584 0.5C12.584 0.223858 12.3601 0 12.084 0C11.8078 0 11.584 0.223858 11.584 0.5L11.584 13.5C11.584 13.7761 11.8078 14 12.084 14Z"
    fill="currentColor" />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M3.08398 5C3.08398 5.82843 3.75556 6.5 4.58398 6.5H9.58398C10.4124 6.5 11.084 5.82843 11.084 5V4C11.084 3.17157 10.4124 2.5 9.58398 2.5L4.58398 2.5C3.75556 2.5 3.08398 3.17157 3.08398 4V5ZM4.58398 5.5C4.30784 5.5 4.08398 5.27614 4.08398 5V4C4.08398 3.72386 4.30784 3.5 4.58398 3.5L9.58398 3.5C9.86013 3.5 10.084 3.72386 10.084 4V5C10.084 5.27614 9.86013 5.5 9.58398 5.5H4.58398Z"
    fill="currentColor" />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M1.58398 11.5C0.755557 11.5 0.0839844 10.8284 0.0839844 10L0.0839844 9C0.0839844 8.17157 0.755558 7.5 1.58398 7.5L9.58398 7.5C10.4124 7.5 11.084 8.17157 11.084 9V10C11.084 10.8284 10.4124 11.5 9.58398 11.5L1.58398 11.5ZM1.08398 10C1.08398 10.2761 1.30784 10.5 1.58398 10.5L9.58398 10.5C9.86013 10.5 10.084 10.2761 10.084 10V9C10.084 8.72386 9.86013 8.5 9.58398 8.5L1.58398 8.5C1.30784 8.5 1.08398 8.72386 1.08398 9L1.08398 10Z"
    fill="currentColor" />
</template>
