<template>
  <path
    fill="currentColor"
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M1.25 5.625C1.25 4.58947 2.08947 3.75 3.125 3.75H16.875C17.9105 3.75 18.75 4.58947 18.75 5.625V12.5C18.75 12.8452 18.4702 13.125 18.125 13.125C17.7798 13.125 17.5 12.8452 17.5 12.5V5.63478L13.7143 8.1586L15.9704 10.1572C16.2288 10.386 16.2527 10.781 16.0238 11.0394C15.7949 11.2978 15.3999 11.3217 15.1416 11.0928L12.6386 8.87569L10.8655 10.0578C10.2357 10.4777 9.41519 10.4777 8.78538 10.0578L7.00981 8.87407L4.81694 11.0669C4.57286 11.311 4.17714 11.311 3.93306 11.0669C3.68898 10.8229 3.68898 10.4271 3.93306 10.1831L5.94915 8.16696L2.5 5.86753V14.375C2.5 14.7202 2.77982 15 3.125 15H10.625C10.9702 15 11.25 15.2798 11.25 15.625C11.25 15.9702 10.9702 16.25 10.625 16.25H3.125C2.08947 16.25 1.25 15.4105 1.25 14.375V5.625ZM3.45217 5L9.47875 9.01772C9.68869 9.15768 9.96219 9.15768 10.1721 9.01772L16.1987 5H3.45217Z" />
  <path
    fill="currentColor"
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M17.5 15C17.5 16.3807 16.3807 17.5 15 17.5C13.6193 17.5 12.5 16.3807 12.5 15C12.5 13.6193 13.6193 12.5 15 12.5C16.3807 12.5 17.5 13.6193 17.5 15ZM15 15.5C15.2761 15.5 15.5 15.2761 15.5 15C15.5 14.7239 15.2761 14.5 15 14.5C14.7239 14.5 14.5 14.7239 14.5 15C14.5 15.2761 14.7239 15.5 15 15.5Z" />
</template>
