<template>
  <path
    fill="currentColor"
    d="M5.62527 11.25C5.2801 11.25 5.00027 11.5298 5.00027 11.875C5.00027 12.2202 5.2801 12.5 5.62527 12.5H10.6253C10.9705 12.5 11.2503 12.2202 11.2503 11.875C11.2503 11.5298 10.9705 11.25 10.6253 11.25H5.62527Z" />
  <path
    fill="currentColor"
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M2.50027 6.25C2.50027 4.86929 3.61956 3.75 5.00027 3.75C6.38098 3.75 7.50027 4.86929 7.50027 6.25C7.50027 7.62998 6.38218 8.74881 5.00248 8.75H10.6234C9.58871 8.74897 8.75027 7.9099 8.75027 6.875C8.75027 5.83947 9.58974 5 10.6253 5C11.6608 5 12.5003 5.83947 12.5003 6.875C12.5003 7.9099 11.6618 8.74897 10.6272 8.75H13.1252C14.1608 8.75 15.0002 9.58947 15.0002 10.625V11.493L17.3206 10.7079C17.4641 10.648 17.6178 10.6212 17.7686 10.6254C17.9328 10.63 18.0993 10.6714 18.2502 10.7531C18.4012 10.8348 18.5402 10.9593 18.6345 11.1266C18.7206 11.2792 18.7623 11.456 18.7473 11.6369V14.6131C18.7623 14.794 18.7206 14.9708 18.6345 15.1234C18.5402 15.2907 18.4012 15.4152 18.2502 15.4969C18.0993 15.5786 17.9328 15.62 17.7686 15.6246C17.6178 15.6288 17.4641 15.602 17.3206 15.5421L15.0002 14.757V15.625C15.0002 16.6605 14.1608 17.5 13.1252 17.5H3.12524C2.08971 17.5 1.25024 16.6605 1.25024 15.625V10.625C1.25024 9.58947 2.08971 8.75 3.12524 8.75H4.99807C3.61837 8.74881 2.50027 7.62998 2.50027 6.25ZM5.00027 5C4.30992 5 3.75027 5.55964 3.75027 6.25C3.75027 6.94036 4.30992 7.5 5.00027 7.5C5.69063 7.5 6.25027 6.94036 6.25027 6.25C6.25027 5.55964 5.69063 5 5.00027 5ZM13.7502 12.3775C13.7501 12.3686 13.7501 12.3597 13.7502 12.3508V10.625C13.7502 10.2798 13.4704 10 13.1252 10H3.12524C2.78007 10 2.50024 10.2798 2.50024 10.625V15.625C2.50024 15.9702 2.78007 16.25 3.12524 16.25H13.1252C13.4704 16.25 13.7502 15.9702 13.7502 15.625V13.8992C13.7501 13.8903 13.7501 13.8814 13.7502 13.8725V12.3775ZM15.0002 12.8126V13.4374L17.4973 14.2823V11.9677L15.0002 12.8126ZM10.0003 6.875C10.0003 6.52982 10.2801 6.25 10.6253 6.25C10.9705 6.25 11.2503 6.52982 11.2503 6.875C11.2503 7.22018 10.9705 7.5 10.6253 7.5C10.2801 7.5 10.0003 7.22018 10.0003 6.875Z" />
</template>
