<template>
  <path
    fill="currentColor"
    d="M2.95956 2.96754C3.25065 2.66525 3.64032 2.5 4.04142 2.5H15.9581C16.3592 2.5 16.7489 2.66525 17.04 2.96754C17.3318 3.27063 17.4998 3.68657 17.4998 4.125V10.625C17.4998 10.9702 17.7796 11.25 18.1248 11.25C18.4699 11.25 18.7498 10.9702 18.7498 10.625V4.125C18.7498 3.36995 18.4611 2.6413 17.9404 2.10048C17.4188 1.55886 16.7063 1.25 15.9581 1.25H4.04142C3.29325 1.25 2.58072 1.55886 2.05916 2.10048C1.53837 2.64131 1.24976 3.36995 1.24976 4.125V10.875C1.24976 11.63 1.53837 12.3587 2.05916 12.8995C2.36431 13.2164 2.79791 13.4217 3.1853 13.548C3.58299 13.6776 4.01324 13.75 4.37476 13.75C4.71993 13.75 4.99976 13.4702 4.99976 13.125C4.99976 12.7798 4.71993 12.5 4.37476 12.5C4.16164 12.5 3.86235 12.4539 3.57258 12.3595C3.27251 12.2617 3.06074 12.1375 2.95956 12.0325C2.66769 11.7294 2.49976 11.3134 2.49976 10.875V4.125C2.49976 3.68657 2.66769 3.27063 2.95956 2.96754Z" />
  <path
    fill="currentColor"
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M10.3531 5.12796C10.5619 5.04283 10.7854 4.9994 11.0108 5.00001C11.4653 5.00028 11.9021 5.1793 12.2251 5.4995C12.5486 5.82013 12.7313 6.25615 12.7313 6.71196V10.5575L15.2919 11.1219C15.9593 11.2689 16.5512 11.6522 16.9559 12.2016C17.3608 12.7511 17.5504 13.4289 17.4881 14.1081L17.1146 18.1821C17.0851 18.5039 16.8152 18.7501 16.492 18.75L9.91311 18.7478C9.71741 18.7478 9.53304 18.656 9.41494 18.5L6.60063 14.7811C6.38337 14.494 6.26072 14.1464 6.25046 13.7862C6.24019 13.4261 6.34281 13.0722 6.54348 12.7731L7.06246 13.1214L6.54413 12.7722C6.67634 12.5759 6.84723 12.4088 7.04614 12.2808C7.24503 12.1528 7.46786 12.0665 7.70099 12.0269C7.93411 11.9874 8.17291 11.9953 8.40291 12.0502C8.63291 12.1052 8.84952 12.2061 9.03946 12.347L9.04045 12.3478L9.28466 12.5298V6.71197C9.28466 6.4859 9.32968 6.2622 9.417 6.05372C9.50431 5.84525 9.63212 5.65628 9.79284 5.49749C9.95354 5.33871 10.144 5.21322 10.3531 5.12796ZM10.825 6.28545C10.8828 6.26189 10.9449 6.24979 11.0077 6.25L11.0098 5.62501V6.25001C11.1366 6.25001 11.2571 6.29999 11.3451 6.38723C11.4329 6.4743 11.4813 6.59121 11.4813 6.71196V11.0598C11.4813 11.3531 11.6853 11.607 11.9718 11.6701L15.0229 12.3426C15.3963 12.4248 15.7255 12.6388 15.9496 12.943C16.1735 13.247 16.2776 13.6206 16.2434 13.9941L15.9219 17.4998L10.2242 17.4979L7.59744 14.0269C7.53659 13.9464 7.50278 13.8499 7.49995 13.7506C7.49713 13.6516 7.52525 13.5536 7.58114 13.4701C7.6181 13.4154 7.66614 13.3683 7.7226 13.332C7.77925 13.2955 7.84309 13.2707 7.91025 13.2593C7.97742 13.2479 8.04625 13.2502 8.11244 13.266C8.17848 13.2818 8.24029 13.3106 8.29416 13.3505L9.53619 14.2761C9.72574 14.4174 9.97876 14.4396 10.19 14.3336C10.4013 14.2276 10.5347 14.0114 10.5347 13.775V6.71196C10.5347 6.65193 10.5466 6.59235 10.57 6.53661C10.5933 6.48085 10.6277 6.42985 10.6714 6.38668C10.7151 6.3435 10.7673 6.30901 10.825 6.28545Z" />
</template>
