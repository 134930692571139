<template>
  <path
    d="M1 14.25C1 13.9739 1.22386 13.75 1.5 13.75L14.5 13.75C14.7761 13.75 15 13.9739 15 14.25C15 14.5261 14.7761 14.75 14.5 14.75L1.5 14.75C1.22386 14.75 1 14.5261 1 14.25Z"
    fill="currentColor" />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M5 13.25C4.17157 13.25 3.5 12.5784 3.5 11.75L3.5 6.75C3.5 5.92157 4.17157 5.25 5 5.25L6 5.25C6.82843 5.25 7.5 5.92157 7.5 6.75L7.5 11.75C7.5 12.5784 6.82843 13.25 6 13.25L5 13.25ZM4.5 11.75C4.5 12.0261 4.72386 12.25 5 12.25L6 12.25C6.27614 12.25 6.5 12.0261 6.5 11.75L6.5 6.75C6.5 6.47386 6.27614 6.25 6 6.25L5 6.25C4.72386 6.25 4.5 6.47386 4.5 6.75L4.5 11.75Z"
    fill="currentColor" />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M8.5 11.75C8.5 12.5784 9.17157 13.25 10 13.25L11 13.25C11.8284 13.25 12.5 12.5784 12.5 11.75L12.5 3.75C12.5 2.92157 11.8284 2.25 11 2.25L10 2.25C9.17157 2.25 8.5 2.92157 8.5 3.75L8.5 11.75ZM10 12.25C9.72386 12.25 9.5 12.0261 9.5 11.75L9.5 3.75C9.5 3.47386 9.72386 3.25 10 3.25L11 3.25C11.2761 3.25 11.5 3.47386 11.5 3.75L11.5 11.75C11.5 12.0261 11.2761 12.25 11 12.25L10 12.25Z"
    fill="currentColor" />
</template>
