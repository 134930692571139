<script lang="ts" setup>
import { computed } from 'vue';

const props = withDefaults(
  defineProps<{
    level?: number;
  }>(),
  {
    level: 1,
  },
);

const titleLevel = computed(() => {
  switch (props.level) {
    case 1:
      return 'h1';
    case 2:
      return 'h2';
    case 3:
      return 'h3';
    case 4:
      return 'h4';
    case 5:
      return 'h5';
    case 6:
      return 'h6';
    default:
      return 'h1';
  }
});
</script>

<template>
  <component
    :is="titleLevel"
    class="font-bold"
    :class="{
      'text-32': level === 1,
      'text-24': level === 2,
      'text-20': level === 3,
      'text-18': level === 4,
      'text-16': level === 5,
    }">
    <slot></slot>
  </component>
</template>
