<template>
  <path
    fill="currentColor"
    d="M1.875 1.25C1.52982 1.25 1.25 1.52982 1.25 1.875V7.5C1.25 7.84518 1.52982 8.125 1.875 8.125C2.22018 8.125 2.5 7.84518 2.5 7.5V2.5H7.5C7.84518 2.5 8.125 2.22018 8.125 1.875C8.125 1.52982 7.84518 1.25 7.5 1.25H1.875Z" />
  <path
    fill="currentColor"
    d="M18.1211 18.7476C18.4663 18.7476 18.7461 18.4677 18.7461 18.1226V12.4976C18.7461 12.1524 18.4663 11.8726 18.1211 11.8726C17.7759 11.8726 17.4961 12.1524 17.4961 12.4976V17.4976H12.4961C12.1509 17.4976 11.8711 17.7774 11.8711 18.1226C11.8711 18.4677 12.1509 18.7476 12.4961 18.7476H18.1211Z" />
  <path
    fill="currentColor"
    d="M18.1211 1.25C18.4663 1.25 18.7461 1.52982 18.7461 1.875V7.5C18.7461 7.84518 18.4663 8.125 18.1211 8.125C17.7759 8.125 17.4961 7.84518 17.4961 7.5V2.5L12.4961 2.5C12.1509 2.5 11.8711 2.22018 11.8711 1.875C11.8711 1.52982 12.1509 1.25 12.4961 1.25H18.1211Z" />
  <path
    fill="currentColor"
    d="M1.25 18.1226C1.25 18.4677 1.52982 18.7476 1.875 18.7476H7.5C7.84518 18.7476 8.125 18.4677 8.125 18.1226C8.125 17.7774 7.84518 17.4976 7.5 17.4976H2.5L2.5 12.4976C2.5 12.1524 2.22018 11.8726 1.875 11.8726C1.52982 11.8726 1.25 12.1524 1.25 12.4976V18.1226Z" />
</template>
