<template>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M13.125 16.25C7.94733 16.25 3.75 12.0527 3.75 6.875L3.75 3.125C3.75 2.77982 3.47018 2.5 3.125 2.5C2.77982 2.5 2.5 2.77982 2.5 3.125L2.5 6.875C2.5 12.743 7.25698 17.5 13.125 17.5L16.875 17.5C17.2202 17.5 17.5 17.2202 17.5 16.875C17.5 16.5298 17.2202 16.25 16.875 16.25L13.125 16.25Z"
    fill="#AAAAAA"
    stroke="#AAAAAA"
    stroke-width="0.25"
    stroke-linecap="round" />
</template>
