<template>
  <path
    d="M15.5 2.25C15.5 2.52614 15.2761 2.75 15 2.75L2 2.75C1.72386 2.75 1.5 2.52614 1.5 2.25C1.5 1.97386 1.72386 1.75 2 1.75L15 1.75C15.2761 1.75 15.5 1.97386 15.5 2.25Z"
    fill="currentColor"></path>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M11.5 3.25C12.3284 3.25 13 3.92157 13 4.75L13 9.75C13 10.5784 12.3284 11.25 11.5 11.25L10.5 11.25C9.67157 11.25 9 10.5784 9 9.75L9 4.75C9 3.92157 9.67157 3.25 10.5 3.25L11.5 3.25ZM12 4.75C12 4.47386 11.7761 4.25 11.5 4.25L10.5 4.25C10.2239 4.25 10 4.47386 10 4.75L10 9.75C10 10.0261 10.2239 10.25 10.5 10.25L11.5 10.25C11.7761 10.25 12 10.0261 12 9.75L12 4.75Z"
    fill="currentColor"></path>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M8 4.75C8 3.92157 7.32843 3.25 6.5 3.25L5.5 3.25C4.67157 3.25 4 3.92157 4 4.75L4 12.75C4 13.5784 4.67157 14.25 5.5 14.25L6.5 14.25C7.32843 14.25 8 13.5784 8 12.75L8 4.75ZM6.5 4.25C6.77614 4.25 7 4.47386 7 4.75L7 12.75C7 13.0261 6.77614 13.25 6.5 13.25L5.5 13.25C5.22386 13.25 5 13.0261 5 12.75L5 4.75C5 4.47386 5.22386 4.25 5.5 4.25L6.5 4.25Z"
    fill="currentColor"></path>
</template>
