<template>
  <path
    fill="currentColor"
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M3.125 3.75C2.08947 3.75 1.25 4.58947 1.25 5.625V13.125C1.25 14.1605 2.08946 15 3.125 15H11.4583C11.8035 15 12.0833 14.7202 12.0833 14.375C12.0833 14.0298 11.8035 13.75 11.4583 13.75H3.125C2.77982 13.75 2.5 13.4702 2.5 13.125V7.5L15.625 7.5V10.625C15.625 10.9702 15.9048 11.25 16.25 11.25C16.5952 11.25 16.875 10.9702 16.875 10.625V5.625C16.875 4.58947 16.0355 3.75 15 3.75H3.125ZM15.625 6.25V5.625C15.625 5.27982 15.3452 5 15 5H3.125C2.77982 5 2.5 5.27982 2.5 5.625V6.25L15.625 6.25Z" />
  <path
    fill="currentColor"
    d="M4.375 8.75C4.02982 8.75 3.75 9.02982 3.75 9.375C3.75 9.72018 4.02982 10 4.375 10H8.125C8.47018 10 8.75 9.72018 8.75 9.375C8.75 9.02982 8.47018 8.75 8.125 8.75H4.375Z" />
  <path
    fill="currentColor"
    d="M11.25 9.375C11.25 9.02982 11.5298 8.75 11.875 8.75H13.125C13.4702 8.75 13.75 9.02982 13.75 9.375C13.75 9.72018 13.4702 10 13.125 10H11.875C11.5298 10 11.25 9.72018 11.25 9.375Z" />
  <path
    fill="currentColor"
    d="M18.5669 11.4331C18.811 11.6771 18.811 12.0729 18.5669 12.3169L17.1339 13.75L18.5669 15.1831C18.811 15.4271 18.811 15.8229 18.5669 16.0669C18.3229 16.311 17.9271 16.311 17.6831 16.0669L16.25 14.6339L14.8169 16.0669C14.5729 16.311 14.1771 16.311 13.9331 16.0669C13.689 15.8229 13.689 15.4271 13.9331 15.1831L15.3661 13.75L13.9331 12.3169C13.689 12.0729 13.689 11.6771 13.9331 11.4331C14.1771 11.189 14.5729 11.189 14.8169 11.4331L16.25 12.8661L17.6831 11.4331C17.9271 11.189 18.3229 11.189 18.5669 11.4331Z" />
</template>
