<template>
  <path
    fill="currentColor"
    d="M10 8.125C10.5178 8.125 10.9375 7.70527 10.9375 7.1875C10.9375 6.66973 10.5178 6.25 10 6.25C9.48223 6.25 9.0625 6.66973 9.0625 7.1875C9.0625 7.70527 9.48223 8.125 10 8.125Z" />
  <path
    fill="currentColor"
    d="M10.9375 10C10.9375 10.5178 10.5178 10.9375 10 10.9375C9.48223 10.9375 9.0625 10.5178 9.0625 10C9.0625 9.48223 9.48223 9.0625 10 9.0625C10.5178 9.0625 10.9375 9.48223 10.9375 10Z" />
  <path
    fill="currentColor"
    d="M10 13.75C10.5178 13.75 10.9375 13.3303 10.9375 12.8125C10.9375 12.2947 10.5178 11.875 10 11.875C9.48223 11.875 9.0625 12.2947 9.0625 12.8125C9.0625 13.3303 9.48223 13.75 10 13.75Z" />
  <path
    fill="currentColor"
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M3.125 3.75C2.08947 3.75 1.25 4.58947 1.25 5.625V7C1.25 7.64506 1.76203 8.06399 2.24828 8.16203C3.10504 8.33478 3.75 9.09277 3.75 10C3.75 10.9072 3.10504 11.6652 2.24828 11.838C1.76203 11.936 1.25 12.3549 1.25 13V14.375C1.25 15.4105 2.08947 16.25 3.125 16.25H16.875C17.9105 16.25 18.75 15.4105 18.75 14.375V13C18.75 12.3549 18.238 11.936 17.7517 11.838C16.895 11.6652 16.25 10.9072 16.25 10C16.25 9.09277 16.895 8.33478 17.7517 8.16203C18.238 8.06399 18.75 7.64506 18.75 7V5.625C18.75 4.58947 17.9105 3.75 16.875 3.75H3.125ZM2.5 5.625C2.5 5.27982 2.77982 5 3.125 5H16.875C17.2202 5 17.5 5.27982 17.5 5.625V6.93764C16.0737 7.22749 15 8.48767 15 10C15 11.5123 16.0737 12.7725 17.5 13.0624V14.375C17.5 14.7202 17.2202 15 16.875 15H3.125C2.77982 15 2.5 14.7202 2.5 14.375V13.0624C3.92634 12.7725 5 11.5123 5 10C5 8.48767 3.92634 7.22749 2.5 6.93764V5.625Z" />
</template>
