<template>
  <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.49995 2.99936L14.4999 2.99805C14.7761 2.99802 15 3.22185 15 3.49799C15 3.77413 14.7762 3.99802 14.5001 3.99805L2.50005 3.99936C2.22391 3.99939 2.00003 3.77556 2 3.49942C1.99997 3.22327 2.2238 2.99939 2.49995 2.99936Z"
      fill="currentColor" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.49995 6.00077L9.55005 6C9.8262 5.99997 10.0501 6.2238 10.0501 6.49994C10.0501 6.77609 9.82631 6.99997 9.55016 7L2.50005 7.00077C2.22391 7.0008 2.00003 6.77697 2 6.50083C1.99997 6.22468 2.2238 6.0008 2.49995 6.00077Z"
      fill="currentColor" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.49995 12.0027L9.55005 12.002C9.8262 12.0019 10.0501 12.2258 10.0501 12.5019C10.0501 12.778 9.82631 13.0019 9.55016 13.002L2.50005 13.0027C2.22391 13.0028 2.00003 12.7789 2 12.5028C1.99997 12.2266 2.2238 12.0028 2.49995 12.0027Z"
      fill="currentColor" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.49995 9.00109L12.4457 9C12.7219 8.99997 12.9458 9.2238 12.9458 9.49994C12.9458 9.77609 12.722 9.99997 12.4459 10L2.50005 10.0011C2.22391 10.0011 2.00003 9.77729 2 9.50114C1.99997 9.225 2.2238 9.00112 2.49995 9.00109Z"
      fill="currentColor" />
  </svg>
</template>
