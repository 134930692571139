<template>
  <path
    fill="currentColor"
    d="M12.2488 10.557L15.3707 15.9243C15.5442 16.2227 15.9268 16.3239 16.2252 16.1503C16.5235 15.9768 16.6247 15.5942 16.4512 15.2958L13.228 9.75423C12.8082 9.03249 11.7761 9.00343 11.3164 9.7004L9.25243 12.8294L8.0577 11.7756C7.54846 11.3264 6.76036 11.4273 6.38074 11.9903L4.17564 15.2606C3.98267 15.5468 4.05824 15.9353 4.34444 16.1283C4.63063 16.3212 5.01908 16.2457 5.21205 15.9595L7.33757 12.8071L8.96479 14.2425C9.10049 14.3622 9.2814 14.4172 9.46076 14.3933C9.64012 14.3694 9.80032 14.2689 9.89995 14.1179L12.2488 10.557Z" />
  <path
    fill="currentColor"
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M6.2561 4.375C5.04798 4.375 4.0686 5.35438 4.0686 6.5625C4.0686 7.77062 5.04798 8.75 6.2561 8.75C7.46423 8.75 8.4436 7.77062 8.4436 6.5625C8.4436 5.35438 7.46423 4.375 6.2561 4.375ZM5.3186 6.5625C5.3186 6.04473 5.73834 5.625 6.2561 5.625C6.77387 5.625 7.1936 6.04473 7.1936 6.5625C7.1936 7.08027 6.77387 7.5 6.2561 7.5C5.73834 7.5 5.3186 7.08027 5.3186 6.5625Z" />
  <path
    fill="currentColor"
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M1.25 3.125C1.25 2.08947 2.08947 1.25 3.125 1.25H16.875C17.9105 1.25 18.75 2.08947 18.75 3.125V16.875C18.75 17.9105 17.9105 18.75 16.875 18.75H3.125C2.08947 18.75 1.25 17.9105 1.25 16.875V3.125ZM3.125 2.5C2.77982 2.5 2.5 2.77982 2.5 3.125V16.875C2.5 17.2202 2.77982 17.5 3.125 17.5H16.875C17.2202 17.5 17.5 17.2202 17.5 16.875V3.125C17.5 2.77982 17.2202 2.5 16.875 2.5H3.125Z" />
</template>
