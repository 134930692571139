<template>
  <div class="gemx-control" :class="classContainer">
    <div class="gemx-checkbox-group">
      <div class="flex items-center">
        <input
          v-model="val"
          class="rounded-medium mr-[6.5px] h-[19px] w-[19px] bg-blue-300"
          :class="inputClasses"
          type="checkbox"
          :value="value"
          @change.stop="change" />
        <label class="text-light-high text-14 cursor-pointer select-none" :for="value" @click.stop="val = !val">
          <span v-html="text"></span>
        </label>
      </div>
      <p class="gemx-checkbox-desc">{{ desc }}</p>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, watch } from 'vue';

const props = defineProps<{
  value?: any;
  text?: string;
  desc?: string;
  classContainer?: string;
  inputClasses?: string;
}>();

const val = ref(props.value);

const emit = defineEmits<{
  (e: 'onChange', controlId?: string | number, value?: any): void;
  (e: 'onFocus', controlId?: string | number, value?: any): void;
  (e: 'onBlur', controlId?: string | number, value?: any): void;
}>();

const setValue = (value: any) => {
  if (value != val.value) {
    val.value = value;
  }
};

watch(val, (value) => {
  setValue(value);
  change();
});

watch(
  () => props.value,
  (value) => {
    val.value = value;
  },
);

const change = () => {
  emit('onChange', val.value);
};
</script>
