<script lang="ts" setup>
import GInput from '../../base/GInput.vue';
import GButton from '../../base/GButton.vue';
import GBaseIcon from '../../base/GBaseIcon.vue';
import type { DomainItem, StepAddDomain } from '../../types/domain';
import removeWWWOrHttps from './utils/removeWWWOrHttps';
import { computed, nextTick, ref } from 'vue';
import DnsInfo from './DNSInfo.vue';
import { validateDomainNameFormat } from './utils/validateDomainFormat';

type Props = {
  domain: string;
  domains: DomainItem[];
  defaultDns: string;
  ipAddress: string;
  isAdding?: boolean;
};

const props = defineProps<Props>();
const emit = defineEmits<{
  (e: 'add-domain'): void;
  (e: 'on-change-domain-name-success', domain: string): void;
  (e: 'on-change-domain-name-fail', domain: string): void;
  (e: 'need-scroll-bottom'): void;
}>();
const steps: StepAddDomain[] = ['input_domain', 'config_domain'];
const isStartAddDomain = ref<boolean>(false);
const currentStep = ref<StepAddDomain>('input_domain');
const errorFormatDomainMessage = ref<string>('');
const MAX_DOMAIN = 10;

const currentStepIndex = computed<number>(() => steps.findIndex((item) => item === currentStep.value) || 0);

const isDisableInputDomain = computed<boolean>(() => {
  const stepInputDomainIndex = steps.findIndex((item) => item === 'input_domain');
  return currentStepIndex.value > stepInputDomainIndex;
});

const startAddDomain = () => {
  if (props.domains && props.domains?.length < MAX_DOMAIN) {
    isStartAddDomain.value = true;
    nextTick(() => {
      emit('need-scroll-bottom');
    });
  }
};

const closeStepAddDomain = () => {
  resetStateAddDomain();
  emit('on-change-domain-name-success', '');
};

const resetStateAddDomain = () => {
  isStartAddDomain.value = false;
  errorFormatDomainMessage.value = '';
  currentStep.value = 'input_domain';
};

const isShowStep = (step: StepAddDomain) => {
  const stepIndex = steps.findIndex((item) => item === step);
  return stepIndex <= currentStepIndex.value;
};

const onChange = (value: string) => {
  const formatValue = removeWWWOrHttps(value);
  const { isValid, message } = validateDomainNameFormat(formatValue);
  errorFormatDomainMessage.value = message;
  if (isValid) {
    emit('on-change-domain-name-success', formatValue);
  } else {
    emit('on-change-domain-name-fail', formatValue);
  }
};

const nextToStepSubmitDomain = () => {
  if (!props.domain) {
    return;
  }
  currentStep.value = 'config_domain';
  nextTick(() => {
    emit('need-scroll-bottom');
  });
};

const addDomain = () => {
  props.domain && emit('add-domain');
};

defineExpose({
  closeStepAddDomain,
});
</script>
<template>
  <g-button
    v-if="!isStartAddDomain && domains?.length < MAX_DOMAIN"
    type="grey"
    class="rounded-medium w-full items-center justify-center"
    @click="startAddDomain">
    <g-base-icon name="plus" width="20px" height="20px" class="mr-8"></g-base-icon>
    Add New Domain
  </g-button>
  <div v-if="isStartAddDomain && domains?.length < MAX_DOMAIN" class="rounded-medium bg-light-300 flex flex-col p-16">
    <template v-if="isShowStep('input_domain')">
      <div class="flex flex-row items-start justify-between">
        <span class="text-14 text-light-high font-medium">Add new domain</span>
        <span class="text-primary-325 cursor-pointer font-medium" @click="closeStepAddDomain">Cancel</span>
      </div>
      <div class="pt-16">
        <div class="pb-16">
          <span class="text-12">Step 1: Let input your domain here to get the value of DNS record</span>
        </div>
        <div class="flex w-full flex-row items-start gap-8">
          <g-input
            placeholder="e.g. example.com"
            input-style="primary"
            type="text"
            classes="border-white !bg-white"
            :auto-focus="true"
            :value="domain"
            :error="errorFormatDomainMessage"
            :disable="isDisableInputDomain"
            @on-change="onChange">
          </g-input>
          <g-button
            type="invert"
            size="large"
            :disable="!domain || isDisableInputDomain || !!errorFormatDomainMessage"
            @click="nextToStepSubmitDomain"
            >Get DNS Value</g-button
          >
        </div>
        <div v-if="!isShowStep('config_domain') && !errorFormatDomainMessage" class="text-12 flex items-center pt-10">
          <template v-if="domain === ''">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" class="mr-8">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M15 8C15 11.866 11.866 15 8 15C4.13401 15 1 11.866 1 8C1 4.13401 4.13401 1 8 1C11.866 1 15 4.13401 15 8ZM8.4668 4.61597C8.4668 4.89211 8.24294 5.11597 7.9668 5.11597C7.69065 5.11597 7.4668 4.89211 7.4668 4.61597C7.4668 4.33982 7.69065 4.11597 7.9668 4.11597C8.24294 4.11597 8.4668 4.33982 8.4668 4.61597ZM6.91309 5.48633C6.63694 5.48633 6.41309 5.71019 6.41309 5.98633C6.41309 6.26247 6.63694 6.48633 6.91309 6.48633H7.56006V10.3839C7.56006 10.9362 8.00777 11.3839 8.56006 11.3839H9.57144C9.84758 11.3839 10.0714 11.1601 10.0714 10.8839C10.0714 10.6078 9.84758 10.3839 9.57144 10.3839H8.56006V6.48633C8.56006 5.93404 8.11234 5.48633 7.56006 5.48633H6.91309Z"
                fill="#2856F8" />
            </svg>
            The name should not contain <span class="mx-4 font-medium text-gray-600">“https”</span> or
            <span class="mx-4 font-medium text-gray-600">“www”</span>
          </template>
          <template v-else>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" class="mr-8">
              <path
                d="M8 1C4.1 1 1 4.1 1 8C1 11.9 4.1 15 8 15C11.9 15 15 11.9 15 8C15 4.1 11.9 1 8 1ZM6.4 11.2L3.8 8.6L4.5 7.9L6.4 9.8L11.5 4.8L12.2 5.5L6.4 11.2Z"
                fill="#00A042" />
            </svg>
            Correctly name structure
          </template>
        </div>
      </div>
    </template>
    <template v-if="isShowStep('config_domain')">
      <div class="mt-16 flex w-full flex-row items-start justify-between">
        <div class="flex flex-col justify-start">
          <span class="text-12">Step 2: Access your domain provider to add following DNS record to your domain</span>
        </div>
      </div>
      <dns-info :domain="domain" :default-dns="defaultDns" :ip-address="ipAddress"></dns-info>
      <div class="mt-16">
        <span class="text-12"> Step 3: Now you can add your domain to use it for your instant pages </span>
        <div class="mt-16 flex w-full flex-row">
          <g-button
            :loading="isAdding"
            type="primary"
            size="large"
            button-classes="w-full justify-center"
            @click="addDomain">
            <span class="max-w-[400px] truncate">Add {{ domain }}</span>
          </g-button>
        </div>
      </div>
    </template>
  </div>
</template>
