<script lang="ts" setup>
import { ref, watch, onMounted } from 'vue';
import Progress from './Progress.vue';

type Props = {
  percentSyncDone?: number;
  isAutoOpenWhenSuccess?: boolean;
  progressClass?: string;
};

const props = defineProps<Props>();

const isShow = ref<boolean>(false);
const isUserForceClose = ref<boolean>(false);
let timeoutCloseSuccessModal: ReturnType<typeof setTimeout>;

const checkShowProgress = () => {
  if (props.percentSyncDone && props.percentSyncDone < 100) {
    checkUserBehaviorToOpenProgress();
  }
  if (props.percentSyncDone === 100 && props.isAutoOpenWhenSuccess) {
    forceOpenProgress();
    autoCloseProgress();
  }
};

const checkUserBehaviorToOpenProgress = () => {
  if (isUserForceClose.value) {
    return;
  }
  isShow.value = true;
};

const forceOpenProgress = () => {
  isShow.value = true;
};

const autoCloseProgress = () => {
  clearTimeout(timeoutCloseSuccessModal);
  timeoutCloseSuccessModal = setTimeout(() => {
    isShow.value = false;
  }, 3000);
};

const closeProgress = () => {
  isShow.value = false;
  isUserForceClose.value = true;
};

watch(() => props.percentSyncDone, checkShowProgress);

onMounted(() => {
  checkShowProgress();
});
</script>
<template>
  <Progress
    :is-show="isShow"
    :status="percentSyncDone === 100 ? 'success' : 'pending'"
    :progress-class="progressClass"
    @close="closeProgress">
    <template #content>
      <template v-if="percentSyncDone === 100">
        Successfully <span class="font-semibold">imported</span> products & collections from Shopify to Instant Landing
        Pages
      </template>
      <template v-else>
        <span class="font-semibold">Importing</span> products & collections from Shopify to Instant Landing Pages ({{
          percentSyncDone
        }}%)
      </template>
    </template>
  </Progress>
</template>
