<template>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M4 2.5C3.17157 2.5 2.5 3.17157 2.5 4V6C2.5 6.82843 3.17157 7.5 4 7.5H12C12.8284 7.5 13.5 6.82843 13.5 6V4C13.5 3.17157 12.8284 2.5 12 2.5H4ZM3.5 4C3.5 3.72386 3.72386 3.5 4 3.5H12C12.2761 3.5 12.5 3.72386 12.5 4V6C12.5 6.27614 12.2761 6.5 12 6.5H4C3.72386 6.5 3.5 6.27614 3.5 6V4Z"
    fill="#757575" />
  <path
    d="M8 8.50435C8.27614 8.50435 8.5 8.7282 8.5 9.00435L8.5 13.8039L8.60213 13.7023C9.10976 13.1972 9.66736 12.6395 9.88913 12.4083C10.0803 12.209 10.3968 12.2024 10.5961 12.3935C10.7954 12.5846 10.802 12.9012 10.6109 13.1005C10.3791 13.3422 9.81157 13.9095 9.30751 14.4112C9.05429 14.6631 8.81529 14.9003 8.63963 15.0744L8.35884 15.3525C8.27735 15.4365 8.16665 15.492 8.04314 15.5025C7.90199 15.5147 7.75666 15.4672 7.64818 15.3598L7.36002 15.0744C7.18438 14.9003 6.94543 14.6631 6.69224 14.4111C6.18826 13.9095 5.62085 13.3421 5.38909 13.1004C5.19798 12.9011 5.20463 12.5846 5.40396 12.3935C5.60328 12.2024 5.91979 12.209 6.11091 12.4083C6.33262 12.6396 6.89012 13.1972 7.39767 13.7023L7.5 13.8041L7.5 9.00435C7.5 8.7282 7.72386 8.50435 8 8.50435Z"
    fill="#757575" />
</template>
