<template>
  <path
    fill="currentColor"
    d="M4.375 1.25C4.02982 1.25 3.75 1.52982 3.75 1.875C3.75 2.22018 4.02982 2.5 4.375 2.5L9.375 2.5V8.125C9.375 8.47018 9.65482 8.75 10 8.75C10.3452 8.75 10.625 8.47018 10.625 8.125V2.5L15.625 2.5C15.9702 2.5 16.25 2.22018 16.25 1.875C16.25 1.52982 15.9702 1.25 15.625 1.25H4.375Z" />
  <path
    fill="currentColor"
    d="M3.125 10C2.77982 10 2.5 10.2798 2.5 10.625C2.5 10.9702 2.77982 11.25 3.125 11.25L16.875 11.25C17.2202 11.25 17.5 10.9702 17.5 10.625C17.5 10.2798 17.2202 10 16.875 10L3.125 10Z" />
  <path
    fill="currentColor"
    d="M10.625 12.5C10.625 12.1548 10.3452 11.875 10 11.875C9.65482 11.875 9.375 12.1548 9.375 12.5V18.125C9.375 18.4702 9.65482 18.75 10 18.75C10.3452 18.75 10.625 18.4702 10.625 18.125V12.5Z" />
</template>
