<template>
  <path
    fill="currentColor"
    d="M8.75 6.32279C8.40482 6.32279 8.125 6.60261 8.125 6.94779C8.125 7.29297 8.40482 7.57279 8.75 7.57279H11.25C11.5952 7.57279 11.875 7.29297 11.875 6.94779C11.875 6.60261 11.5952 6.32279 11.25 6.32279H8.75Z" />
  <path
    fill="currentColor"
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M3.25806 4.04736C2.22252 4.04736 1.38306 4.88683 1.38306 5.92236V14.0779C1.38306 15.1134 2.22252 15.9529 3.25806 15.9529H16.7414C17.7769 15.9529 18.6164 15.1134 18.6164 14.0779V5.92236C18.6164 4.88683 17.7769 4.04736 16.7414 4.04736H3.25806ZM2.63306 5.92236C2.63306 5.57719 2.91288 5.29736 3.25806 5.29736H16.7414C17.0865 5.29736 17.3664 5.57719 17.3664 5.92236V14.0779C17.3664 14.4231 17.0865 14.7029 16.7414 14.7029H3.25806C2.91288 14.7029 2.63306 14.4231 2.63306 14.0779V5.92236Z" />
</template>
