<template>
  <path
    fill="currentColor"
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M10.4507 15.0133C10.3329 15.136 10.1701 15.2053 10 15.2053C9.82988 15.2053 9.6671 15.136 9.54925 15.0133L4.87444 10.1464C4.37081 9.62213 4.74241 8.74994 5.46943 8.74994H7.5L7.49999 1.875C7.49999 1.70924 7.56584 1.55027 7.68305 1.43306C7.80026 1.31585 7.95923 1.25 8.12499 1.25H11.875C12.2202 1.25 12.5 1.52982 12.5 1.875L12.5 8.74994H14.5306C15.2576 8.74994 15.6292 9.62212 15.1256 10.1464L10.4507 15.0133ZM8.125 9.99994H6.46696L10 13.6781L13.533 9.99994H11.875C11.5298 9.99994 11.25 9.72012 11.25 9.37494L11.25 2.5H8.74999L8.75 9.37494C8.75 9.5407 8.68415 9.69967 8.56694 9.81688C8.44973 9.93409 8.29076 9.99994 8.125 9.99994Z" />
  <path
    fill="currentColor"
    d="M2.50001 14.375C2.50002 14.0298 2.2202 13.75 1.87502 13.75C1.52985 13.75 1.25002 14.0298 1.25001 14.375L1.25 16.8751C1.25 17.9107 2.08952 18.75 3.125 18.75H16.8749C17.9104 18.75 18.7499 17.9106 18.7499 16.8751L18.7499 14.375C18.7499 14.0298 18.4701 13.75 18.1249 13.75C17.7798 13.75 17.4999 14.0298 17.4999 14.375L17.4999 16.8751C17.4999 17.2202 17.2201 17.5 16.8749 17.5H3.125C2.77978 17.5 2.5 17.2202 2.5 16.8751L2.50001 14.375Z" />
</template>
