<template>
  <path
    fill="currentColor"
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M12.7495 1.87476C11.714 1.87476 10.8745 2.71422 10.8745 3.74976V5.24976C10.8745 6.28529 11.714 7.12476 12.7495 7.12476H16.2495C17.285 7.12476 18.1245 6.28529 18.1245 5.24976V3.74976C18.1245 2.71422 17.285 1.87476 16.2495 1.87476H12.7495ZM12.1245 3.74976C12.1245 3.40458 12.4043 3.12476 12.7495 3.12476H16.2495C16.5947 3.12476 16.8745 3.40458 16.8745 3.74976V5.24976C16.8745 5.59493 16.5947 5.87476 16.2495 5.87476H12.7495C12.4043 5.87476 12.1245 5.59493 12.1245 5.24976V3.74976Z" />
  <path
    fill="currentColor"
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M12.7495 8.62549C11.714 8.62549 10.8745 9.46495 10.8745 10.5005V16.2505C10.8745 17.286 11.714 18.1255 12.7495 18.1255H16.2495C17.285 18.1255 18.1245 17.286 18.1245 16.2505V10.5005C18.1245 9.46495 17.285 8.62549 16.2495 8.62549H12.7495ZM12.1245 10.5005C12.1245 10.1553 12.4043 9.87549 12.7495 9.87549H16.2495C16.5947 9.87549 16.8745 10.1553 16.8745 10.5005V16.2505C16.8745 16.5957 16.5947 16.8755 16.2495 16.8755H12.7495C12.4043 16.8755 12.1245 16.5957 12.1245 16.2505V10.5005Z" />
  <path
    fill="currentColor"
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M1.875 3.75024C1.875 2.71471 2.71447 1.87524 3.75 1.87524H7.25C8.28554 1.87524 9.125 2.71471 9.125 3.75024V9.50024C9.125 10.5358 8.28554 11.3752 7.25 11.3752H3.75C2.71447 11.3752 1.875 10.5358 1.875 9.50024V3.75024ZM3.75 3.12524C3.40482 3.12524 3.125 3.40507 3.125 3.75024V9.50024C3.125 9.84542 3.40482 10.1252 3.75 10.1252H7.25C7.59518 10.1252 7.875 9.84542 7.875 9.50024V3.75024C7.875 3.40507 7.59518 3.12524 7.25 3.12524H3.75Z" />
  <path
    fill="currentColor"
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M3.75 12.8755C2.71447 12.8755 1.875 13.715 1.875 14.7505V16.2505C1.875 17.286 2.71447 18.1255 3.75 18.1255H7.25C8.28554 18.1255 9.125 17.286 9.125 16.2505V14.7505C9.125 13.715 8.28554 12.8755 7.25 12.8755H3.75ZM3.125 14.7505C3.125 14.4053 3.40482 14.1255 3.75 14.1255H7.25C7.59518 14.1255 7.875 14.4053 7.875 14.7505V16.2505C7.875 16.5957 7.59518 16.8755 7.25 16.8755H3.75C3.40482 16.8755 3.125 16.5957 3.125 16.2505V14.7505Z" />
</template>
