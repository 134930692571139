<template>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M10.3003 8.98073C9.91899 9.87396 9.0326 10.5 8 10.5C6.61929 10.5 5.5 9.38071 5.5 8C5.5 6.61955 6.61888 5.50037 7.99933 5.5C9.03251 5.49945 9.9196 6.12588 10.3008 7.01985C10.4289 7.32024 10.4998 7.65083 10.5 7.998V8C10.4999 8.34787 10.4288 8.67979 10.3003 8.98073ZM7.9996 6.5C7.17133 6.50022 6.5 7.17173 6.5 8C6.5 8.82843 7.17157 9.5 8 9.5C8.82821 9.5 9.49964 8.82879 9.5 8.00067L9.5 7.9993C9.49956 7.17124 8.82766 6.49956 7.9996 6.5Z" />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M15 8C15 11.866 11.866 15 8 15C4.13401 15 1 11.866 1 8C1 4.13401 4.13401 1 8 1C11.866 1 15 4.13401 15 8ZM13.9069 9.05844C13.407 11.8673 10.9526 14 8 14C5.04064 14 2.58168 11.8575 2.08963 9.039C2.49706 9.59249 3.14036 10.3548 3.99731 11.0227C5.01844 11.8186 6.36924 12.5 7.99906 12.5C11.0087 12.5 13.0323 10.2614 13.9069 9.05844ZM13.9089 6.95287C13.5048 6.4009 12.8689 5.64498 12.0199 4.98152C10.9982 4.1831 9.64275 3.49996 7.99905 3.5C6.35901 3.50004 5.0062 4.18018 3.98558 4.97631C3.13386 5.64069 2.49624 6.39868 2.09122 6.95199C2.58693 4.13793 5.04379 2 8 2C10.9565 2 13.4136 4.13838 13.9089 6.95287ZM4.60063 5.7648C3.6935 6.4724 3.04873 7.30987 2.70727 7.8133C2.62954 7.92792 2.62974 8.06858 2.70827 8.18333C3.05287 8.68685 3.70285 9.52538 4.61204 10.234C5.51994 10.9416 6.65994 11.5 7.99906 11.5C10.749 11.5 12.6021 9.22381 13.2982 8.18386C13.3738 8.07085 13.3736 7.93325 13.2969 7.82005C12.9573 7.31857 12.3125 6.47931 11.4042 5.76947C10.4974 5.06091 9.35303 4.49996 7.99908 4.5C6.64813 4.50004 5.50609 5.05849 4.60063 5.7648Z" />
</template>
