<template>
  <path
    d="M1.26111 8C1.26111 7.30964 1.82075 6.75 2.51111 6.75C3.20146 6.75 3.76111 7.30964 3.76111 8C3.76111 8.69036 3.20146 9.25 2.51111 9.25C1.82075 9.25 1.26111 8.69036 1.26111 8Z"
    fill="currentColor" />
  <path
    d="M5.09436 8C5.09436 7.30964 5.654 6.75 6.34436 6.75C7.03472 6.75 7.59436 7.30964 7.59436 8C7.59436 8.69036 7.03472 9.25 6.34436 9.25C5.654 9.25 5.09436 8.69036 5.09436 8Z"
    fill="currentColor" />
  <path
    d="M8.92761 8C8.92761 7.30964 9.48726 6.75 10.1776 6.75C10.868 6.75 11.4276 7.30964 11.4276 8C11.4276 8.69036 10.868 9.25 10.1776 9.25C9.48726 9.25 8.92761 8.69036 8.92761 8Z"
    fill="currentColor" />
  <path
    d="M12.7611 8C12.7611 7.30964 13.3208 6.75 14.0111 6.75C14.7015 6.75 15.2611 7.30964 15.2611 8C15.2611 8.69036 14.7015 9.25 14.0111 9.25C13.3208 9.25 12.7611 8.69036 12.7611 8Z"
    fill="currentColor" />
</template>
