<template>
  <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14 16H2C1.2 16 0.5 15.3 0.5 14.5V1.5C0.5 0.7 1.2 0 2 0H12C12.4 0 12.8 0.2 13 0.4L15 2.3C15.3 2.6 15.5 3 15.5 3.4V14.5C15.5 15.3 14.8 16 14 16ZM2 1C1.7 1 1.5 1.2 1.5 1.5V14.5C1.5 14.8 1.7 15 2 15H14C14.3 15 14.5 14.8 14.5 14.5V3.4C14.5 3.3 14.4 3.1 14.3 3L12.3 1.1C12.3 1.1 12.1 1 12 1H2Z"
      fill="#9E9E9E" />
    <path
      d="M5.5 7C5 7 4.5 6.8 4.1 6.4C3.3 5.6 3.3 4.4 4.1 3.6C4.9 2.8 6.1 2.8 6.9 3.6C7.7 4.4 7.7 5.6 6.9 6.4C6.5 6.8 6 7 5.5 7ZM5.5 4C5.3 4 5 4.1 4.8 4.3C4.4 4.7 4.4 5.3 4.8 5.7C5.2 6.1 5.8 6.1 6.2 5.7C6.6 5.3 6.6 4.7 6.2 4.3C6 4.1 5.8 4 5.5 4Z"
      fill="#9E9E9E" />
    <path
      d="M12.4998 12.5999C12.2998 12.5999 12.1998 12.4999 12.0998 12.3999L9.49984 8.4999C9.49984 8.3999 9.29984 8.3999 9.29984 8.4999L7.39984 10.9999C7.29984 11.0999 7.19984 11.1999 7.09984 11.1999C6.99984 11.1999 6.79984 11.1999 6.69984 11.0999L5.59984 10.1999C5.49984 10.0999 5.39984 10.1999 5.39984 10.1999L3.99984 12.2999C3.79984 12.4999 3.49984 12.5999 3.29984 12.3999C3.09984 12.1999 2.99984 11.8999 3.19984 11.6999L4.59984 9.5999L4.69984 9.4999C4.99984 9.1999 5.69984 9.0999 6.19984 9.4999L6.89984 9.9999L8.39984 7.8999C8.49984 7.7999 8.59984 7.6999 8.69984 7.5999C8.99984 7.3999 9.29984 7.3999 9.59984 7.3999C9.89984 7.4999 10.1998 7.5999 10.2998 7.8999L12.8998 11.7999C13.0998 11.9999 12.9998 12.2999 12.7998 12.4999C12.6998 12.5999 12.5998 12.5999 12.4998 12.5999Z"
      fill="#9E9E9E" />
  </svg>
</template>
