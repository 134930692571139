<template>
  <path
    fill="currentColor"
    d="M5.5 1.25C4.55022 1.25 3.75 2.00303 3.75 2.96875V10.625C3.75 10.9702 4.02982 11.25 4.375 11.25C4.72018 11.25 5 10.9702 5 10.625V2.96875C5 2.72635 5.20714 2.5 5.5 2.5H14.5C14.7929 2.5 15 2.72635 15 2.96875V10.625C15 10.9702 15.2798 11.25 15.625 11.25C15.9702 11.25 16.25 10.9702 16.25 10.625V2.96875C16.25 2.00303 15.4498 1.25 14.5 1.25H5.5Z" />
  <path
    fill="currentColor"
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M7.20318 13.2706C6.9905 12.8452 6.59571 12.5 6.12011 12.5H2.25C1.69772 12.5 1.25 12.9477 1.25 13.5V17.75C1.25 18.3023 1.69772 18.75 2.25 18.75H17.75C18.3023 18.75 18.75 18.3023 18.75 17.75V13.5C18.75 12.9477 18.3023 12.5 17.75 12.5H13.8799C13.4043 12.5 13.0095 12.8452 12.7968 13.2706C12.2842 14.2959 11.2243 15 10 15C8.77569 15 7.71582 14.2959 7.20318 13.2706ZM2.5 17.5V13.75H6.03361L6.03814 13.7555C6.05396 13.7751 6.07048 13.8003 6.08514 13.8296C6.8013 15.262 8.28414 16.25 10 16.25C11.7159 16.25 13.1987 15.262 13.9149 13.8296C13.9295 13.8003 13.946 13.7751 13.9619 13.7555L13.9664 13.75H17.5V17.5H2.5Z" />
  <path
    fill="currentColor"
    d="M7.5 5.625C7.5 5.27982 7.77982 5 8.125 5H9.375C9.72018 5 10 5.27982 10 5.625C10 5.97018 9.72018 6.25 9.375 6.25H8.125C7.77982 6.25 7.5 5.97018 7.5 5.625Z" />
  <path
    fill="currentColor"
    d="M8.125 7.5C7.77982 7.5 7.5 7.77982 7.5 8.125C7.5 8.47018 7.77982 8.75 8.125 8.75H11.875C12.2202 8.75 12.5 8.47018 12.5 8.125C12.5 7.77982 12.2202 7.5 11.875 7.5H8.125Z" />
</template>
