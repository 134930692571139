<template>
  <path
    fill="currentColor"
    d="M8.23576 13.9049C7.24515 13.5899 8.04666 16.0887 9.98325 16.0887C13.8964 16.0887 11.7051 9.84857 10.2489 10.6796C9.88198 10.8889 9.77247 11.5349 9.65976 12.1998C9.48987 13.202 9.31268 14.2473 8.23576 13.9049Z" />
  <path
    fill="currentColor"
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M9.49321 1.42619C8.85104 1.07836 8.17861 1.28635 7.77642 1.69512C7.37823 2.09984 7.17799 2.76669 7.49917 3.40104C8.23424 4.86678 8.24295 6.29102 8.06198 7.36298C8.02396 7.58815 7.97771 7.79681 7.92842 7.98578C7.82573 7.78654 7.65417 7.52049 7.37155 7.36995C7.19035 7.27343 6.97721 7.23198 6.75236 7.27288C6.54073 7.31137 6.36131 7.41479 6.21455 7.53444C4.08753 9.25997 3.26541 12.157 4.1538 14.7641C5.10902 17.5591 7.74757 18.8424 10.2503 18.7429C12.7543 18.6434 15.2742 17.1538 15.9799 14.2698C16.4841 12.217 16.1088 9.6841 15.0089 7.34939C13.9047 5.00558 12.0432 2.80125 9.49321 1.42619ZM7.49917 3.40104C7.49942 3.40153 7.49967 3.40203 7.49992 3.40252L8.05721 3.11962L7.4986 3.3999C7.49879 3.40028 7.49898 3.40066 7.49917 3.40104ZM8.63589 9.68052L8.08268 9.38968C8.63589 9.68052 8.63576 9.68078 8.63589 9.68052L8.63645 9.67946L8.63716 9.67811L8.639 9.67456L8.64435 9.66411C8.64864 9.65567 8.65436 9.64428 8.66135 9.63003C8.67532 9.60154 8.69441 9.56157 8.7174 9.51083C8.76336 9.4094 8.82508 9.26457 8.89281 9.08197C9.02802 8.71743 9.18861 8.1985 9.29454 7.57106C9.50633 6.31651 9.50236 4.60617 8.61585 2.83932L8.61453 2.8367C8.57276 2.75442 8.58435 2.65626 8.66746 2.5718C8.70742 2.53118 8.7508 2.51033 8.7852 2.5036C8.81342 2.49808 8.84872 2.49866 8.89803 2.5254L8.89939 2.52614C11.1828 3.75727 12.8714 5.74521 13.8781 7.88211C14.8891 10.0282 15.1853 12.2646 14.7659 13.9718C14.2206 16.201 12.274 17.4115 10.2007 17.4939C8.12602 17.5764 6.07615 16.5233 5.33691 14.3607C4.63692 12.3062 5.24799 10.0357 6.85243 8.63129C6.91367 8.76636 6.96923 8.93125 7.0277 9.11316L7.04567 9.16947C7.09508 9.32489 7.16178 9.53464 7.23664 9.67693C7.39108 9.9713 7.68316 10.0932 7.9281 10.096C8.17339 10.0989 8.46889 9.98349 8.62993 9.69159L8.63589 9.68052Z" />
</template>
