<template>
  <path
    fill="currentColor"
    d="M13.5296 7.22785C13.7028 6.92928 13.6012 6.54682 13.3026 6.37362C13.004 6.20041 12.6216 6.30205 12.4484 6.60062L9.30602 12.0175L7.48134 9.92746C7.25433 9.66743 6.85951 9.64067 6.59948 9.86768C6.33945 10.0947 6.31269 10.4895 6.5397 10.7495L8.93809 13.4967C9.07062 13.6486 9.26823 13.7271 9.46883 13.7078C9.66943 13.6885 9.8484 13.5736 9.94953 13.3993L13.5296 7.22785Z" />
  <path
    fill="currentColor"
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M10.4041 1.45746C10.1365 1.39569 9.86356 1.39569 9.59592 1.45746C9.10974 1.56966 8.02253 1.83038 6.86241 2.1743C5.719 2.51325 4.44309 2.95048 3.6153 3.42688C3.05843 3.74737 2.83326 4.34709 2.83746 4.87098C2.87994 10.1637 3.50837 13.2917 4.67846 15.2445C5.88355 17.2557 7.60834 17.9311 9.47169 18.489C9.81624 18.5922 10.1838 18.5922 10.5283 18.489C12.3917 17.9311 14.1165 17.2557 15.3216 15.2445C16.4917 13.2917 17.1201 10.1637 17.1626 4.87098C17.1668 4.34709 16.9416 3.74737 16.3847 3.42688C15.557 2.95048 14.281 2.51325 13.1376 2.1743C11.9775 1.83038 10.8903 1.56966 10.4041 1.45746ZM9.87701 2.67544C9.95969 2.65636 10.0404 2.65636 10.123 2.67544C10.597 2.78483 11.6567 3.03905 12.7824 3.37274C13.9247 3.71139 15.0734 4.11444 15.7612 4.51028C15.83 4.54987 15.9142 4.66299 15.9126 4.86095C15.8704 10.1189 15.2364 12.9546 14.2493 14.602C13.2972 16.191 11.9701 16.7525 10.1698 17.2915C10.0592 17.3246 9.94089 17.3246 9.83025 17.2915C8.02994 16.7525 6.70281 16.191 5.75072 14.602C4.76363 12.9546 4.12962 10.1189 4.08742 4.86095C4.08583 4.66299 4.17001 4.54987 4.23881 4.51028C4.92661 4.11444 6.07533 3.71139 7.21768 3.37274C8.34334 3.03905 9.40304 2.78483 9.87701 2.67544Z" />
</template>
