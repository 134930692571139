<template>
  <path
    fill="currentColor"
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M10.4507 1.44204C10.3329 1.31935 10.1701 1.25 10 1.25C9.82987 1.25 9.6671 1.31935 9.54925 1.44204L4.87444 6.30888C4.37081 6.8332 4.74241 7.70538 5.46943 7.70538H7.5L7.49999 14.5803C7.49999 14.7461 7.56584 14.9051 7.68305 15.0223C7.80026 15.1395 7.95923 15.2053 8.12499 15.2053H11.875C12.2202 15.2053 12.5 14.9255 12.5 14.5803L12.5 7.70538H14.5306C15.2576 7.70538 15.6292 6.8332 15.1256 6.30888L10.4507 1.44204ZM8.125 6.45538H6.46696L10 2.77722L13.533 6.45538H11.875C11.5298 6.45538 11.25 6.73521 11.25 7.08038L11.25 13.9553H8.74999L8.75 7.08038C8.75 6.91462 8.68415 6.75565 8.56694 6.63844C8.44973 6.52123 8.29076 6.45538 8.125 6.45538Z" />
  <path
    fill="currentColor"
    d="M2.50001 14.375C2.50002 14.0298 2.2202 13.75 1.87502 13.75C1.52985 13.75 1.25002 14.0298 1.25001 14.375L1.25 16.8751C1.25 17.9107 2.08952 18.75 3.125 18.75H16.8749C17.9104 18.75 18.7499 17.9106 18.7499 16.8751L18.7499 14.375C18.7499 14.0298 18.4701 13.75 18.1249 13.75C17.7798 13.75 17.4999 14.0298 17.4999 14.375L17.4999 16.8751C17.4999 17.2202 17.2201 17.5 16.8749 17.5H3.125C2.77978 17.5 2.5 17.2202 2.5 16.8751L2.50001 14.375Z" />
</template>
