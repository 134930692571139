<template>
  <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.49995 2.99936L14.4999 2.99805C14.7761 2.99802 15 3.22185 15 3.49799C15 3.77413 14.7762 3.99802 14.5001 3.99805L2.50005 3.99936C2.22391 3.99939 2.00003 3.77556 2 3.49942C1.99997 3.22327 2.2238 2.99939 2.49995 2.99936Z"
      fill="currentColor" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.97492 6.00077L12.025 6C12.3012 5.99997 12.5251 6.2238 12.5251 6.49994C12.5251 6.77609 12.3013 6.99997 12.0251 7L4.97503 7.00077C4.69889 7.0008 4.47501 6.77697 4.47498 6.50083C4.47495 6.22468 4.69878 6.0008 4.97492 6.00077Z"
      fill="currentColor" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.97492 12.0027L12.025 12.002C12.3012 12.0019 12.5251 12.2258 12.5251 12.5019C12.5251 12.778 12.3013 13.0019 12.0251 13.002L4.97503 13.0027C4.69889 13.0028 4.47501 12.7789 4.47498 12.5028C4.47495 12.2266 4.69878 12.0028 4.97492 12.0027Z"
      fill="currentColor" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.52704 9.00109L13.4728 9C13.749 8.99997 13.9729 9.2238 13.9729 9.49994C13.9729 9.77609 13.7491 9.99997 13.473 10L3.52715 10.0011C3.25101 10.0011 3.02713 9.77729 3.0271 9.50114C3.02707 9.225 3.2509 9.00112 3.52704 9.00109Z"
      fill="currentColor" />
  </svg>
</template>
