import type { Ref } from 'vue';
import { ref } from 'vue';
import { onBeforeUnmount, onMounted, watch } from 'vue';

type Options = {
  detectIframe?: boolean;
  contains?: Ref<HTMLElement | undefined>[];
  containSelectors?: string[];
};

export function useOutsideClick(el: Ref<HTMLElement | undefined>, callback: () => any, options?: Options) {
  const iframeDocument = ref<HTMLIFrameElement>();

  const addEventListenClickOutside = () => {
    window.addEventListener('mousedown', handler);
    if (options?.detectIframe) {
      getIframeDocument();
      listenOnIframe();
    }
  };

  const removeEventListenClickOutside = () => {
    window.removeEventListener('mousedown', handler);
    options?.detectIframe && removeListenOnIframe();
  };

  const listenOnIframe = () => {
    addEventBlurWindow();
    addEventClickIframe();
  };

  const addEventBlurWindow = () => {
    window.addEventListener('blur', handlerDetectIframe);
  };

  const addEventClickIframe = () => {
    iframeDocument.value?.addEventListener('mousedown', handler);
  };

  const removeListenOnIframe = () => {
    removeEventBlurWindow();
    removeEventClickIframe();
  };

  const removeEventBlurWindow = () => {
    window.removeEventListener('blur', handlerDetectIframe);
  };

  const removeEventClickIframe = () => {
    iframeDocument.value?.removeEventListener('mousedown', handler);
  };

  const handler = (e: Event) => {
    const clickedEl = e.target as HTMLElement;
    if (!isContainElement(clickedEl)) {
      callback();
    }
  };

  const handlerDetectIframe = () => {
    if (document.activeElement?.tagName === 'IFRAME' && !isContainElement(document.activeElement)) {
      callback();
    }
  };

  const isContainElement = (checkEl: Element) => {
    const fixedEls = options?.contains?.map((containRef) => containRef.value) || [];
    const dynamicEls = getDynamicContainElements();
    const containEls = [...fixedEls, ...dynamicEls];
    return el.value?.contains(checkEl) || containEls.find((containEl) => containEl?.contains(checkEl));
  };

  const getDynamicContainElements = () => {
    if (!options?.containSelectors?.length) {
      return [];
    }
    const ignoreDoms = document.querySelectorAll(options.containSelectors.join(','));
    const arrayDoms = [];
    for (let i = 0; i < ignoreDoms.length; i++) {
      arrayDoms.push(ignoreDoms[i] as HTMLElement);
    }
    return arrayDoms;
  };

  const getIframeDocument = () => {
    const iframe = document.querySelector('iframe');
    if (!iframe) {
      return;
    }
    iframeDocument.value = iframe.contentDocument || (iframe.contentWindow as any).document;
  };

  watch(el, (newVal) => {
    removeEventListenClickOutside();
    if (newVal) {
      addEventListenClickOutside();
    }
  });

  onMounted(() => {
    if (el.value) {
      addEventListenClickOutside();
    }
  });

  onBeforeUnmount(() => {
    removeEventListenClickOutside();
  });
}
