<template>
  <g>
    <g>
      <path
        d="m7.977 485.191c8.08 8.08 20.346 10.257 30.712 5.449l281.333-121.124c13.77-6.385 16.988-24.523 6.256-35.256l-166.762-166.762c-10.733-10.733-28.871-7.515-35.256 6.256l-121.732 280.725c-4.807 10.366-2.631 22.632 5.449 30.712z"
        fill="#ffe266"
        data-original="#ffe266"
        class=""></path>
      <path
        d="m242.333 342.982-206.806 89.037c-7.309 3.389-15.894 2.045-21.825-3.31l-11.175 25.77c-4.807 10.367-2.631 22.632 5.449 30.712s20.346 10.256 30.712 5.449l278.635-119.962c-6.419-2.573-73.946-28.18-74.99-27.696z"
        fill="#efaa66"
        data-original="#efaa66"></path>
      <ellipse
        cx="231.044"
        cy="260.908"
        fill="#ffe266"
        rx="49.233"
        ry="137.532"
        transform="matrix(.707 -.707 .707 .707 -116.819 239.791)"
        data-original="#ffe266"
        class=""></ellipse>
      <ellipse
        cx="211.102"
        cy="264.918"
        fill="#efaa66"
        rx="32.296"
        ry="96.826"
        transform="matrix(.707 -.707 .707 .707 -125.495 226.864)"
        data-original="#efaa66"></ellipse>
      <g>
        <path
          d="m310.924 374.229c-30.645 0-78.104-27.777-121.764-71.437-39.645-39.646-66.812-83.308-70.895-113.947-.729-5.475 3.116-10.504 8.591-11.233 5.47-.727 10.504 3.116 11.233 8.591 3.479 26.098 29.077 66.311 65.214 102.447 46.173 46.173 88.75 66.305 108.76 65.557 5.512-.201 10.169 4.077 10.389 9.596.219 5.518-4.077 10.17-9.596 10.389-.635.024-1.283.037-1.932.037z"
          fill="#d0844b"
          data-original="#d0844b"></path>
      </g>
      <path
        d="m204.007 219.851c-2.347-2.347-4.158-5.296-5.164-8.704l-9.247-31.3c-3.493-11.82-.341-24.484 8.224-33.049l16.81-16.81c8.566-8.566 21.23-11.717 33.05-8.224l33.67 9.503-11.356-40.515c-3.218-10.891 3.003-22.328 13.894-25.546s22.328 3.003 25.547 13.894l9.247 31.3c3.492 11.821.341 24.485-8.224 33.049l-16.81 16.81c-8.565 8.565-21.229 11.716-33.049 8.224l-34.014-11.092 11.699 42.104c3.218 10.891-3.003 22.328-13.894 25.546-7.485 2.211-15.228-.035-20.383-5.19z"
        fill="#cab4ef"
        data-original="#cab4ef"
        class=""></path>
      <path
        d="m281.35 131.267-33.67-9.503c-10.16-3.002-20.94-1.092-29.208 4.941 2.315 9.257 5.26 21.571 8.112 30.685l34.014 11.092c10.642 3.144 21.965.901 30.368-5.827-2.976-9.316-6.784-22.028-9.616-31.388z"
        fill="#a183e2"
        data-original="#a183e2"></path>
      <path
        d="m334.232 205.215c2.347 2.347 5.297 4.158 8.704 5.164l31.3 9.247c11.819 3.492 24.484.341 33.049-8.224l16.81-16.81c8.566-8.566 11.717-21.23 8.224-33.05l-9.503-33.67 40.515 11.355c10.891 3.218 22.329-3.003 25.547-13.894s-3.003-22.328-13.894-25.546l-31.3-9.247c-11.821-3.492-24.485-.34-33.049 8.224l-16.81 16.81c-8.565 8.565-11.716 21.229-8.224 33.049l11.091 34.014-42.104-11.699c-10.891-3.218-22.328 3.003-25.546 13.894-2.212 7.485.035 15.228 5.19 20.383z"
        fill="#f7c4db"
        data-original="#f7c4db"></path>
      <path
        d="m432.318 161.542-9.503-33.67c-9.796-2.111-23.5-4.812-33.277-7.005-5.235 8.055-6.767 18.182-3.938 27.757l11.091 34.014c10.141 2.504 20.872 4.231 30.608 8.218 6.095-8.282 8.034-19.111 5.019-29.314z"
        fill="#ef8bbd"
        data-original="#ef8bbd"></path>
      <path
        d="m382.105 36.123c-6.52 6.52-17.092 6.52-23.612 0s-6.52-17.092 0-23.612 17.092-6.52 23.612 0c6.52 6.521 6.52 17.092 0 23.612z"
        fill="#ffa585"
        data-original="#ffa585"></path>
      <circle cx="407.299" cy="296.806" fill="#78eac4" r="16.696" data-original="#78eac4"></circle>
      <path
        d="m315.962 311.644c-6.52 6.52-17.092 6.52-23.612 0s-6.52-17.092 0-23.612 17.092-6.52 23.612 0c6.52 6.521 6.52 17.092 0 23.612z"
        fill="#78eac4"
        data-original="#78eac4"></path>
      <path
        d="m315.962 311.644c-6.52 6.52-17.092 6.52-23.612 0s-6.52-17.092 0-23.612 17.092-6.52 23.612 0c6.52 6.521 6.52 17.092 0 23.612z"
        fill="#8bb6ef"
        data-original="#8bb6ef"></path>
      <g>
        <path
          d="m90.904 360.592c-1.319 0-2.661-.263-3.949-.818-5.073-2.184-7.415-8.066-5.23-13.139l12.664-29.414c2.184-5.072 8.068-7.418 13.139-5.23 5.073 2.184 7.415 8.066 5.23 13.139l-12.664 29.414c-1.629 3.783-5.316 6.048-9.19 6.048z"
          fill="#ffffff"
          data-original="#ffffff"></path>
      </g>
      <g>
        <path
          d="m117.741 298.264c-1.319 0-2.66-.263-3.95-.818-5.072-2.184-7.414-8.066-5.229-13.14l3.86-8.966c2.184-5.071 8.064-7.417 13.14-5.229 5.072 2.184 7.414 8.066 5.23 13.14l-3.86 8.966c-1.63 3.782-5.318 6.047-9.191 6.047z"
          fill="#ffffff"
          data-original="#ffffff"></path>
      </g>
      <path
        d="m196.217 8.13 8.214 23.098c.352.989 1.048 1.773 1.927 2.169l20.521 9.246c2.899 1.306 2.899 5.925 0 7.232l-20.521 9.246c-.879.396-1.575 1.18-1.927 2.168l-8.214 23.098c-1.16 3.263-5.265 3.263-6.425 0l-8.214-23.098c-.352-.989-1.048-1.773-1.927-2.168l-20.521-9.246c-2.899-1.306-2.899-5.925 0-7.232l20.521-9.246c.879-.396 1.575-1.18 1.927-2.169l8.214-23.098c1.16-3.264 5.265-3.264 6.425 0z"
        fill="#f7c4db"
        data-original="#f7c4db"></path>
      <path
        d="m474.493 215.776 6.093 17.133c.261.734.777 1.315 1.429 1.609l15.222 6.858c2.151.969 2.151 4.395 0 5.364l-15.222 6.858c-.652.294-1.168.875-1.429 1.609l-6.093 17.133c-.861 2.421-3.905 2.421-4.766 0l-6.093-17.133c-.261-.734-.777-1.315-1.429-1.609l-15.222-6.858c-2.151-.969-2.151-4.395 0-5.364l15.222-6.858c.652-.294 1.168-.875 1.429-1.609l6.093-17.133c.861-2.42 3.905-2.42 4.766 0z"
        fill="#cab4ef"
        data-original="#cab4ef"
        class=""></path>
      <circle cx="361.199" cy="79.016" fill="#78eac4" r="7.737" data-original="#78eac4"></circle>
      <ellipse
        cx="464.23"
        cy="29.623"
        fill="#8bb6ef"
        rx="14.282"
        ry="14.283"
        transform="matrix(.122 -.993 .993 .122 378.107 486.756)"
        data-original="#8bb6ef"></ellipse>
    </g>
  </g>
</template>
