<template>
  <path
    fill="currentColor"
    d="M8.56405 11.8767V11.3001L9.91132 10.0526C10.0259 9.94175 10.122 9.84195 10.1996 9.75324C10.2785 9.66453 10.3382 9.57767 10.3789 9.49265C10.4196 9.40641 10.4399 9.31339 10.4399 9.21359C10.4399 9.1027 10.4146 9.00722 10.3641 8.92713C10.3136 8.84582 10.2446 8.7836 10.1571 8.74047C10.0696 8.69612 9.97046 8.67394 9.85958 8.67394C9.74376 8.67394 9.64273 8.69735 9.55649 8.74417C9.47024 8.79099 9.40371 8.85814 9.35689 8.94561C9.31007 9.03309 9.28666 9.1372 9.28666 9.25795H8.52709C8.52709 9.0103 8.58315 8.7953 8.69527 8.61295C8.80739 8.43061 8.96448 8.28953 9.16654 8.18974C9.3686 8.08994 9.60146 8.04004 9.86512 8.04004C10.1362 8.04004 10.3721 8.08809 10.5729 8.18419C10.775 8.27906 10.9321 8.41089 11.0442 8.57969C11.1563 8.74848 11.2124 8.94192 11.2124 9.16C11.2124 9.30292 11.1841 9.44399 11.1274 9.58321C11.0719 9.72244 10.9728 9.87706 10.8298 10.0471C10.6869 10.2159 10.4855 10.4186 10.2255 10.6551L9.67292 11.1966V11.2225H11.2623V11.8767H8.56405Z" />
  <path
    fill="currentColor"
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M5.14551 11.8767V8.09179H6.66096C6.93941 8.09179 7.17165 8.13306 7.3577 8.21561C7.54374 8.29816 7.68358 8.41274 7.77722 8.55936C7.87086 8.70474 7.91767 8.87231 7.91767 9.06205C7.91767 9.20989 7.8881 9.33988 7.82897 9.452C7.76983 9.56288 7.68851 9.65406 7.58501 9.72552C7.48275 9.79574 7.36571 9.84564 7.23387 9.87521V9.91218C7.37803 9.91834 7.51294 9.95899 7.63861 10.0342C7.76551 10.1093 7.86839 10.2147 7.94724 10.3502C8.0261 10.4845 8.06552 10.6446 8.06552 10.8307C8.06552 11.0315 8.01562 11.2108 7.91583 11.3685C7.81726 11.525 7.67126 11.6488 7.47782 11.74C7.28439 11.8311 7.04598 11.8767 6.76261 11.8767H5.14551ZM5.94574 11.2225H6.59812C6.82113 11.2225 6.98376 11.18 7.08602 11.095C7.18829 11.0087 7.23942 10.8941 7.23942 10.7512C7.23942 10.6465 7.21416 10.5541 7.16365 10.474C7.11313 10.3939 7.04105 10.3311 6.94742 10.2855C6.85501 10.2399 6.74474 10.2171 6.6166 10.2171H5.94574V11.2225ZM5.94574 9.67562H6.53898C6.64864 9.67562 6.74597 9.65652 6.83099 9.61833C6.91723 9.5789 6.98499 9.52346 7.03428 9.452C7.08479 9.38054 7.11005 9.29491 7.11005 9.19511C7.11005 9.05835 7.06138 8.94808 6.96405 8.8643C6.86795 8.78052 6.73119 8.73863 6.55377 8.73863H5.94574V9.67562Z" />
  <path
    fill="currentColor"
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M12.5056 11.7252C12.7348 11.8804 13.0083 11.9587 13.3262 11.9599C13.644 11.9599 13.917 11.8823 14.1449 11.727C14.3728 11.5718 14.5478 11.3469 14.6697 11.0525C14.793 10.758 14.8546 10.4032 14.8546 9.98795C14.8558 9.5752 14.7948 9.22406 14.6716 8.93453C14.5484 8.64376 14.3722 8.42198 14.143 8.26921C13.9151 8.11643 13.6428 8.04004 13.3262 8.04004C13.0095 8.04004 12.7366 8.11643 12.5075 8.26921C12.2795 8.42075 12.104 8.64191 11.9807 8.93268C11.8588 9.22345 11.7978 9.5752 11.7978 9.98795C11.7966 10.4019 11.8569 10.7561 11.9789 11.0506C12.1021 11.3451 12.2777 11.5699 12.5056 11.7252ZM13.8455 10.9693C13.7161 11.1874 13.543 11.2964 13.3262 11.2964C13.182 11.2964 13.0563 11.2484 12.9492 11.1523C12.842 11.0549 12.7588 10.9095 12.6997 10.7161C12.6418 10.5214 12.6128 10.2787 12.6128 9.98795C12.614 9.55795 12.6793 9.23515 12.8087 9.01954C12.9381 8.80393 13.1106 8.69612 13.3262 8.69612C13.4703 8.69612 13.5954 8.74417 13.7013 8.84027C13.8085 8.93637 13.8911 9.07991 13.949 9.27088C14.0081 9.46185 14.0377 9.70088 14.0377 9.98795C14.0389 10.4241 13.9749 10.7512 13.8455 10.9693Z" />
  <path
    fill="currentColor"
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M18.75 10C18.75 14.8325 14.8325 18.75 10 18.75C5.16751 18.75 1.25 14.8325 1.25 10C1.25 5.16751 5.16751 1.25 10 1.25C14.8325 1.25 18.75 5.16751 18.75 10ZM17.5 10C17.5 14.1421 14.1421 17.5 10 17.5C5.85786 17.5 2.5 14.1421 2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10Z" />
</template>
