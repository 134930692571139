<template>
  <path
    d="M4.0625 4.6875C4.0625 4.34232 4.34232 4.0625 4.6875 4.0625H5.9375C6.28268 4.0625 6.5625 4.34232 6.5625 4.6875V5.9375C6.5625 6.28268 6.28268 6.5625 5.9375 6.5625H4.6875C4.34232 6.5625 4.0625 6.28268 4.0625 5.9375V4.6875Z"
    fill="white" />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M2.5 1.25C1.80964 1.25 1.25 1.80964 1.25 2.5V8.125C1.25 8.81536 1.80964 9.375 2.5 9.375H8.125C8.81536 9.375 9.375 8.81536 9.375 8.125V2.5C9.375 1.80964 8.81536 1.25 8.125 1.25H2.5ZM8.125 2.5H2.5L2.5 8.125H8.125V2.5Z"
    fill="white" />
  <path
    d="M14.0625 4.0625C13.7173 4.0625 13.4375 4.34232 13.4375 4.6875V5.9375C13.4375 6.28268 13.7173 6.5625 14.0625 6.5625H15.3125C15.6577 6.5625 15.9375 6.28268 15.9375 5.9375V4.6875C15.9375 4.34232 15.6577 4.0625 15.3125 4.0625H14.0625Z"
    fill="white" />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M11.875 1.25C11.1846 1.25 10.625 1.80964 10.625 2.5V8.125C10.625 8.81536 11.1846 9.375 11.875 9.375H17.5C18.1904 9.375 18.75 8.81536 18.75 8.125V2.5C18.75 1.80964 18.1904 1.25 17.5 1.25H11.875ZM17.5 2.5H11.875V8.125H17.5V2.5Z"
    fill="white" />
  <path
    d="M4.6875 13.4375C4.34232 13.4375 4.0625 13.7173 4.0625 14.0625V15.3125C4.0625 15.6577 4.34232 15.9375 4.6875 15.9375H5.9375C6.28268 15.9375 6.5625 15.6577 6.5625 15.3125V14.0625C6.5625 13.7173 6.28268 13.4375 5.9375 13.4375H4.6875Z"
    fill="white" />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M1.25 11.875C1.25 11.1846 1.80964 10.625 2.5 10.625H8.125C8.81536 10.625 9.375 11.1846 9.375 11.875V17.5C9.375 18.1904 8.81536 18.75 8.125 18.75H2.5C1.80964 18.75 1.25 18.1904 1.25 17.5V11.875ZM2.5 11.875H8.125V17.5H2.5L2.5 11.875Z"
    fill="white" />
  <path
    d="M13.4375 14.0625C13.4375 13.7173 13.7173 13.4375 14.0625 13.4375H15.3125C15.6577 13.4375 15.9375 13.7173 15.9375 14.0625V15.3125C15.9375 15.6577 15.6577 15.9375 15.3125 15.9375H14.0625C13.7173 15.9375 13.4375 15.6577 13.4375 15.3125V14.0625Z"
    fill="white" />
  <path
    d="M11.25 16.25C10.9048 16.25 10.625 16.5298 10.625 16.875V18.125C10.625 18.4702 10.9048 18.75 11.25 18.75H12.5C12.8452 18.75 13.125 18.4702 13.125 18.125V16.875C13.125 16.5298 12.8452 16.25 12.5 16.25H11.25Z"
    fill="white" />
  <path
    d="M16.25 11.2445C16.25 10.8993 16.5298 10.6195 16.875 10.6195H18.125C18.4702 10.6195 18.75 10.8993 18.75 11.2445V12.4945C18.75 12.8397 18.4702 13.1195 18.125 13.1195H16.875C16.5298 13.1195 16.25 12.8397 16.25 12.4945V11.2445Z"
    fill="white" />
  <path
    d="M11.25 10.6195C10.9048 10.6195 10.625 10.8993 10.625 11.2445V12.4945C10.625 12.8397 10.9048 13.1195 11.25 13.1195H12.5C12.8452 13.1195 13.125 12.8397 13.125 12.4945V11.2445C13.125 10.8993 12.8452 10.6195 12.5 10.6195H11.25Z"
    fill="white" />
  <path
    d="M16.25 16.875C16.25 16.5298 16.5298 16.25 16.875 16.25H18.125C18.4702 16.25 18.75 16.5298 18.75 16.875V18.125C18.75 18.4702 18.4702 18.75 18.125 18.75H16.875C16.5298 18.75 16.25 18.4702 16.25 18.125V16.875Z"
    fill="white" />
</template>
