<script lang="ts" setup>
import { computed, onMounted, ref, useSlots, withDefaults } from 'vue';
import type { InputType, InputStyle, InputSize } from '../types/input';

const props = withDefaults(
  defineProps<{
    inputStyle?: InputStyle;
    type?: InputType;
    size?: InputSize;
    disable?: boolean;
    placeholder?: string;
    name?: string;
    value?: any;
    error?: string;
    autoFocus?: boolean;
    classes?: string;
    clsIconClose?: string;
    clsIconSearch?: string;
    shouldFocus?: boolean;
  }>(),
  {
    inputStyle: 'primary',
    type: 'text',
    size: 'medium',
    disable: false,
    autoFocus: false,
    error: '',
  },
);
const slots = useSlots();

const inputSearch = ref<HTMLInputElement>();

onMounted(() => {
  if (props.shouldFocus) {
    inputSearch.value?.focus();
  }
});

//=================== Styles =====================
const inputClasses = computed(() => {
  const classes = ['w-full', 'rounded-medium', 'transition-colors', 'duration-200', 'outline-0'];

  switch (props.inputStyle) {
    case 'primary':
      if (props.disable) {
        classes.push(
          'placeholder:text-dark-disabled border-dark-300 disabled:cursor-not-allowed disabled:border-dark-200',
        );
      } else {
        classes.push('placeholder:text-14 placeholder:text-light-disabled bg-white border text-light-high');
      }
      if (props.error !== '') {
        classes.push('border-red-300 hover:border-red-300 focus:border-red-300');
      } else {
        classes.push('border-transparent hover:border-transparent focus:border-primary-300');
      }
      break;
    case 'secondary':
      if (props.disable) {
        classes.push(
          'placeholder:text-dark-disabled border-dark-300 disabled:cursor-not-allowed disabled:border-dark-200',
        );
      } else {
        classes.push(
          'text-dark-high  placeholder:text-14 placeholder:text-light-disabled bg-transparent border border-transparent hover:border-dark-100 focus:border-primary-300',
        );
      }
      break;
  }
  switch (props.size) {
    case 'large':
      classes.push('h-40 text-16 p-12 leading-4');
      break;
    case 'medium':
      classes.push('h-40 text-14 p-10 leading-4');
      break;
    case 'small':
      classes.push('h-32 text-12 p-8 leading-4');
      break;
  }
  if (slots.icon !== undefined) {
    classes.push('px-28');
  }
  return classes.join(' ');
});

//=================== End Styles =====================

//=================== Emit Function =====================
const emit = defineEmits<{
  (e: 'change', value: string): void;
  (e: 'on-change', value: string): void;
}>();
//=================== End Emit Function =====================

//=================== Methods ====================

const change = ($event: Event | KeyboardEvent | any) => {
  emit('change', $event?.target?.value);
};
const onChange = ($event: Event | KeyboardEvent | any) => {
  emit('on-change', $event?.target?.value);
};

//=================== End Methods =====================
</script>

<template>
  <div class="w-full">
    <slot name="label"></slot>
    <div class="relative flex w-full">
      <div class="absolute p-12 text-center" :class="clsIconSearch"><slot name="icon"></slot></div>
      <input
        ref="inputSearch"
        :auto-focus="autoFocus"
        :placeholder="placeholder"
        :class="[inputClasses, classes]"
        :type="type"
        :value="value"
        @change="change"
        @input="onChange" />
      <div :class="clsIconClose" class="absolute top-14 right-12">
        <slot name="icon-end"></slot>
      </div>
    </div>
    <div v-if="error !== ''" class="text-12 font-regular flex flex-row gap-8 pt-10 text-red-300">
      <g-base-icon name="error" width="16" height="16" view-box="0 0 16 16" custom-class="text-red-300"></g-base-icon
      ><span>{{ error }}</span>
    </div>
    <slot name="description"></slot>
  </div>
</template>
