<template>
  <path
    fill="currentColor"
    d="M10 1.25C10.3452 1.25 10.625 1.52982 10.625 1.875V9.32437L12.7344 7.63688C13.0039 7.42125 13.3972 7.46495 13.6128 7.73449C13.8285 8.00403 13.7848 8.39734 13.5152 8.61297L10.4026 11.1031C10.2939 11.1947 10.1534 11.25 10 11.25C9.87249 11.25 9.75389 11.2118 9.65503 11.1463C9.63944 11.1359 9.6242 11.1248 9.60937 11.113L6.48437 8.61297C6.21483 8.39734 6.17113 8.00403 6.38676 7.73449C6.60239 7.46495 6.9957 7.42125 7.26523 7.63688L9.375 9.32469V1.875C9.375 1.52982 9.65482 1.25 10 1.25Z" />
  <path
    fill="currentColor"
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M6.12011 11.25C6.59571 11.25 6.9905 11.5952 7.20318 12.0206C7.71582 13.0459 8.77569 13.75 10 13.75C11.2243 13.75 12.2842 13.0459 12.7968 12.0206C13.0095 11.5952 13.4043 11.25 13.8799 11.25H17.75C18.3023 11.25 18.75 11.6977 18.75 12.25V16.5C18.75 17.0523 18.3023 17.5 17.75 17.5H2.25C1.69772 17.5 1.25 17.0523 1.25 16.5V12.25C1.25 11.6977 1.69772 11.25 2.25 11.25H6.12011ZM2.5 12.5V16.25H17.5V12.5H13.9664L13.9619 12.5055C13.946 12.5251 13.9295 12.5503 13.9149 12.5796C13.1987 14.012 11.7159 15 10 15C8.28414 15 6.8013 14.012 6.08514 12.5796C6.07048 12.5503 6.05396 12.5251 6.03814 12.5055L6.03361 12.5H2.5Z" />
</template>
