<template>
  <rect width="90" height="48" rx="3" fill="#3E3E3E" />
  <rect x="10.5" y="9.5" width="31" height="29" rx="2.5" fill="#525252" />
  <circle cx="20.5" cy="20" r="2" fill="#F9F9F9" />
  <path
    d="M18.6969 28.6453L20.9097 26.0595C20.9846 25.9718 21.0772 25.9008 21.1814 25.8512C21.2856 25.8017 21.3991 25.7746 21.5145 25.7718C21.6299 25.769 21.7446 25.7905 21.8511 25.835C21.9576 25.8795 22.0535 25.9458 22.1326 26.0298L23.1673 27.1271L27.212 22.2946C27.2912 22.1999 27.3908 22.1243 27.5033 22.0733C27.6157 22.0224 27.7383 21.9974 27.8618 22.0002C27.9852 22.0031 28.1065 22.0337 28.2165 22.0898C28.3265 22.1459 28.4224 22.2261 28.4972 22.3243L33.3329 28.6772C33.4254 28.7989 33.482 28.9441 33.4964 29.0962C33.5107 29.2484 33.4823 29.4016 33.4144 29.5385C33.3464 29.6754 33.2415 29.7907 33.1115 29.8713C32.9815 29.952 32.8316 29.9948 32.6786 29.995L19.322 30C19.1649 29.9999 19.0111 29.9549 18.8789 29.8703C18.7466 29.7857 18.6413 29.6649 18.5755 29.5224C18.5098 29.3799 18.4862 29.2216 18.5077 29.0661C18.5292 28.9107 18.5949 28.7646 18.6969 28.6453Z"
    fill="#F9F9F9" />
  <rect x="10.5" y="9.5" width="31" height="29" rx="2.5" stroke="#5B5B5B" />
  <rect x="48.5" y="9.5" width="31" height="29" rx="2.5" fill="#525252" />
  <circle cx="58.5" cy="20" r="2" fill="#F9F9F9" />
  <path
    d="M56.6969 28.6453L58.9097 26.0595C58.9846 25.9718 59.0772 25.9008 59.1814 25.8512C59.2856 25.8017 59.3991 25.7746 59.5145 25.7718C59.6299 25.769 59.7446 25.7905 59.8511 25.835C59.9576 25.8795 60.0535 25.9458 60.1326 26.0298L61.1673 27.1271L65.212 22.2946C65.2912 22.1999 65.3908 22.1243 65.5033 22.0733C65.6157 22.0224 65.7383 21.9974 65.8618 22.0002C65.9852 22.0031 66.1065 22.0337 66.2165 22.0898C66.3265 22.1459 66.4224 22.2261 66.4972 22.3243L71.3329 28.6772C71.4254 28.7989 71.482 28.9441 71.4964 29.0962C71.5107 29.2484 71.4823 29.4016 71.4144 29.5385C71.3464 29.6754 71.2415 29.7907 71.1115 29.8713C70.9815 29.952 70.8316 29.9948 70.6786 29.995L57.322 30C57.1649 29.9999 57.0111 29.9549 56.8789 29.8703C56.7466 29.7857 56.6413 29.6649 56.5755 29.5224C56.5098 29.3799 56.4862 29.2216 56.5077 29.0661C56.5292 28.9107 56.5949 28.7646 56.6969 28.6453Z"
    fill="#F9F9F9" />
  <rect x="48.5" y="9.5" width="31" height="29" rx="2.5" stroke="#5B5B5B" />
</template>
