<template>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M2.64645 2.64645C2.84171 2.45118 3.15829 2.45118 3.35355 2.64645L13.3536 12.6464C13.5488 12.8417 13.5488 13.1583 13.3536 13.3536C13.1583 13.5488 12.8417 13.5488 12.6464 13.3536L2.64645 3.35355C2.45118 3.15829 2.45118 2.84171 2.64645 2.64645Z"
    fill="currentColor" />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M13.3536 2.64645C13.5488 2.84171 13.5488 3.15829 13.3536 3.35355L3.35355 13.3536C3.15829 13.5488 2.84171 13.5488 2.64645 13.3536C2.45118 13.1583 2.45118 12.8417 2.64645 12.6464L12.6464 2.64645C12.8417 2.45118 13.1583 2.45118 13.3536 2.64645Z"
    fill="currentColor" />
</template>
