<script lang="ts" setup>
import { ref } from 'vue';

const props = defineProps<{
  layoutSetting?: string;
  shopDomain?: string;
}>();

const currentOptions = ref({
  showHeader: false,
  showFooter: false,
});

const emit = defineEmits(['changeLayoutSetting']);

if (props.layoutSetting) {
  currentOptions.value = JSON.parse(props.layoutSetting);
}

const changeFooter = (value: boolean) => {
  currentOptions.value.showFooter = value;
  emit('changeLayoutSetting', JSON.stringify(currentOptions.value));
};

const changeHeader = (value: boolean) => {
  currentOptions.value.showHeader = value;
  emit('changeLayoutSetting', JSON.stringify(currentOptions.value));
};
</script>
<template>
  <div class="rounded-medium bg-white p-16">
    <div class="text-16 mb-4 w-full truncate font-medium leading-6">Shopify theme header & footer</div>
    <div class="text-14 text-light-high mb-16 border-b border-[#EDEDED] pb-16 font-normal">
      To edit those sections, please follow instruction in
      <a class="cursor-pointer no-underline" href="https://help.gempages.net/faqs/edit-footer-header/" target="_blank"
        >this article</a
      >
    </div>
    <div class="mb-16">
      <div class="flex h-32 items-center justify-between">
        <GCheckbox text="Use Shopify theme’s header" :value="currentOptions.showHeader" @on-change="changeHeader" />
        <GCheckbox text="Use Shopify theme’s footer" :value="currentOptions.showFooter" @on-change="changeFooter" />
      </div>
    </div>
    <div class="border-light-500 rounded-medium border">
      <div
        v-if="currentOptions.showHeader"
        class="bg-light flex h-[48px] items-center justify-between border-b border-1 border-[#EEEEEE] px-[32px]">
        <svg width="84" height="8" viewBox="0 0 84 8" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="22" height="8" rx="3" fill="#9E9E9E"/>
          <rect x="28" width="56" height="8" rx="3" fill="#E1E1E1"/>
        </svg>
        <svg width="192" height="8" viewBox="0 0 192 8" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="56" height="8" rx="3" fill="#E1E1E1"/>
          <rect x="68" width="56" height="8" rx="3" fill="#E1E1E1"/>
          <rect x="136" width="56" height="8" rx="3" fill="#E1E1E1"/>
        </svg>
      </div>
      <div class="text-dark-400 text-12 flex h-[160px] items-center justify-center bg-white">GemPages’s content</div>
      <div
        v-if="currentOptions.showFooter"
        class="bg-light flex h-[72px] items-center justify-between border-t border-1 border-[#EEEEEE] px-[32px]">
        <svg width="192" height="8" viewBox="0 0 192 8" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="56" height="8" rx="3" fill="#E1E1E1"/>
          <rect x="68" width="56" height="8" rx="3" fill="#E1E1E1"/>
          <rect x="136" width="56" height="8" rx="3" fill="#E1E1E1"/>
        </svg>
        <svg width="82" height="8" viewBox="0 0 82 8" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="22" height="8" rx="3" fill="#E1E1E1"/>
          <rect x="30" width="22" height="8" rx="3" fill="#E1E1E1"/>
          <rect x="60" width="22" height="8" rx="3" fill="#E1E1E1"/>
        </svg>
      </div>
    </div>
  </div>
</template>
