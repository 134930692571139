import { computePosition, flip } from '@floating-ui/dom';
import { offset, shift } from '@floating-ui/core';
import type { Ref } from 'vue';
import { watch } from 'vue';

export default function useFlip(referenceRef: Ref<HTMLElement | undefined>, flipRef: Ref<HTMLElement | undefined>) {
  const startFlip = (referenceEl: HTMLElement, flipEl: HTMLElement) => {
    if (!referenceEl || !flipEl) {
      return;
    }
    computePosition(referenceEl, flipEl, {
      placement: 'bottom',
      middleware: [flip(), shift(), offset(4)],
    }).then(({ x, y }) => {
      Object.assign(flipEl.style, {
        left: `${x}px`,
        top: `${y}px`,
      });
    });
  };

  watch([referenceRef, flipRef], ([newR, newF]) => {
    startFlip(newR as HTMLElement, newF as HTMLElement);
  });
}
