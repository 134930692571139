<template>
  <path
    fill="currentColor"
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M11.9723 8.50537H13.4544L14.5884 16.2499H13.4942L13.2953 14.7121V14.7342H12.0519L11.853 16.2499H10.8384L11.9723 8.50537ZM13.166 13.6832L12.6786 9.85514H12.6587L12.1812 13.6832H13.166Z" />
  <path
    fill="currentColor"
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M3.125 3.12488C3.125 2.08934 3.96447 1.24988 5 1.24988H11.6016C12.0865 1.24988 12.5525 1.43771 12.9018 1.77394L16.3002 5.04479C16.6675 5.39826 16.875 5.88601 16.875 6.39572V16.8749C16.875 17.9104 16.0355 18.7499 15 18.7499H5C3.96447 18.7499 3.125 17.9104 3.125 16.8749V3.12488ZM5 2.49988C4.65482 2.49988 4.375 2.7797 4.375 3.12488V16.8749C4.375 17.2201 4.65482 17.4999 5 17.4999H15C15.3452 17.4999 15.625 17.2201 15.625 16.8749V7.03819H13.1279C12.0924 7.03819 11.2529 6.19872 11.2529 5.16319V2.49988H5ZM12.5029 3.12492V5.16319C12.5029 5.50836 12.7828 5.78819 13.1279 5.78819H15.2701L12.5029 3.12492Z" />
</template>
