<template>
  <path
    d="M8 1.5C8.27614 1.5 8.5 1.72386 8.5 2V4.5C8.5 4.77614 8.27614 5 8 5C7.72386 5 7.5 4.77614 7.5 4.5V2C7.5 1.72386 7.72386 1.5 8 1.5Z"
    fill="currentColor" />
  <path
    d="M3.05035 3.55016C3.24562 3.3549 3.5622 3.3549 3.75746 3.55016L5.52523 5.31792C5.72049 5.51319 5.72049 5.82977 5.52523 6.02503C5.32996 6.22029 5.01338 6.22029 4.81812 6.02503L3.05035 4.25726C2.85509 4.062 2.85509 3.74542 3.05035 3.55016Z"
    fill="currentColor" />
  <path
    d="M1.5 8C1.22386 8 1 8.22386 1 8.5C1 8.77614 1.22386 9 1.5 9H4C4.27614 9 4.5 8.77614 4.5 8.5C4.5 8.22386 4.27614 8 4 8H1.5Z"
    fill="currentColor" />
  <path
    d="M12 8C11.7239 8 11.5 8.22386 11.5 8.5C11.5 8.77614 11.7239 9 12 9H14.5C14.7761 9 15 8.77614 15 8.5C15 8.22386 14.7761 8 14.5 8H12Z"
    fill="currentColor" />
  <path
    d="M11.1821 10.9748C10.9868 10.7795 10.6702 10.7795 10.475 10.9748C10.2797 11.17 10.2797 11.4866 10.475 11.6819L12.2427 13.4497C12.438 13.6449 12.7546 13.6449 12.9498 13.4497C13.1451 13.2544 13.1451 12.9378 12.9498 12.7425L11.1821 10.9748Z"
    fill="currentColor" />
  <path
    d="M8.5 12.5C8.5 12.2239 8.27614 12 8 12C7.72386 12 7.5 12.2239 7.5 12.5V15C7.5 15.2761 7.72386 15.5 8 15.5C8.27614 15.5 8.5 15.2761 8.5 15V12.5Z"
    fill="currentColor" />
  <path
    d="M12.9496 3.55016C13.1449 3.74542 13.1449 4.062 12.9496 4.25726L11.1819 6.02503C10.9866 6.22029 10.67 6.22029 10.4748 6.02503C10.2795 5.82977 10.2795 5.51319 10.4748 5.31792L12.2425 3.55016C12.4378 3.3549 12.7544 3.3549 12.9496 3.55016Z"
    fill="currentColor" />
  <path
    d="M5.52503 11.6819C5.72029 11.4866 5.72029 11.17 5.52503 10.9748C5.32976 10.7795 5.01318 10.7795 4.81792 10.9748L3.05015 12.7425C2.85489 12.9378 2.85489 13.2544 3.05015 13.4497C3.24541 13.6449 3.562 13.6449 3.75726 13.4497L5.52503 11.6819Z"
    fill="currentColor" />
</template>
