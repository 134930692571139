<template>
  <path
    fill="currentColor"
    d="M1.25 3.12524C1.25 2.08971 2.08947 1.25024 3.125 1.25024H5C5.34518 1.25024 5.625 1.53007 5.625 1.87524C5.625 2.22042 5.34518 2.50024 5 2.50024H3.125C2.77982 2.50024 2.5 2.78007 2.5 3.12524V5.00024C2.5 5.34542 2.22018 5.62524 1.875 5.62524C1.52982 5.62524 1.25 5.34542 1.25 5.00024V3.12524Z" />
  <path
    fill="currentColor"
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M12.1875 9.99842C12.1869 8.79058 11.2072 7.81195 9.99942 7.8125C8.7916 7.81291 7.8125 8.79216 7.8125 10C7.8125 11.2081 8.79188 12.1875 10 12.1875C11.2079 12.1875 12.1875 11.2088 12.1875 10.0004V9.99842ZM9.99992 9.0625C10.5175 9.06222 10.9372 9.48155 10.9375 9.99908V10.0004C10.9375 10.518 10.518 10.9375 10 10.9375C9.48223 10.9375 9.0625 10.5178 9.0625 10C9.0625 9.48233 9.48225 9.06264 9.99992 9.0625Z" />
  <path
    fill="currentColor"
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M18.0238 9.65913C18.0239 9.65934 18.024 9.65953 17.5 10.0001C18.024 10.3408 18.0239 10.341 18.0238 10.3412L18.0226 10.343L18.02 10.3469L18.0115 10.3597C18.0044 10.3704 17.9944 10.3854 17.9814 10.4043C17.9556 10.4422 17.9182 10.496 17.8696 10.5635C17.7725 10.6985 17.6303 10.8886 17.446 11.1154C17.0781 11.5682 16.5386 12.172 15.8502 12.7772C14.484 13.9783 12.4666 15.2405 10 15.2405C7.53342 15.2405 5.51604 13.9783 4.14984 12.7772C3.46139 12.172 2.92186 11.5682 2.55399 11.1154C2.3697 10.8886 2.22754 10.6985 2.1304 10.5635C2.08181 10.496 2.04441 10.4422 2.01856 10.4043C2.00564 10.3854 1.9956 10.3704 1.98849 10.3597L1.98002 10.3469L1.97744 10.343L1.97657 10.3417C1.97643 10.3415 1.97597 10.3408 2.5 10.0001C1.97597 9.65953 1.9761 9.65934 1.97624 9.65913L1.97744 9.65728L1.98002 9.65335L1.98849 9.64056C1.9956 9.62988 2.00564 9.61493 2.01856 9.59599C2.04441 9.55812 2.08181 9.50429 2.1304 9.43676C2.22754 9.30177 2.3697 9.11169 2.55399 8.88488C2.92186 8.43211 3.46139 7.82828 4.14984 7.22306C5.51604 6.022 7.53342 4.75977 10 4.75977C12.4666 4.75977 14.484 6.022 15.8502 7.22306C16.5386 7.82828 17.0781 8.43211 17.446 8.88488C17.6303 9.11169 17.7725 9.30177 17.8696 9.43676C17.9182 9.50429 17.9556 9.55812 17.9814 9.59599C17.9944 9.61493 18.0044 9.62988 18.0115 9.64056L18.02 9.65335L18.0226 9.65728L18.0238 9.65913ZM3.52413 10.3272C3.42431 10.2043 3.33869 10.094 3.26792 10.0001C3.33869 9.90625 3.42431 9.79598 3.52413 9.67312C3.85939 9.26049 4.35111 8.71048 4.97516 8.16186C6.23396 7.05522 7.96658 6.00977 10 6.00977C12.0334 6.00977 13.766 7.05522 15.0248 8.16186C15.6489 8.71048 16.1406 9.26049 16.4759 9.67312C16.5757 9.79598 16.6613 9.90625 16.7321 10.0001C16.6613 10.094 16.5757 10.2043 16.4759 10.3272C16.1406 10.7398 15.6489 11.2898 15.0248 11.8384C13.766 12.9451 12.0334 13.9905 10 13.9905C7.96658 13.9905 6.23396 12.9451 4.97516 11.8384C4.35111 11.2898 3.85939 10.7398 3.52413 10.3272Z" />
  <path
    fill="currentColor"
    d="M17.5 10.0001L18.0238 9.65913C18.1584 9.86625 18.1584 10.134 18.0238 10.3412L17.5 10.0001Z" />
  <path
    fill="currentColor"
    d="M1.97624 9.65913L2.5 10.0001L1.97597 10.3408C1.84134 10.1336 1.84161 9.86625 1.97624 9.65913Z" />
  <path
    fill="currentColor"
    d="M15 1.25024C14.6548 1.25024 14.375 1.53007 14.375 1.87524C14.375 2.22042 14.6548 2.50024 15 2.50024H16.875C17.2202 2.50024 17.5 2.78007 17.5 3.12524V5.00024C17.5 5.34542 17.7798 5.62524 18.125 5.62524C18.4702 5.62524 18.75 5.34542 18.75 5.00024V3.12524C18.75 2.08971 17.9105 1.25024 16.875 1.25024H15Z" />
  <path
    fill="currentColor"
    d="M1.875 14.375C2.22018 14.375 2.5 14.6548 2.5 15V16.875C2.5 17.2202 2.77982 17.5 3.125 17.5H5C5.34518 17.5 5.625 17.7798 5.625 18.125C5.625 18.4702 5.34518 18.75 5 18.75H3.125C2.08947 18.75 1.25 17.9105 1.25 16.875V15C1.25 14.6548 1.52982 14.375 1.875 14.375Z" />
  <path
    fill="currentColor"
    d="M18.75 15C18.75 14.6548 18.4702 14.375 18.125 14.375C17.7798 14.375 17.5 14.6548 17.5 15V16.875C17.5 17.2202 17.2202 17.5 16.875 17.5H15C14.6548 17.5 14.375 17.7798 14.375 18.125C14.375 18.4702 14.6548 18.75 15 18.75H16.875C17.9105 18.75 18.75 17.9105 18.75 16.875V15Z" />
</template>
