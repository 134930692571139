<template>
  <g clip-path="url(#clip0_2959_23122)">
    <path
      fill="currentColor"
      d="M11.914 7.22667L5.03898 14.1017C4.86207 14.2763 4.62349 14.3742 4.37492 14.3742C4.12634 14.3742 3.88777 14.2763 3.71085 14.1017L0.273355 10.6642C0.0972344 10.488 -0.00170898 10.2492 -0.00170898 10.0001C-0.00170898 9.75103 0.0972344 9.51216 0.273355 9.33604C0.449475 9.15992 0.688345 9.06098 0.937417 9.06098C1.18649 9.06098 1.42536 9.15992 1.60148 9.33604L4.37492 12.1095L10.5859 5.89854C10.762 5.72242 11.0008 5.62348 11.2499 5.62348C11.499 5.62348 11.7379 5.72242 11.914 5.89854C12.0901 6.07466 12.189 6.31353 12.189 6.5626C12.189 6.81168 12.0901 7.05055 11.914 7.22667ZM19.7265 5.89854C19.6394 5.81114 19.5359 5.74179 19.4219 5.69448C19.308 5.64716 19.1858 5.6228 19.0624 5.6228C18.939 5.6228 18.8169 5.64716 18.7029 5.69448C18.5889 5.74179 18.4854 5.81114 18.3984 5.89854L12.1874 12.1095L11.5937 11.5235C11.5103 11.4234 11.407 11.3417 11.2903 11.2837C11.1736 11.2257 11.046 11.1927 10.9159 11.1868C10.7857 11.1808 10.6557 11.2021 10.5342 11.2492C10.4127 11.2963 10.3024 11.3683 10.2102 11.4604C10.1181 11.5525 10.0461 11.6629 9.99903 11.7844C9.95192 11.9059 9.93065 12.0359 9.9366 12.1661C9.94254 12.2962 9.97555 12.4237 10.0335 12.5404C10.0915 12.6572 10.1732 12.7605 10.2734 12.8439L11.5234 14.1017C11.7003 14.2763 11.9388 14.3742 12.1874 14.3742C12.436 14.3742 12.6746 14.2763 12.8515 14.1017L19.7265 7.22667C19.8139 7.13957 19.8832 7.03608 19.9305 6.92212C19.9779 6.80817 20.0022 6.68599 20.0022 6.5626C20.0022 6.43922 19.9779 6.31704 19.9305 6.20309C19.8832 6.08913 19.8139 5.98564 19.7265 5.89854Z" />
  </g>
  <defs>
    <clipPath id="clip0_2959_23122">
      <rect width="20" height="20" fill="white" />
    </clipPath>
  </defs>
</template>
