<template>
  <path
    d="M8 3.5C8.27614 3.5 8.5 3.72386 8.5 4V9C8.5 9.27614 8.27614 9.5 8 9.5C7.72386 9.5 7.5 9.27614 7.5 9V4C7.5 3.72386 7.72386 3.5 8 3.5Z"
    fill="currentColor" />
  <path
    d="M8 10.5C7.58579 10.5 7.25 10.8358 7.25 11.25C7.25 11.6642 7.58579 12 8 12C8.41421 12 8.75 11.6642 8.75 11.25C8.75 10.8358 8.41421 10.5 8 10.5Z"
    fill="currentColor" />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M15 8C15 11.866 11.866 15 8 15C4.13401 15 1 11.866 1 8C1 4.13401 4.13401 1 8 1C11.866 1 15 4.13401 15 8ZM14 8C14 11.3137 11.3137 14 8 14C4.68629 14 2 11.3137 2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8Z"
    fill="currentColor" />
</template>
