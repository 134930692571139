<template>
  <path
    d="M2.5 3C2.77614 3 3 3.22386 3 3.5V13.5C3 13.7761 2.77614 14 2.5 14C2.22386 14 2 13.7761 2 13.5V3.5C2 3.22386 2.22386 3 2.5 3Z"
    fill="#676767" />
  <path
    d="M8.86753 6.13909C8.66821 5.94798 8.35169 5.95463 8.16058 6.15396C7.96946 6.35328 7.97612 6.66979 8.17544 6.86091C8.3804 7.05743 8.87552 7.55243 9.32474 8.00379L9.33142 8.0105H4.52148C4.24534 8.0105 4.02148 8.23436 4.02148 8.5105C4.02148 8.78664 4.24534 9.0105 4.52148 9.0105H9.31033C8.86497 9.45799 8.37819 9.94465 8.17541 10.1391C7.9761 10.3303 7.96948 10.6468 8.16061 10.8461C8.35175 11.0454 8.66826 11.052 8.86756 10.8609C9.083 10.6543 9.58788 10.1493 10.0336 9.70142C10.2576 9.4763 10.4684 9.26383 10.6232 9.10767L10.8107 8.91842C10.9383 8.82782 11.0215 8.67888 11.0215 8.5105L11.0215 8.50527C11.0229 8.37635 10.9747 8.24699 10.8769 8.1482L10.6232 7.89202C10.4684 7.73588 10.2575 7.52344 10.0335 7.29836C9.58785 6.85057 9.08296 6.34565 8.86753 6.13909Z"
    fill="#676767" />
  <path
    d="M13 4.5C13 4.22386 12.7761 4 12.5 4C12.2239 4 12 4.22386 12 4.5V5.5C12 5.77614 12.2239 6 12.5 6C12.7761 6 13 5.77614 13 5.5V4.5Z"
    fill="#676767" />
  <path
    d="M13 7.5C13 7.22386 12.7761 7 12.5 7C12.2239 7 12 7.22386 12 7.5V9.5C12 9.77614 12.2239 10 12.5 10C12.7761 10 13 9.77614 13 9.5V7.5Z"
    fill="#676767" />
  <path
    d="M13 11.5C13 11.2239 12.7761 11 12.5 11C12.2239 11 12 11.2239 12 11.5V12.5C12 12.7761 12.2239 13 12.5 13C12.7761 13 13 12.7761 13 12.5V11.5Z"
    fill="#676767" />
</template>
