<template>
  <path
    fill="currentColor"
    d="M5.5 1.25C4.55022 1.25 3.75 2.00303 3.75 2.96875V10.625C3.75 10.9702 4.02982 11.25 4.375 11.25C4.72018 11.25 5 10.9702 5 10.625V2.96875C5 2.72635 5.20714 2.5 5.5 2.5H14.5C14.7929 2.5 15 2.72635 15 2.96875V10.625C15 10.9702 15.2798 11.25 15.625 11.25C15.9702 11.25 16.25 10.9702 16.25 10.625V2.96875C16.25 2.00303 15.4498 1.25 14.5 1.25H5.5Z" />
  <path
    fill="currentColor"
    d="M13.688 4.23401C13.688 3.88883 13.4082 3.60901 13.063 3.60901C12.7179 3.60901 12.438 3.88883 12.438 4.23401V4.71254C12.3229 4.59007 12.1975 4.47602 12.0627 4.37166C11.4766 3.91777 10.7477 3.6731 9.99996 3.6731C9.25221 3.6731 8.52335 3.91777 7.93718 4.37166C7.35038 4.82604 6.9409 5.46408 6.78709 6.18173C6.71474 6.51924 6.92971 6.85149 7.26722 6.92383C7.60473 6.99618 7.93698 6.78121 8.00932 6.4437C8.09924 6.02422 8.34096 5.63993 8.70248 5.36C9.06464 5.07957 9.52291 4.9231 9.99996 4.9231C10.477 4.9231 10.9353 5.07957 11.2974 5.36C11.4235 5.45759 11.535 5.56787 11.6305 5.6879H10.8687C10.5235 5.6879 10.2437 5.96772 10.2437 6.3129C10.2437 6.65808 10.5235 6.9379 10.8687 6.9379H13.063C13.4082 6.9379 13.688 6.65808 13.688 6.3129V4.23401Z" />
  <path
    fill="currentColor"
    d="M12.5236 10.5105C11.9375 10.9644 11.2086 11.2091 10.4609 11.2091C9.71311 11.2091 8.98425 10.9644 8.39808 10.5105C8.2633 10.4062 8.13788 10.2921 8.02277 10.1697V10.6482C8.02277 10.9934 7.74294 11.2732 7.39777 11.2732C7.05259 11.2732 6.77277 10.9934 6.77277 10.6482V8.5693C6.77277 8.22412 7.05259 7.9443 7.39777 7.9443H9.59215C9.93733 7.9443 10.2172 8.22412 10.2172 8.5693C10.2172 8.91448 9.93733 9.1943 9.59215 9.1943H8.83031C8.92587 9.31433 9.03734 9.42461 9.16338 9.52221C9.52554 9.80263 9.98381 9.95911 10.4609 9.95911C10.9379 9.95911 11.3962 9.80263 11.7583 9.52221C12.1199 9.24227 12.3616 8.85798 12.4515 8.4385C12.5238 8.10099 12.8561 7.88603 13.1936 7.95837C13.5311 8.03071 13.7461 8.36296 13.6737 8.70047C13.5199 9.41812 13.1104 10.0562 12.5236 10.5105Z" />
  <path
    fill="currentColor"
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M6.12011 12.5C6.59571 12.5 6.9905 12.8452 7.20318 13.2706C7.71582 14.2959 8.77569 15 10 15C11.2243 15 12.2842 14.2959 12.7968 13.2706C13.0095 12.8452 13.4043 12.5 13.8799 12.5H17.75C18.3023 12.5 18.75 12.9477 18.75 13.5V17.75C18.75 18.3023 18.3023 18.75 17.75 18.75H2.25C1.69772 18.75 1.25 18.3023 1.25 17.75V13.5C1.25 12.9477 1.69772 12.5 2.25 12.5H6.12011ZM2.5 13.75V17.5H17.5V13.75H13.9664L13.9619 13.7555C13.946 13.7751 13.9295 13.8003 13.9149 13.8296C13.1987 15.262 11.7159 16.25 10 16.25C8.28414 16.25 6.8013 15.262 6.08514 13.8296C6.07048 13.8003 6.05396 13.7751 6.03814 13.7555L6.03361 13.75H2.5Z" />
</template>
