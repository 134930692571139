<template>
  <path
    fill="currentColor"
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M10.0002 4.79199C8.44685 4.79199 7.18765 6.05119 7.18765 7.60449C7.18765 8.88825 8.04775 9.97112 9.22313 10.3083C8.3777 10.4755 7.59294 10.8856 6.97271 11.4974C6.14451 12.3143 5.67764 13.4241 5.67764 14.5831C5.67764 14.9283 5.95746 15.2081 6.30264 15.2081C6.64782 15.2081 6.92764 14.9283 6.92764 14.5831C6.92764 13.7612 7.25858 12.9712 7.8505 12.3873C8.44271 11.8032 9.24771 11.4735 10.0889 11.4735C10.93 11.4735 11.735 11.8032 12.3272 12.3873C12.9192 12.9712 13.2501 13.7612 13.2501 14.5831C13.2501 14.9283 13.5299 15.2081 13.8751 15.2081C14.2203 15.2081 14.5001 14.9283 14.5001 14.5831C14.5001 13.4241 14.0332 12.3143 13.205 11.4974C12.558 10.8592 11.7319 10.4405 10.8447 10.288C11.9854 9.92933 12.8127 8.86352 12.8127 7.60449C12.8127 6.05119 11.5535 4.79199 10.0002 4.79199ZM8.43765 7.60449C8.43765 6.74155 9.13721 6.04199 10.0002 6.04199C10.8631 6.04199 11.5627 6.74155 11.5627 7.60449C11.5627 8.46744 10.8631 9.16699 10.0002 9.16699C9.13721 9.16699 8.43765 8.46744 8.43765 7.60449Z" />
  <path
    fill="currentColor"
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M4.80215 6.15002C3.62855 6.15002 2.67715 7.10142 2.67715 8.27502C2.67715 9.06481 3.10802 9.75397 3.74751 10.1203C3.67038 10.1482 3.59406 10.1787 3.51868 10.2117C2.99105 10.4424 2.52259 10.7884 2.14897 11.2244C1.77531 11.6605 1.50631 12.1751 1.36284 12.7297C1.2764 13.0639 1.47722 13.4049 1.8114 13.4913C2.14558 13.5778 2.48656 13.377 2.57301 13.0428C2.66851 12.6736 2.84785 12.3299 3.09816 12.0378C3.34849 11.7457 3.66334 11.5127 4.0194 11.357C4.37548 11.2013 4.76313 11.1272 5.15302 11.1406C5.38263 11.1484 5.60933 11.1865 5.82722 11.2531C6.1573 11.3541 6.50674 11.1684 6.60772 10.8383C6.7087 10.5082 6.52297 10.1588 6.1929 10.0578C6.13816 10.0411 6.08307 10.0256 6.02764 10.0113C6.57184 9.62646 6.92715 8.99223 6.92715 8.27502C6.92715 7.10142 5.97576 6.15002 4.80215 6.15002ZM3.92715 8.27502C3.92715 7.79178 4.3189 7.40002 4.80215 7.40002C5.2854 7.40002 5.67715 7.79178 5.67715 8.27502C5.67715 8.75827 5.2854 9.15003 4.80215 9.15003C4.3189 9.15003 3.92715 8.75827 3.92715 8.27502Z" />
  <path
    fill="currentColor"
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M15.1975 6.15002C16.3711 6.15002 17.3225 7.10142 17.3225 8.27502C17.3225 9.06481 16.8917 9.75397 16.2522 10.1203C16.3293 10.1482 16.4056 10.1787 16.481 10.2117C17.0086 10.4424 17.4771 10.7884 17.8507 11.2244C18.2244 11.6605 18.4934 12.1751 18.6369 12.7297C18.7233 13.0639 18.5225 13.4049 18.1883 13.4913C17.8541 13.5778 17.5131 13.377 17.4267 13.0428C17.3312 12.6736 17.1518 12.3299 16.9015 12.0378C16.6512 11.7457 16.3364 11.5127 15.9803 11.357C15.6242 11.2013 15.2366 11.1272 14.8467 11.1406C14.6171 11.1484 14.3904 11.1865 14.1725 11.2531C13.8424 11.3541 13.493 11.1684 13.392 10.8383C13.291 10.5082 13.4767 10.1588 13.8068 10.0578C13.8615 10.0411 13.9166 10.0256 13.9721 10.0113C13.4279 9.62646 13.0725 8.99223 13.0725 8.27502C13.0725 7.10142 14.0239 6.15002 15.1975 6.15002ZM16.0725 8.27502C16.0725 7.79177 15.6808 7.40002 15.1975 7.40002C14.7143 7.40002 14.3225 7.79177 14.3225 8.27502C14.3225 8.75827 14.7143 9.15003 15.1975 9.15003C15.6808 9.15003 16.0725 8.75827 16.0725 8.27502Z" />
</template>
