<template>
  <path
    fill="currentColor"
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M6.45567 14.5341C6.58148 14.5341 6.69392 14.4556 6.73729 14.3375L7.77257 11.518H12.2274L13.2627 14.3375C13.306 14.4556 13.4185 14.5341 13.5443 14.5341H14.2985C14.5085 14.5341 14.6535 14.3239 14.579 14.1276L10.8109 4.20651C10.7372 4.01238 10.5512 3.88403 10.3435 3.88403H9.65644C9.44878 3.88403 9.26274 4.01238 9.18902 4.20651L5.421 14.1276C5.34644 14.3239 5.49145 14.5341 5.70145 14.5341H6.45567ZM11.8073 10.3739L10.0578 5.60931C10.0489 5.58504 10.0258 5.56891 9.99998 5.56891C9.97413 5.56891 9.95103 5.58504 9.94212 5.60931L8.19264 10.3739H11.8073Z" />
  <path
    fill="currentColor"
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M10 1.25C5.16751 1.25 1.25 5.16751 1.25 10C1.25 14.8325 5.16751 18.75 10 18.75C14.8325 18.75 18.75 14.8325 18.75 10C18.75 5.16751 14.8325 1.25 10 1.25ZM2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10C17.5 14.1421 14.1421 17.5 10 17.5C5.85786 17.5 2.5 14.1421 2.5 10Z" />
</template>
