<template>
  <path
    fill="currentColor"
    d="M7.71772 10.1154C7.71772 9.60045 7.30184 9.18301 6.78882 9.18301C6.2758 9.18301 5.85991 9.60045 5.85991 10.1154C5.85991 10.6304 6.2758 11.0478 6.78882 11.0478C7.30184 11.0478 7.71772 10.6304 7.71772 10.1154Z" />
  <path
    fill="currentColor"
    d="M13.4768 9.18301C13.9898 9.18301 14.4057 9.60045 14.4057 10.1154C14.4057 10.6304 13.9898 11.0478 13.4768 11.0478C12.9638 11.0478 12.5479 10.6304 12.5479 10.1154C12.5479 9.60045 12.9638 9.18301 13.4768 9.18301Z" />
  <path
    fill="currentColor"
    d="M11.0615 10.1154C11.0615 9.60045 10.6456 9.18301 10.1326 9.18301C9.61955 9.18301 9.20366 9.60045 9.20366 10.1154C9.20366 10.6304 9.61955 11.0478 10.1326 11.0478C10.6456 11.0478 11.0615 10.6304 11.0615 10.1154Z" />
  <path
    fill="currentColor"
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M2.05539 16.7779C1.77705 17.6458 2.59396 18.4772 3.4707 18.1939L6.43487 17.2358C7.55851 17.8325 8.83967 18.1703 10.1979 18.1703C14.6495 18.1703 18.2541 14.5482 18.2541 10.0852C18.2541 5.62214 14.6495 2 10.1979 2C5.74627 2 2.14165 5.62215 2.14165 10.0852C2.14165 11.4087 2.45902 12.6596 3.02203 13.7638L2.05539 16.7779ZM3.31964 16.929L4.37534 13.6372L4.24452 13.4008C3.70134 12.4195 3.39165 11.2896 3.39165 10.0852C3.39165 6.30792 6.4412 3.25 10.1979 3.25C13.9546 3.25 17.0041 6.30792 17.0041 10.0852C17.0041 13.8624 13.9546 16.9203 10.1979 16.9203C8.96447 16.9203 7.80934 16.5914 6.81276 16.0164L6.57233 15.8777L3.31964 16.929Z" />
</template>
