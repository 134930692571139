<template>
  <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.49995 2.99936L14.4999 2.99805C14.7761 2.99802 15 3.22185 15 3.49799C15 3.77413 14.7762 3.99802 14.5001 3.99805L2.50005 3.99936C2.22391 3.99939 2.00003 3.77556 2 3.49942C1.99997 3.22327 2.2238 2.99939 2.49995 2.99936Z"
      fill="currentColor" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.4499 6.00077L14.5 6C14.7761 5.99997 15 6.2238 15.0001 6.49994C15.0001 6.77609 14.7763 6.99997 14.5001 7L7.45001 7.00077C7.17386 7.0008 6.94998 6.77697 6.94995 6.50083C6.94992 6.22468 7.17375 6.0008 7.4499 6.00077Z"
      fill="currentColor" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.4499 12.0027L14.5 12.002C14.7761 12.0019 15 12.2258 15.0001 12.5019C15.0001 12.778 14.7763 13.0019 14.5001 13.002L7.45001 13.0027C7.17386 13.0028 6.94998 12.7789 6.94995 12.5028C6.94992 12.2266 7.17375 12.0028 7.4499 12.0027Z"
      fill="currentColor" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.55414 9.00109L14.4999 9C14.7761 8.99997 15 9.2238 15 9.49994C15 9.77609 14.7762 9.99997 14.5001 10L4.55425 10.0011C4.27811 10.0011 4.05423 9.77729 4.0542 9.50114C4.05417 9.225 4.278 9.00112 4.55414 9.00109Z"
      fill="currentColor" />
  </svg>
</template>
