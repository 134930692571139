<template>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M5.31445 12.5C4.27892 12.5 3.43945 13.3395 3.43945 14.375C3.43945 15.4105 4.27892 16.25 5.31445 16.25C6.34999 16.25 7.18945 15.4105 7.18945 14.375C7.18945 13.3395 6.34999 12.5 5.31445 12.5ZM4.68945 14.375C4.68945 14.0298 4.96928 13.75 5.31445 13.75C5.65963 13.75 5.93945 14.0298 5.93945 14.375C5.93945 14.7202 5.65963 15 5.31445 15C4.96928 15 4.68945 14.7202 4.68945 14.375Z"
    fill="currentColor" />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M3.37695 1.25C2.20335 1.25 1.25195 2.20139 1.25195 3.375V14.6875C1.25195 16.7576 2.80026 18.466 4.80201 18.718C4.86423 18.7388 4.9308 18.75 5 18.75H16.6057C17.7793 18.75 18.7307 17.7986 18.7307 16.625V12.75C18.7307 11.5764 17.7793 10.625 16.6057 10.625H15.5712L17.5001 8.69615C18.3299 7.86629 18.3299 6.52081 17.5001 5.69095L15.1527 3.34357C14.3228 2.51371 12.9774 2.51371 12.1475 3.34358L9.37695 6.11413V3.375C9.37695 2.2014 8.42556 1.25 7.25195 1.25H3.37695ZM13.6677 10.7608C13.6306 10.7903 13.597 10.8239 13.5675 10.861L9.35926 15.0692C9.37097 14.9436 9.37695 14.8162 9.37695 14.6875V7.8819L13.0314 4.22746C13.3731 3.88575 13.9271 3.88575 14.2688 4.22746L16.6162 6.57483C16.9579 6.91654 16.9579 7.47056 16.6162 7.81227L13.6677 10.7608ZM14.3212 11.875H16.6057C17.089 11.875 17.4807 12.2668 17.4807 12.75V16.625C17.4807 17.1082 17.089 17.5 16.6057 17.5H8.69624L14.3212 11.875ZM5.31445 17.5C6.86775 17.5 8.12695 16.2408 8.12695 14.6875V3.375C8.12695 2.89175 7.7352 2.5 7.25195 2.5H3.37695C2.8937 2.5 2.50195 2.89175 2.50195 3.375V14.6875C2.50195 16.2408 3.76115 17.5 5.31445 17.5Z"
    fill="currentColor" />
</template>
