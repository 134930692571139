<template>
  <path
    fill="currentColor"
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M5.62476 1.25C5.96993 1.25 6.24976 1.52982 6.24976 1.875V2.5H11.2498V1.875C11.2498 1.52982 11.5296 1.25 11.8748 1.25C12.2199 1.25 12.4998 1.52982 12.4998 1.875V2.5H14.3748C15.4103 2.5 16.2498 3.33947 16.2498 4.375V8.4358C16.2498 8.78098 15.9699 9.0608 15.6248 9.0608C15.2796 9.0608 14.9998 8.78098 14.9998 8.4358V7.5H2.49976V13.125C2.49976 13.4702 2.77958 13.75 3.12476 13.75H7.49976C7.84493 13.75 8.12476 14.0298 8.12476 14.375C8.12476 14.7202 7.84493 15 7.49976 15H3.12476C2.08922 15 1.24976 14.1605 1.24976 13.125V4.375C1.24976 3.33947 2.08922 2.5 3.12476 2.5H4.99976V1.875C4.99976 1.52982 5.27958 1.25 5.62476 1.25ZM14.9998 4.375V6.25H2.49976V4.375C2.49976 4.02982 2.77958 3.75 3.12476 3.75H4.99976V4.375C4.99976 4.72018 5.27958 5 5.62476 5C5.96993 5 6.24976 4.72018 6.24976 4.375V3.75H11.2498V4.375C11.2498 4.72018 11.5296 5 11.8748 5C12.2199 5 12.4998 4.72018 12.4998 4.375V3.75H14.3748C14.7199 3.75 14.9998 4.02982 14.9998 4.375Z" />
  <path
    fill="currentColor"
    d="M13.9998 11.875C14.3449 11.875 14.6248 12.1548 14.6248 12.5V13.75H15.8748C16.2199 13.75 16.4998 14.0298 16.4998 14.375C16.4998 14.7202 16.2199 15 15.8748 15H13.9998C13.6546 15 13.3748 14.7202 13.3748 14.375V12.5C13.3748 12.1548 13.6546 11.875 13.9998 11.875Z" />
  <path
    fill="currentColor"
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M9.37476 14.1322C9.37476 11.5049 11.5046 9.375 14.132 9.375C16.7593 9.375 18.8892 11.5049 18.8892 14.1322C18.8892 16.7596 16.7593 18.8894 14.132 18.8894C11.5046 18.8894 9.37476 16.7596 9.37476 14.1322ZM14.132 10.625C12.195 10.625 10.6248 12.1952 10.6248 14.1322C10.6248 16.0692 12.195 17.6394 14.132 17.6394C16.069 17.6394 17.6392 16.0692 17.6392 14.1322C17.6392 12.1952 16.069 10.625 14.132 10.625Z" />
  <path
    fill="currentColor"
    d="M4.37476 8.75C4.02958 8.75 3.74976 9.02982 3.74976 9.375C3.74976 9.72018 4.02958 10 4.37476 10H5.60408C5.94926 10 6.22908 9.72018 6.22908 9.375C6.22908 9.02982 5.94926 8.75 5.60408 8.75H4.37476Z" />
  <path
    fill="currentColor"
    d="M3.74976 11.875C3.74976 11.5298 4.02958 11.25 4.37476 11.25H5.60408C5.94926 11.25 6.22908 11.5298 6.22908 11.875C6.22908 12.2202 5.94926 12.5 5.60408 12.5H4.37476C4.02958 12.5 3.74976 12.2202 3.74976 11.875Z" />
  <path
    fill="currentColor"
    d="M8.13508 8.75C7.78991 8.75 7.51008 9.02982 7.51008 9.375C7.51008 9.72018 7.78991 10 8.13508 10H9.36441C9.70959 10 9.98941 9.72018 9.98941 9.375C9.98941 9.02982 9.70959 8.75 9.36441 8.75H8.13508Z" />
</template>
