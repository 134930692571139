<script lang="ts" setup>
import { inject } from 'vue';

type Props = {
  id?: string;
  gap?: number;
};
const prefix = inject('prefixId');

withDefaults(defineProps<Props>(), {
  id: '',
});
</script>

<template>
  <div :id="`${prefix}-${id}`">
    <div class="hidden">
      <slot name="label"></slot>
    </div>
    <div :style="{ 'margin-bottom': gap + 'px' }">
      <slot name="content"></slot>
    </div>
  </div>
</template>
