<script lang="ts" setup>
import type { Thumbnail } from '../types/image';

import GLoadingPlaceholder from '../base/GLoadingPlaceholder.vue';
import GButton from '../base/GButton.vue';
import GBaseIcon from '../base/GBaseIcon.vue';
import GImage from '../base/GImage.vue';

const VELOCITY = 0.3;

defineProps<{
  isLoading?: boolean;
  isPreview?: boolean;
  section: {
    name: string;
    thumbnail: Thumbnail;
  };
  isSelectLoading?: boolean;
  isPreviewLoading?: boolean;
}>();

// Emit
const emit = defineEmits<{
  (e: 'select'): void;
  (e: 'preview'): void;
}>();

const handleSelect = () => {
  emit('select');
};
const handlePreview = () => {
  emit('preview');
};
</script>
<template>
  <div
    class="rounded-medium z-5 bg-light-100 group relative !my-0 h-auto w-full overflow-hidden transition-all delay-[0.25s]">
    <g-image
      input-classes="rounded-t-medium pt-[calc(100%+68px)] w-full cursor-pointer overflow-hidden"
      cls-image="absolute top-0 left-0 w-full"
      width="100%"
      :src="section.thumbnail.src"
      :origin-width="section.thumbnail.width"
      :origin-height="section.thumbnail.height"
      :velocity="VELOCITY" />

    <div v-if="isLoading" class="absolute inset-0 flex items-center justify-center bg-white">
      <g-loading-placeholder width="100%" height="100%"></g-loading-placeholder>
    </div>

    <div
      v-if="!isLoading"
      class="bg-light-200 group bottom-[-48px] left-0 flex h-[50px] w-full items-center overflow-hidden border-t py-8 px-12 transition-all duration-200 group-hover:bottom-0">
      <div class="flex h-full max-w-full items-center group-hover:hidden">
        <div class="text-light-high text-14 inline-block max-w-full truncate align-middle font-medium">
          {{ section.name }}
        </div>
      </div>

      <g-button
        v-if="isPreview"
        :loading="isPreviewLoading"
        type="grey"
        size="medium"
        :only-icon="true"
        @click.stop="handlePreview">
        <template #prefix>
          <g-base-icon
            name="library-eye-preview"
            class="text-light-high"
            width="16px"
            height="16px"
            view-box="0 0 16 16"></g-base-icon>
        </template>
      </g-button>
      <g-button
        class="hidden w-full group-hover:flex"
        button-classes="justify-center w-full"
        type="primary"
        size="medium"
        :loading="isSelectLoading"
        @click.stop="handleSelect"
        >Add to page</g-button
      >
    </div>
  </div>
</template>
