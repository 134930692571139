<template>
  <rect x="0.5" y="0.5" width="27" height="27" rx="2.5" fill="url(#pattern0)" stroke="#D6D6D6" />
  <defs>
    <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
      <use xlink:href="#image0_7087_130975" transform="translate(-0.821429 -0.142857) scale(0.0357143)" />
    </pattern>
    <image
      id="image0_7087_130975"
      width="66"
      height="35"
      xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEIAAAAjCAYAAAAg/NwXAAABYUlEQVRoge3ZzUrDQBSG4a+Jq4qtP1NFN2KtWHeC4G0oXkIpvRSpRfBObAtuvAVBcJd2aVGkZKqYuMyPC09EqRm6sJmQnGfX5EBmXtJASOH65jZERpmmgfWKQL1WxXK5pJwthGGY2RCe52H0/ALX/UCtuo3FYjF2NtMhImNbwnFc7O3uxM4YCa5HG7G6gnfHVc7kIoRpmgiCQDmTixCz4BCEQxAOQTgEWdB58fN2B7ac4Ory4vu3NRgCAIQQv47/nJsHrSGiTUeEEAC+jlXEWuzcPGgNEbEGQxzU99FqNtBqNqbOJSEVz4hur//nhqWU6Pb6iawhNXeE1e5oXUMq7og04BCEQxAOQTgE4RCEQxAOQTgE4RBEa4iz05OZ5oQQUy9j/y0X3zUA4O7+AcdHh7Hn+a9BOAThEIRDEA5BchHC930YhnqruQghX99QLi0pZzIdwvd9jG2Jx9ETtjY3lLOfU+ZnWobFUewAAAAASUVORK5CYII=" />
  </defs>
</template>
