<template>
  <path
    d="M3.5 4C3.5 3.72386 3.72386 3.5 4 3.5H5C5.27614 3.5 5.5 3.72386 5.5 4C5.5 4.27614 5.27614 4.5 5 4.5H4C3.72386 4.5 3.5 4.27614 3.5 4Z"
    fill="#676767" />
  <path
    d="M6.5 4C6.5 3.72386 6.72386 3.5 7 3.5H9C9.27614 3.5 9.5 3.72386 9.5 4C9.5 4.27614 9.27614 4.5 9 4.5H7C6.72386 4.5 6.5 4.27614 6.5 4Z"
    fill="#676767" />
  <path
    d="M10.5 4C10.5 3.72386 10.7239 3.5 11 3.5H12C12.2761 3.5 12.5 3.72386 12.5 4C12.5 4.27614 12.2761 4.5 12 4.5H11C10.7239 4.5 10.5 4.27614 10.5 4Z"
    fill="#676767" />
  <path
    d="M8.00556 5.50003L8.01074 5.5C8.17902 5.5 8.32788 5.58313 8.4185 5.71056L8.60798 5.89831C8.76412 6.05311 8.97656 6.26394 9.20164 6.48796C9.64943 6.93364 10.1543 7.43852 10.3609 7.65396C10.552 7.85328 10.5454 8.16979 10.346 8.36091C10.1467 8.55202 9.83021 8.54537 9.63909 8.34604C9.44468 8.14328 8.95815 7.65655 8.51074 7.2112L8.51074 12C8.51074 12.2761 8.28688 12.5 8.01074 12.5C7.7346 12.5 7.51074 12.2761 7.51074 12L7.51074 7.19001L7.50396 7.19676C7.05253 7.64599 6.55745 8.1411 6.36087 8.34608C6.16974 8.54538 5.85322 8.55201 5.65392 8.36087C5.45462 8.16974 5.44799 7.85322 5.63913 7.65392C5.84573 7.43848 6.35072 6.9336 6.79858 6.48793C7.0237 6.26391 7.23617 6.05308 7.39233 5.89828L7.64825 5.64481C7.74703 5.5471 7.87666 5.49864 8.00556 5.50003Z"
    fill="#676767" />
  <path
    d="M3.5 13.5C3.22386 13.5 3 13.7239 3 14C3 14.2761 3.22386 14.5 3.5 14.5H12.5C12.7761 14.5 13 14.2761 13 14C13 13.7239 12.7761 13.5 12.5 13.5H3.5Z"
    fill="#676767" />
</template>
