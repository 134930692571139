<template>
  <path
    fill="currentColor"
    d="M7.13388 8.75L8.56694 7.31694C8.81102 7.07286 8.81102 6.67714 8.56694 6.43306C8.32286 6.18898 7.92714 6.18898 7.68306 6.43306L5.18306 8.93306C4.93898 9.17714 4.93898 9.57286 5.18306 9.81694L7.68306 12.3169C7.92714 12.561 8.32286 12.561 8.56694 12.3169C8.81102 12.0729 8.81102 11.6771 8.56694 11.4331L7.13388 10H12.5C13.1904 10 13.75 10.5596 13.75 11.25C13.75 11.9404 13.1904 12.5 12.5 12.5H11.875C11.5298 12.5 11.25 12.7798 11.25 13.125C11.25 13.4702 11.5298 13.75 11.875 13.75H12.5C13.8807 13.75 15 12.6307 15 11.25C15 9.86929 13.8807 8.75 12.5 8.75H7.13388Z" />
  <path
    fill="currentColor"
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M1.25 10C1.25 5.16751 5.16751 1.25 10 1.25C14.8325 1.25 18.75 5.16751 18.75 10C18.75 14.8325 14.8325 18.75 10 18.75C5.16751 18.75 1.25 14.8325 1.25 10ZM10 2.5C5.85786 2.5 2.5 5.85786 2.5 10C2.5 14.1421 5.85786 17.5 10 17.5C14.1421 17.5 17.5 14.1421 17.5 10C17.5 5.85786 14.1421 2.5 10 2.5Z" />
</template>
