<template>
  <rect width="90" height="48" rx="3" fill="#323232" />
  <rect x="9.5" y="9.5" width="31" height="29" rx="2.5" fill="#525252" />
  <path
    d="M17 18.8922L22.328 17.0308C22.328 17.0308 22.9759 18.5486 25.0053 18.5486C27.0348 18.5486 27.6755 17 27.6755 17L33 18.8922L32.1138 23.5595H29.4418V32H20.5467V23.5567H17.888L17 18.8922Z"
    fill="#F9F9F9" />
  <rect x="9.5" y="9.5" width="31" height="29" rx="2.5" stroke="#5B5B5B" />
  <rect x="49.5" y="9.5" width="31" height="29" rx="2.5" fill="#525252" />
  <path
    d="M57 18.8922L62.328 17.0308C62.328 17.0308 62.9759 18.5486 65.0053 18.5486C67.0348 18.5486 67.6755 17 67.6755 17L73 18.8922L72.1138 23.5595H69.4418V32H60.5467V23.5567H57.888L57 18.8922Z"
    fill="#F9F9F9" />
  <rect x="49.5" y="9.5" width="31" height="29" rx="2.5" stroke="#5B5B5B" />
</template>
