<template>
  <g clip-path="url(#clip0_1028_30728)">
    <path
      d="M5.5 7C5.23478 7 4.98043 6.89464 4.79289 6.70711C4.60536 6.51957 4.5 6.26522 4.5 6C4.5 5.73478 4.60536 5.48043 4.79289 5.29289C4.98043 5.10536 5.23478 5 5.5 5H27.5C27.7652 5 28.0196 5.10536 28.2071 5.29289C28.3946 5.48043 28.5 5.73478 28.5 6C28.5 6.26522 28.3946 6.51957 28.2071 6.70711C28.0196 6.89464 27.7652 7 27.5 7H5.5ZM9.5 3C9.23478 3 8.98043 2.89464 8.79289 2.70711C8.60536 2.51957 8.5 2.26522 8.5 2C8.5 1.73478 8.60536 1.48043 8.79289 1.29289C8.98043 1.10536 9.23478 1 9.5 1H23.5C23.7652 1 24.0196 1.10536 24.2071 1.29289C24.3946 1.48043 24.5 1.73478 24.5 2C24.5 2.26522 24.3946 2.51957 24.2071 2.70711C24.0196 2.89464 23.7652 3 23.5 3H9.5ZM0.5 26C0.5 26.7956 0.81607 27.5587 1.37868 28.1213C1.94129 28.6839 2.70435 29 3.5 29H29.5C30.2956 29 31.0587 28.6839 31.6213 28.1213C32.1839 27.5587 32.5 26.7956 32.5 26V12C32.5 11.2044 32.1839 10.4413 31.6213 9.87868C31.0587 9.31607 30.2956 9 29.5 9H3.5C2.70435 9 1.94129 9.31607 1.37868 9.87868C0.81607 10.4413 0.5 11.2044 0.5 12V26ZM3.5 27C3.23478 27 2.98043 26.8946 2.79289 26.7071C2.60536 26.5196 2.5 26.2652 2.5 26V12C2.5 11.7348 2.60536 11.4804 2.79289 11.2929C2.98043 11.1054 3.23478 11 3.5 11H29.5C29.7652 11 30.0196 11.1054 30.2071 11.2929C30.3946 11.4804 30.5 11.7348 30.5 12V26C30.5 26.2652 30.3946 26.5196 30.2071 26.7071C30.0196 26.8946 29.7652 27 29.5 27H3.5Z"
      fill="black" />
  </g>
  <defs>
    <clipPath id="clip0_1028_30728">
      <rect width="32" height="32" fill="white" transform="translate(0.5)" />
    </clipPath>
  </defs>
</template>
