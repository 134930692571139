<script lang="ts" setup>
import type { Ref } from 'vue';
import { nextTick, onMounted, reactive, ref } from 'vue';
import { useOutsideClick } from '../composables/useOutsideClick';

type Props = {
  closeable?: boolean;
  wrapperClass?: string;
  buttonClass?: string;
  arrowClass?: string;
  overlayContainer?: string;
  overlay?: boolean;
  overlayClass?: string;
};

type Emit = {
  (e: 'close'): void;
};

type DOMSize = {
  width?: number;
  height?: number;
  top?: number;
  left?: number;
  bottom?: number;
  right?: number;
  x?: number;
  y?: number;
};

type PositionInfo = {
  isDisplayBottom: boolean;
  position: number;
  isFixed: boolean;
};

type SizeAttribute = 'top' | 'bottom' | 'left' | 'right' | 'width' | 'height';

const props = withDefaults(defineProps<Props>(), {
  overlayContainer: 'body',
  overlayClass: 'bg-dark-500/80',
});

const emit = defineEmits<Emit>();

const open = ref(false);
const isLoaded = ref(false);
const button = ref<HTMLDivElement>();
const content = ref<HTMLDivElement>();
const wrapper = ref<HTMLDivElement>();
const sideBarSize: Ref<DOMSize> = ref({});
const buttonSize: Ref<DOMSize> = ref({});
const wrapperSize: Ref<DOMSize> = ref({});
const position: PositionInfo = reactive({ isDisplayBottom: true, position: 0, isFixed: false });
const isCheckedPopupPosition: Ref<boolean> = ref(false);
const contentHeight: Ref<number> = ref(0);

const onOpen = async () => {
  await calculatePopupPosition();
  togglePopup();
};

const calculatePopupPosition = async () => {
  getSizeBarSize();
  getButtonSize();
  await checkPopupPosition();
};

const getSizeBarSize = () => {
  const CLASS_SIZE_BAR = '.gemx-controls';
  const $sizeBar = document.querySelector(CLASS_SIZE_BAR);
  if (!$sizeBar) {
    sideBarSize.value = {};
    return;
  }
  sideBarSize.value = $sizeBar.getBoundingClientRect();
};

const getButtonSize = () => {
  buttonSize.value = button.value?.getBoundingClientRect() || {};
};

const checkPopupPosition = async () => {
  await togglePopupToGetContentAndWrapperSize();
  const sideBarBottom = getAttributeOfSideBar('bottom');
  const buttonBottom = getAttributeOfButton('bottom');
  if (sideBarBottom - buttonBottom < contentHeight.value) {
    checkPositionDisplayOnTop();
  } else {
    checkPositionDisplayOnBottom();
  }
  isCheckedPopupPosition.value = true;
};

const togglePopupToGetContentAndWrapperSize = async () => {
  open.value = true;
  await nextTick();
  getContentHeight();
  getWrapperSize();
  open.value = false;
};

const getContentHeight = () => {
  contentHeight.value = content.value?.getBoundingClientRect()?.height || 0;
};

const getWrapperSize = () => {
  wrapperSize.value = wrapper.value?.getBoundingClientRect() || {};
};

const checkPositionDisplayOnTop = () => {
  position.isDisplayBottom = false;
  const buttonTop = getAttributeOfButton('top');
  const sideBarTop = getAttributeOfSideBar('top');
  if (buttonTop - sideBarTop >= contentHeight.value) {
    setPositionDisplayOnTop();
  } else {
    setPositionDisplayFixedOnTop();
  }
};

const setPositionDisplayOnTop = () => {
  const wrapperBottom = getAttributeOfWrapper('bottom');
  const buttonTop = getAttributeOfButton('top');
  position.isFixed = false;
  position.position = wrapperBottom - buttonTop;
};

const setPositionDisplayFixedOnTop = () => {
  const MARGIN_TOP_DEFAULT = 8;
  const sideBarHeight = getAttributeOfSideBar('height');
  const positionAtMiddle = sideBarHeight / 2 - contentHeight.value / 2;
  const positionAtTop = sideBarHeight - contentHeight.value - MARGIN_TOP_DEFAULT;
  position.isFixed = true;
  position.position = Math.min(positionAtMiddle, positionAtTop);
};

const checkPositionDisplayOnBottom = () => {
  const buttonBottom = getAttributeOfButton('bottom');
  const sideBarTop = getAttributeOfSideBar('top');
  position.isDisplayBottom = true;
  position.isFixed = false;
  position.position = buttonBottom - sideBarTop;
};

const getAttributeOfButton = (attribute: SizeAttribute) => {
  return buttonSize.value[attribute] || 0;
};

const getAttributeOfSideBar = (attribute: SizeAttribute) => {
  return sideBarSize.value[attribute] || 0;
};

const getAttributeOfWrapper = (attribute: SizeAttribute) => {
  return wrapperSize.value[attribute] || 0;
};

const togglePopup = () => {
  if (!open.value) {
    openPopup();
  } else if (props.closeable && open.value) {
    closePopup();
  }
};

const openPopup = () => {
  open.value = true;
};

const closePopup = () => {
  open.value = false;
  resetStateCheckPopupPosition();
  emit('close');
};

const resetStateCheckPopupPosition = () => {
  position.position = 0;
  position.isDisplayBottom = true;
  position.isFixed = false;
  isCheckedPopupPosition.value = false;
};

const renderStyle = () => {
  if (!isCheckedPopupPosition.value) {
    return {};
  }
  if (position.isDisplayBottom) {
    return { top: `${Number(position.position)}px` };
  }
  return { bottom: `${Number(position.position)}px` };
};

const handleClickOutSide = () => {
  if (props.closeable && open.value) {
    closePopup();
  }
};

useOutsideClick(content, handleClickOutSide, { detectIframe: true });

onMounted(() => {
  isLoaded.value = true;
});
</script>

<template>
  <div class="relative">
    <div ref="button" class="relative !h-36 w-36" :class="buttonClass" @click.prevent.stop="onOpen">
      <slot v-bind="{ open, closePopup }"></slot>
    </div>
    <Teleport :to="overlayContainer">
      <div v-if="open" class="absolute inset-0 z-20">
        <div v-if="overlay && isLoaded" :class="overlayClass" class="absolute inset-0 z-30"></div>
        <div ref="wrapper" class="relative h-full w-full pt-40">
          <perfect-scrollbar class="">
            <div
              ref="content"
              :class="[
                position.isDisplayBottom ? 'mt-8' : '',
                !position.isDisplayBottom && !position.isFixed ? 'mb-8' : '',
                wrapperClass,
              ]"
              class="rounded-large shadow-4dp absolute right-8 z-50 bg-white"
              :style="renderStyle()">
              <svg
                v-if="position.isDisplayBottom || (!position.isDisplayBottom && !position.isFixed)"
                viewBox="0 0 213.3 213.3"
                class="text-dark-300 absolute right-16 h-16 w-16 transform"
                :class="[
                  arrowClass,
                  { '-bottom-12 rotate-0': !position.isDisplayBottom, '-mt-12 rotate-180': position.isDisplayBottom },
                ]"
                fill="currentColor">
                <polygon class="shadow-4dp" points="0,53.333 106.667,160 213.333,53.333"></polygon>
              </svg>
              <div>
                <slot name="content" v-bind="{ open, closePopup }"></slot>
              </div>
            </div>
          </perfect-scrollbar>
        </div>
      </div>
    </Teleport>
  </div>
</template>
