export default function removeWWWOrHttps(domain: string) {
  const removeList = ['www.', 'http://', 'https://'];
  for (let i = 0; i < removeList.length; i++) {
    const removeString = removeList[i];
    if (domain.startsWith(removeString)) {
      return domain.substring(removeString.length, domain.length);
    }
  }
  return domain;
}
