<template>
  <path
    fill="currentColor"
    d="M11.375 10.625C11.0298 10.625 10.75 10.9048 10.75 11.25C10.75 11.5952 11.0298 11.875 11.375 11.875H14.5C14.8452 11.875 15.125 11.5952 15.125 11.25C15.125 10.9048 14.8452 10.625 14.5 10.625H11.375Z" />
  <path
    fill="currentColor"
    d="M10.75 13.4375C10.75 13.0923 11.0298 12.8125 11.375 12.8125H13.25C13.5952 12.8125 13.875 13.0923 13.875 13.4375C13.875 13.7827 13.5952 14.0625 13.25 14.0625H11.375C11.0298 14.0625 10.75 13.7827 10.75 13.4375Z" />
  <path
    fill="currentColor"
    d="M5.25 10.625C4.90482 10.625 4.625 10.9048 4.625 11.25C4.625 11.5952 4.90482 11.875 5.25 11.875H8.375C8.72018 11.875 9 11.5952 9 11.25C9 10.9048 8.72018 10.625 8.375 10.625H5.25Z" />
  <path
    fill="currentColor"
    d="M4.625 13.4375C4.625 13.0923 4.90482 12.8125 5.25 12.8125H8.375C8.72018 12.8125 9 13.0923 9 13.4375C9 13.7827 8.72018 14.0625 8.375 14.0625H5.25C4.90482 14.0625 4.625 13.7827 4.625 13.4375Z" />
  <path
    fill="currentColor"
    d="M5.25 15C4.90482 15 4.625 15.2798 4.625 15.625C4.625 15.9702 4.90482 16.25 5.25 16.25H8.375C8.72018 16.25 9 15.9702 9 15.625C9 15.2798 8.72018 15 8.375 15H5.25Z" />
  <path
    fill="currentColor"
    d="M10.75 15.625C10.75 15.2798 11.0298 15 11.375 15H14.5C14.8452 15 15.125 15.2798 15.125 15.625C15.125 15.9702 14.8452 16.25 14.5 16.25H11.375C11.0298 16.25 10.75 15.9702 10.75 15.625Z" />
  <path
    fill="currentColor"
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M5.5 3.75098C4.87868 3.75098 4.375 4.25466 4.375 4.87598V8.25098C4.375 8.8723 4.87868 9.37598 5.5 9.37598H14.5C15.1213 9.37598 15.625 8.8723 15.625 8.25098V4.87598C15.625 4.25466 15.1213 3.75098 14.5 3.75098H5.5ZM5.625 8.12598V5.00098H14.375V8.12598H5.625Z" />
  <path
    fill="currentColor"
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M3.75 1.24976C2.71447 1.24976 1.875 2.08922 1.875 3.12476V16.8748C1.875 17.9103 2.71447 18.7498 3.75 18.7498H16.25C17.2855 18.7498 18.125 17.9103 18.125 16.8748V3.12476C18.125 2.08922 17.2855 1.24976 16.25 1.24976H3.75ZM3.125 3.12476C3.125 2.77958 3.40482 2.49976 3.75 2.49976H16.25C16.5952 2.49976 16.875 2.77958 16.875 3.12476V16.8748C16.875 17.2199 16.5952 17.4998 16.25 17.4998H3.75C3.40482 17.4998 3.125 17.2199 3.125 16.8748V3.12476Z" />
</template>
