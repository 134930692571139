<template>
  <path
    fill="currentColor"
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M12.6517 5.00338C11.1872 3.53891 8.81281 3.53891 7.34835 5.00338C5.88388 6.46784 5.88388 8.84221 7.34835 10.3067C8.81281 11.7711 11.1872 11.7711 12.6517 10.3067C14.1161 8.84221 14.1161 6.46784 12.6517 5.00338ZM11.7678 5.88726C10.7915 4.91095 9.20854 4.91095 8.23223 5.88726C7.25592 6.86357 7.25592 8.44649 8.23223 9.4228C9.20854 10.3991 10.7915 10.3991 11.7678 9.4228C12.7441 8.44649 12.7441 6.86357 11.7678 5.88726Z" />
  <path
    fill="currentColor"
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M3.81282 3.81282C7.22991 0.395728 12.7701 0.395728 16.1872 3.81282C19.6043 7.22991 19.6043 12.7701 16.1872 16.1872C12.7701 19.6043 7.22991 19.6043 3.81282 16.1872C0.395728 12.7701 0.395728 7.22991 3.81282 3.81282ZM4.6967 4.6967C7.62563 1.76777 12.3744 1.76777 15.3033 4.6967C18.1821 7.57548 18.2314 12.2123 15.4512 15.1513C12.4153 12.2147 7.58912 12.2109 4.54851 15.1509C1.76863 12.2119 1.81803 7.57537 4.6967 4.6967ZM5.48218 15.9877C8.0198 13.599 11.9836 13.6021 14.5175 15.988C11.8521 18.0041 8.14746 18.004 5.48218 15.9877Z" />
</template>
