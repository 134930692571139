<template>
  <path
    d="M13.5 3C13.7761 3 14 3.22386 14 3.5V13.5C14 13.7761 13.7761 14 13.5 14C13.2239 14 13 13.7761 13 13.5V3.5C13 3.22386 13.2239 3 13.5 3Z"
    fill="#676767" />
  <path
    d="M7.84599 6.86091C8.04531 6.66979 8.05196 6.35328 7.86085 6.15396C7.66973 5.95463 7.35322 5.94798 7.1539 6.13909C6.93847 6.34565 6.43358 6.85057 5.98791 7.29836C5.76388 7.52344 5.55306 7.73588 5.39825 7.89202L5.14478 8.1479C5.02972 8.26421 4.98305 8.42318 5.00539 8.57363C5.01237 8.62067 5.02606 8.6669 5.04647 8.71078C5.05645 8.73224 5.06804 8.75313 5.08123 8.77329C5.10124 8.80389 5.12452 8.83217 5.15056 8.85762L5.39822 9.10767C5.55303 9.26383 5.76385 9.4763 5.98787 9.70142C6.43355 10.1493 6.93843 10.6543 7.15386 10.8609C7.35317 11.052 7.66968 11.0454 7.86082 10.8461C8.05195 10.6468 8.04533 10.3303 7.84602 10.1391C7.64165 9.94313 7.14882 9.45036 6.70065 9L11.5 9C11.7761 9 12 8.77614 12 8.5C12 8.22386 11.7761 8 11.5 8L6.70046 8C7.14868 7.54966 7.6416 7.05688 7.84599 6.86091Z"
    fill="#676767" />
  <path
    d="M4 4.5C4 4.22386 3.77614 4 3.5 4C3.22386 4 3 4.22386 3 4.5V5.5C3 5.77614 3.22386 6 3.5 6C3.77614 6 4 5.77614 4 5.5V4.5Z"
    fill="#676767" />
  <path
    d="M4 7.5C4 7.22386 3.77614 7 3.5 7C3.22386 7 3 7.22386 3 7.5V9.5C3 9.77614 3.22386 10 3.5 10C3.77614 10 4 9.77614 4 9.5L4 7.5Z"
    fill="#676767" />
  <path
    d="M4 11.5C4 11.2239 3.77614 11 3.5 11C3.22386 11 3 11.2239 3 11.5V12.5C3 12.7761 3.22386 13 3.5 13C3.77614 13 4 12.7761 4 12.5V11.5Z"
    fill="#676767" />
</template>
