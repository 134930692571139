<template>
  <path
    d="M8 2.5C6.61934 2.5 5.5 3.61948 5.5 5.00025L5.5 7C5.5 7.27615 5.27614 7.5 5 7.5C4.72385 7.5 4.5 7.27614 4.5 7L4.5 5.00025C4.5 3.0673 6.06695 1.5 8 1.5C9.93305 1.5 11.5 3.06729 11.5 5.00023L11.5 7C11.5 7.27615 11.2761 7.5 11 7.5C10.7239 7.5 10.5 7.27614 10.5 7L10.5 5.00023C10.5 3.61947 9.38066 2.5 8 2.5Z"
    fill="#E2E2E2" />
  <path
    d="M5.00001 9.5C5.27615 9.5 5.50001 9.72386 5.5 10L5.5 11.9998C5.5 13.3805 6.61934 14.5 8 14.5C9.38066 14.5 10.5 13.3805 10.5 11.9998L10.5 10C10.5 9.72385 10.7239 9.5 11 9.5C11.2761 9.5 11.5 9.72386 11.5 10L11.5 11.9998C11.5 13.9327 9.93305 15.5 8 15.5C6.06695 15.5 4.5 13.9327 4.5 11.9998L4.5 10C4.50001 9.72385 4.72386 9.5 5.00001 9.5Z"
    fill="#E2E2E2" />
  <path
    d="M7.5 11.5C7.5 11.7761 7.72386 12 8 12C8.27614 12 8.5 11.7761 8.5 11.5L8.5 5.5C8.5 5.22386 8.27614 5 8 5C7.72386 5 7.5 5.22386 7.5 5.5L7.5 11.5Z"
    fill="#E2E2E2" />
</template>
