<template>
  <path
    fill="currentColor"
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M3.75544 1.25C4.10062 1.25 4.38044 1.52982 4.38044 1.875L4.38044 8.2957C5.45538 8.5754 6.24874 9.55282 6.24874 10.7153C6.24874 11.8778 5.45538 12.8553 4.38044 13.135L4.38044 18.125C4.38044 18.4702 4.10062 18.75 3.75544 18.75C3.41026 18.75 3.13044 18.4702 3.13044 18.125L3.13044 13.1381C2.04934 12.8626 1.25 11.8822 1.25 10.7153C1.25 9.54845 2.04934 8.56806 3.13044 8.29257L3.13044 1.875C3.13044 1.52982 3.41026 1.25 3.75544 1.25ZM2.5 10.7153C2.5 10.0248 3.05956 9.46533 3.74937 9.46533C4.43918 9.46533 4.99874 10.0248 4.99874 10.7153C4.99874 11.4059 4.43918 11.9653 3.74937 11.9653C3.05956 11.9653 2.5 11.4059 2.5 10.7153Z" />
  <path
    fill="currentColor"
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M10.3779 1.25C10.7231 1.25 11.0029 1.52982 11.0029 1.875V5.26184C12.0791 5.54068 12.8737 6.51871 12.8737 7.68213C12.8737 8.84555 12.0791 9.82358 11.0029 10.1024V18.125C11.0029 18.4702 10.7231 18.75 10.3779 18.75C10.0328 18.75 9.75293 18.4702 9.75293 18.125V10.1042C8.6731 9.82789 7.875 8.84811 7.875 7.68213C7.875 6.51615 8.6731 5.53637 9.75293 5.26001V1.875C9.75293 1.52982 10.0328 1.25 10.3779 1.25ZM10.3744 6.43213C9.68456 6.43213 9.125 6.99158 9.125 7.68213C9.125 8.37268 9.68456 8.93213 10.3744 8.93213C11.0642 8.93213 11.6237 8.37268 11.6237 7.68213C11.6237 6.99158 11.0642 6.43213 10.3744 6.43213Z" />
  <path
    fill="currentColor"
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M16.8765 1.875C16.8765 1.52982 16.5966 1.25 16.2515 1.25C15.9063 1.25 15.6265 1.52982 15.6265 1.875V10.9334C14.5481 11.2108 13.7515 12.1898 13.7515 13.3547C13.7515 14.5197 14.5481 15.4987 15.6265 15.7761V18.125C15.6265 18.4702 15.9063 18.75 16.2515 18.75C16.5966 18.75 16.8765 18.4702 16.8765 18.125V15.7758C17.9542 15.498 18.7502 14.5192 18.7502 13.3547C18.7502 12.1903 17.9542 11.2115 16.8765 10.9337V1.875ZM15.0015 13.3547C15.0015 12.6642 15.561 12.1047 16.2508 12.1047C16.9406 12.1047 17.5002 12.6642 17.5002 13.3547C17.5002 14.0453 16.9406 14.6047 16.2508 14.6047C15.561 14.6047 15.0015 14.0453 15.0015 13.3547Z" />
</template>
