<template>
  <path
    fill="currentColor"
    d="M9.96509 11.3914C11.0045 11.3914 11.8261 11.0913 12.4595 10.6148C13.0849 10.1443 13.4928 9.52815 13.7593 8.94157C14.0257 8.35525 14.1608 7.77709 14.2301 7.35109C14.265 7.13655 14.2839 6.9566 14.2941 6.82816C14.2998 6.75662 14.3044 6.68489 14.306 6.61313L14.3061 6.6108C14.2301 5.88847 13.1353 5.78092 13.0563 6.58866C12.9772 7.39639 13.0562 6.59228 13.0562 6.59228C13.0562 6.59228 13.0559 6.59559 13.0553 6.6027C13.0501 6.66446 13.0233 6.98483 12.9963 7.15036C12.9385 7.50562 12.8282 7.96913 12.6213 8.42449C12.4145 8.87958 12.1213 9.30506 11.7081 9.61587C11.303 9.92061 10.7485 10.1414 9.96509 10.1414C9.18162 10.1414 8.63311 9.92067 8.23611 9.6182C7.83089 9.30946 7.54614 8.88641 7.34758 8.43254C7.14887 7.97836 7.04646 7.51565 6.99467 7.16057C6.96901 6.98463 6.95636 6.83915 6.95015 6.73977C6.94723 6.693 6.94489 6.6461 6.94425 6.59924C6.95836 5.75482 5.72304 5.75018 5.69425 6.60099C5.6944 6.65923 5.69625 6.71643 5.70259 6.81775C5.71062 6.94623 5.72645 7.12627 5.75776 7.34096C5.81991 7.76712 5.94536 8.34608 6.20238 8.93356C6.45954 9.52136 6.85839 10.14 7.47856 10.6125C8.10695 11.0913 8.92563 11.3914 9.96509 11.3914Z" />
  <path
    fill="currentColor"
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M4.12769 1.25C3.14057 1.25 2.32251 2.01534 2.25684 3.00028L1.34017 16.7503C1.26803 17.8325 2.1264 18.75 3.21102 18.75H16.7889C17.8735 18.75 18.7318 17.8325 18.6597 16.7503L17.743 3.00028C17.6774 2.01534 16.8593 1.25 15.8722 1.25H4.12769ZM3.50408 3.08343C3.52596 2.75511 3.79865 2.5 4.12769 2.5H15.8722C16.2012 2.5 16.4739 2.75511 16.4958 3.08343L17.4125 16.8334C17.4365 17.1942 17.1504 17.5 16.7889 17.5H3.21102C2.84948 17.5 2.56336 17.1942 2.58741 16.8334L3.50408 3.08343Z" />
</template>
