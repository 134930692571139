<template>
  <path
    fill="currentColor"
    d="M7.93945 8.50696C7.70841 8.2505 7.31322 8.2299 7.05676 8.46094C6.80031 8.69198 6.77971 9.08717 7.01075 9.34363L9.53596 12.1466C9.767 12.4031 10.1622 12.4237 10.4186 12.1926C10.6751 11.9616 10.6957 11.5664 10.4647 11.3099L7.93945 8.50696Z" />
  <path
    fill="currentColor"
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M7.78223 1.875C7.78223 1.52982 8.06205 1.25 8.40723 1.25H11.5952C11.9404 1.25 12.2202 1.52982 12.2202 1.875C12.2202 2.22018 11.9404 2.5 11.5952 2.5H10.6259V4.73563C11.9743 4.85609 13.2944 5.36721 14.4036 6.26899L14.8812 5.78997L14.8108 5.71935C14.5671 5.47491 14.5677 5.07918 14.8121 4.83547C15.0566 4.59176 15.4523 4.59236 15.696 4.8368L16.1938 5.33609L16.2052 5.34719C16.2099 5.35185 16.2145 5.35657 16.219 5.36135L16.7162 5.86002C16.9599 6.10446 16.9593 6.50019 16.7148 6.7439C16.4704 6.98761 16.0747 6.98701 15.831 6.74257L15.7638 6.67519L15.3003 7.14003C17.6797 9.89775 17.5636 14.0717 14.952 16.6912C12.2178 19.4336 7.78403 19.4336 5.04986 16.6912C2.31672 13.9498 2.31671 9.50593 5.04986 6.76457C6.25785 5.55295 7.79759 4.87663 9.37594 4.73562V2.5H8.40723C8.06205 2.5 7.78223 2.22018 7.78223 1.875ZM9.99391 5.95777L10.0009 5.95781L10.008 5.95777C11.4771 5.95956 12.9457 6.52268 14.0668 7.64713C16.3135 9.90065 16.3135 13.5551 14.0668 15.8086C11.821 18.0611 8.18078 18.0611 5.93507 15.8086C3.68831 13.5551 3.68831 9.90064 5.93507 7.64713C7.05615 6.52267 8.52476 5.95955 9.99391 5.95777Z" />
</template>
