<template>
  <path
    d="M17.9344 1.25539L2.08642 6.21612C2.02388 6.23696 1.98915 6.30644 2.00304 6.37592L3.22585 10.3987C3.2467 10.4612 3.31618 10.5029 3.37871 10.4821L15.0024 6.84142C15.5582 6.66772 16.0237 6.27865 16.2947 5.75756C17.0172 4.368 17.6148 2.92286 18.0733 1.42213C18.115 1.31097 18.0316 1.2276 17.9344 1.25539Z"
    fill="#FFBB1C" />
  <path
    d="M14.1335 8.20992L4.28848 11.2878C4.2329 11.3086 4.19816 11.3642 4.219 11.4198L5.25423 14.8173C5.27507 14.8729 5.33065 14.9076 5.37929 14.8868L11.6601 12.9205C12.1326 12.7746 12.5216 12.4411 12.7509 12.0034C13.3623 10.8292 13.8695 9.60642 14.2516 8.34192C14.2864 8.25855 14.2099 8.18212 14.1335 8.20992Z"
    fill="#E8486C" />
  <path
    d="M10.3886 14.4213L6.28246 15.7067C6.23383 15.7206 6.21299 15.7692 6.22688 15.8178L7.10231 18.6873C7.1162 18.7359 7.16484 18.7637 7.21347 18.7429L8.30428 18.4024C8.7003 18.2774 9.03379 17.9995 9.22833 17.6243C9.74942 16.6307 10.1732 15.6025 10.4998 14.5256C10.5206 14.463 10.4581 14.3935 10.3886 14.4213Z"
    fill="#476DF2" />
</template>
