<script lang="ts" setup>
import { computed } from 'vue';
import { icons } from '../assets/icons';

const props = withDefaults(
  defineProps<{
    name: keyof typeof icons;
    width?: string;
    height?: string;
    color?: string;
    viewBox?: string;
    customClass?: string;
  }>(),
  {
    width: '100%',
    viewBox: '0 0 20 20',
  },
);

const icon = computed(
  () => {
    return icons[props.name];
  },
  // defineAsyncComponent(() => {
  //   return new Promise<any>((resolve) => {
  //     const component = import(`../assets/icons/${props.name}.vue`);
  //     component
  //       .then(() => {
  //         resolve(component);
  //       })
  //       .catch(() => {
  //         resolve(import(`../assets/icons/icon-is-not-exist.vue`));
  //       });
  //   });
  // }),
);
</script>
<template>
  <svg
    :width="width"
    :height="height"
    :viewBox="viewBox"
    :style="{
      color,
    }"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    :class="customClass">
    <component :is="icon" :color="color" />
  </svg>
</template>
