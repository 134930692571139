<template>
  <rect x="1" y="1" width="4" height="4" fill="#E2E2E2" />
  <rect x="1" y="6" width="4" height="4" fill="#E2E2E2" />
  <rect x="1" y="11" width="4" height="4" fill="#E2E2E2" />
  <rect x="6" y="1" width="4" height="4" fill="#E2E2E2" />
  <rect x="6" y="6" width="4" height="4" fill="#E2E2E2" />
  <rect x="6" y="11" width="4" height="4" fill="#E2E2E2" />
  <rect x="11" y="1" width="4" height="4" fill="#E2E2E2" />
  <rect x="11" y="6" width="4" height="4" fill="#E2E2E2" />
  <rect x="11" y="11" width="4" height="4" fill="#E2E2E2" />
</template>
