<template>
  <path
    fill="currentColor"
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M10.0001 1.2749C10.3453 1.2749 10.6251 1.55472 10.6251 1.8999V3.2949C12.1143 3.42616 13.1679 3.98611 13.8971 4.86844C14.6925 5.831 15.0328 7.09927 15.2474 8.33167C15.3375 8.84965 15.4086 9.38339 15.4775 9.90147C15.4913 10.0053 15.5051 10.1087 15.5189 10.211C15.6026 10.8317 15.6888 11.4256 15.8096 11.9817C16.0539 13.1065 16.4191 13.972 17.0705 14.5231C17.2707 14.6925 17.3439 14.9689 17.2537 15.2152C17.1635 15.4615 16.9291 15.6252 16.6668 15.6252H3.33327C3.07982 15.6252 2.85146 15.4722 2.75515 15.2377C2.65884 15.0033 2.71366 14.7339 2.89392 14.5557C3.478 13.9784 3.8082 13.0812 4.03971 11.9259C4.15378 11.3567 4.23907 10.7534 4.32666 10.1251L4.33524 10.0636C4.41922 9.46093 4.50627 8.83632 4.6225 8.23124C4.86047 6.9925 5.23755 5.7314 6.06489 4.78604C6.81917 3.92418 7.8886 3.38836 9.37514 3.28391V1.8999C9.37514 1.55472 9.65496 1.2749 10.0001 1.2749ZM15.3536 14.3752H4.54849C4.90129 13.6996 5.11259 12.9338 5.26535 12.1716C5.38785 11.5602 5.47784 10.9207 5.56469 10.2977L5.57149 10.2489C5.65685 9.63649 5.73999 9.04 5.85006 8.46705C6.07847 7.27807 6.40615 6.29415 7.00554 5.60926C7.57529 4.95824 8.46016 4.5011 9.99418 4.51545C11.5267 4.52979 12.3882 5.00494 12.9335 5.66473C13.5097 6.36195 13.8081 7.35218 14.0159 8.54603C14.1013 9.03706 14.1685 9.54134 14.2375 10.0592C14.2515 10.1649 14.2657 10.2712 14.2801 10.378C14.364 11.0004 14.4558 11.6377 14.5881 12.247C14.7487 12.9868 14.9769 13.7251 15.3536 14.3752Z" />
  <path
    fill="currentColor"
    d="M8.34277 16.3447C8.67398 16.2475 9.02129 16.4372 9.11851 16.7684C9.36611 17.6118 10.6372 17.6153 10.8858 16.7684C10.9831 16.4372 11.3304 16.2475 11.6616 16.3447C11.9928 16.4419 12.1824 16.7893 12.0852 17.1205C11.4949 19.1313 8.52792 19.1943 7.91912 17.1205C7.8219 16.7893 8.01157 16.442 8.34277 16.3447Z" />
</template>
