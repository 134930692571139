<template>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M1 8C1 4.13401 4.13401 1 8 1C11.866 1 15 4.13401 15 8C15 11.866 11.866 15 8 15C4.13401 15 1 11.866 1 8ZM8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2Z"
    fill="currentColor" />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M8.14778 1.14233C8.34693 1.33361 8.35331 1.65013 8.16202 1.84929C6.82558 3.24069 5.95068 5.38232 5.95068 7.8089C5.95068 10.4443 6.98209 12.7404 8.51329 14.1075C8.71929 14.2914 8.73719 14.6075 8.55329 14.8134C8.36938 15.0194 8.0533 15.0373 7.84731 14.8534C6.07708 13.273 4.95068 10.6916 4.95068 7.8089C4.95068 5.15159 5.9073 2.75316 7.44081 1.15657C7.6321 0.957415 7.94862 0.951037 8.14778 1.14233Z"
    fill="currentColor" />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M7.85222 1.14233C7.65307 1.33361 7.64669 1.65013 7.83798 1.84929C9.17442 3.24069 10.0493 5.38232 10.0493 7.8089C10.0493 10.4443 9.01791 12.7404 7.48671 14.1075C7.28071 14.2914 7.26281 14.6075 7.44671 14.8134C7.63062 15.0194 7.9467 15.0373 8.15269 14.8534C9.92292 13.273 11.0493 10.6916 11.0493 7.8089C11.0493 5.15159 10.0927 2.75316 8.55919 1.15657C8.3679 0.957415 8.05138 0.951037 7.85222 1.14233Z"
    fill="currentColor" />
  <rect x="2.37744" y="4.1875" width="10.9702" height="1" fill="currentColor" />
  <rect x="1.43506" y="7.5" width="12.8552" height="1" fill="currentColor" />
  <rect x="2.37744" y="10.8125" width="10.9702" height="1" fill="currentColor" />
</template>
