<template>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M3.5 3C2.67157 3 2 3.67157 2 4.5V12.5C2 13.3284 2.67157 14 3.5 14H5.5C6.32843 14 7 13.3284 7 12.5V4.5C7 3.67157 6.32843 3 5.5 3H3.5ZM3 4.5C3 4.22386 3.22386 4 3.5 4H5.5C5.77614 4 6 4.22386 6 4.5V12.5C6 12.7761 5.77614 13 5.5 13H3.5C3.22386 13 3 12.7761 3 12.5V4.5Z"
    fill="#757575" />
  <path
    d="M11.8935 5.90392C12.0847 5.70462 12.4012 5.69799 12.6005 5.88913C12.8422 6.12093 13.4096 6.68843 13.9112 7.19249C14.1632 7.44571 14.4003 7.68471 14.5744 7.86037L14.8519 8.14047C14.9439 8.22943 15.0019 8.35339 15.0043 8.49091L15.0044 8.5002C15.0044 8.54389 14.9987 8.58758 14.9873 8.63006C14.9656 8.71118 14.9231 8.7879 14.8599 8.85182L14.5744 9.13998C14.4003 9.31562 14.1631 9.55457 13.9111 9.80776C13.4095 10.3117 12.8421 10.8792 12.6004 11.1109C12.4011 11.302 12.0846 11.2954 11.8935 11.096C11.7024 10.8967 11.709 10.5802 11.9084 10.3891C12.1396 10.1674 12.6972 9.60988 13.2024 9.10233L13.3042 9L8.50439 9C8.22825 9 8.00439 8.77614 8.00439 8.5C8.00439 8.22386 8.22825 8 8.50439 8L13.3039 8L13.2023 7.89787C12.6972 7.39024 12.1396 6.83264 11.9083 6.61087C11.709 6.41974 11.7024 6.10322 11.8935 5.90392Z"
    fill="#757575" />
</template>
