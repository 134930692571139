<script lang="ts" setup>
import type { Thumbnail } from '../types/image';

import { ref } from 'vue';
import GLoadingPlaceholder from './GLoadingPlaceholder.vue';

const props = defineProps<{
  src: Thumbnail['src'];
  originWidth: Thumbnail['width'];
  originHeight: Thumbnail['height'];
  width?: string;
  height?: string;
  inputClasses?: string;
  clsImage?: string;
  alt?: string;
  velocity?: number; // the value is calculated as a percentage of "1s" <<min: 0; max: 1>>
  offsetDistance?: number;
}>();
const image = ref();
const sectionImage = ref();
const onLoaded = ref<boolean>(false);
const isHover = ref<boolean>(false);
const transformTransition = ref({});

const initAnimation = () => {
  if (!isHover.value) {
    transformTransition.value = {};
    return;
  }
  const velocity = props?.velocity || 1;
  const offsetDistance = props?.offsetDistance || 0;
  const clientHeightImage = image.value?.clientHeight || 0;
  const clientHeightSectionImage = sectionImage.value.clientHeight - offsetDistance;
  const transformDelay = clientHeightImage / velocity;
  if (clientHeightImage >= clientHeightSectionImage) {
    transformTransition.value = {
      transform: `translateY(calc(-100% + ${clientHeightSectionImage}px))`,
      transition: `transform ${transformDelay}ms ease-in-out`,
    };
  }
};
//=================== Event ===================
const onHover = (value: boolean) => {
  isHover.value = value;
  initAnimation();
};
const onImgLoaded = (value: boolean) => {
  onLoaded.value = value;
};
</script>

<template>
  <div class="flex">
    <div
      ref="sectionImage"
      class="relative"
      :class="inputClasses"
      @mouseover="onHover(true)"
      @mouseleave="onHover(false)">
      <img
        v-if="src"
        ref="image"
        class="h-auto"
        :class="[{ hidden: !onLoaded }, clsImage]"
        :style="{ width, height, ...transformTransition }"
        :src="src"
        :alt="alt"
        @load="onImgLoaded(true)"
        @loadeddata="onImgLoaded(true)"
        @error="onImgLoaded(true)" />
      <div :class="{ hidden: onLoaded }" class="absolute inset-0 flex items-center justify-center bg-white">
        <g-loading-placeholder width="100%" height="100%" :is-show="onLoaded" />
      </div>
    </div>
  </div>
</template>
