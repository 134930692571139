<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7 3.18351C6.72386 3.18351 6.5 3.40736 6.5 3.68351C6.5 3.95965 6.72386 4.18351 7 4.18351H9C9.27614 4.18351 9.5 3.95965 9.5 3.68351C9.5 3.40736 9.27614 3.18351 9 3.18351H7Z"
      fill="currentColor" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5 1.5C4.17157 1.5 3.5 2.17157 3.5 3V13C3.5 13.8284 4.17157 14.5 5 14.5H11C11.8284 14.5 12.5 13.8284 12.5 13V3C12.5 2.17157 11.8284 1.5 11 1.5H5ZM4.5 3C4.5 2.72386 4.72386 2.5 5 2.5H11C11.2761 2.5 11.5 2.72386 11.5 3V13C11.5 13.2761 11.2761 13.5 11 13.5H5C4.72386 13.5 4.5 13.2761 4.5 13V3Z"
      fill="currentColor" />
  </svg>
</template>
