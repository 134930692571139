<script lang="ts" setup>
import { computed } from 'vue';
import type { ButtonType } from '../types/button';
import { cn } from '../helpers/common';

const emit = defineEmits(['click']);
const props = withDefaults(
  defineProps<{
    buttonType?: 'button' | 'submit';
    type?: ButtonType;
    size?: 'large' | 'medium' | 'small';
    loading?: boolean;
    disable?: boolean;
    onlyIcon?: boolean;
    buttonClasses?: string;
    class?: any;
    hideAttributeDisabled?: boolean;
    active?: boolean;
    loadingClass?: string;
  }>(),
  {
    buttonType: 'button',
    type: 'primary',
    size: 'large',
  },
);

//=================== Style ======================
const computedClass = computed(() => {
  const classes = [
    'flex',
    'whitespace-nowrap',
    'items-center',
    'justify-center',
    'font-medium',
    'rounded-medium',
    'transition-colors',
    'duration-200',
  ];
  if (props.loading) {
    classes.push('cursor-default');
  } else if (props.disable) {
    classes.push('!cursor-not-allowed');
  } else {
    classes.push('cursor-pointer');
  }
  switch (props.type) {
    case 'moreSetting':
      if (props.loading) {
        classes.push('bg-dark-400 border border-dark-400 text-dark-high');
      } else if (props.disable) {
        classes.push('bg-dark-400 border border-dark-400  text-dark-disabled');
      } else if (props.active) {
        classes.push('bg-primary-300 border border-primary-300 text-light-100');
      } else {
        classes.push('bg-dark-400 border border-dark-400 text-dark-high hover:bg-dark-100');
      }
      break;
    case 'primary':
      if (props.loading) {
        classes.push('bg-primary-300 border border-primary-300 text-light-100');
      } else if (props.disable) {
        classes.push('bg-light-300 border border-light-300 text-light-disabled');
      } else {
        classes.push('bg-primary-300 border border-primary-300 text-light-100 hover:bg-primary-325 ');
      }
      break;
    case 'secondary':
      if (props.loading) {
        classes.push(
          'dark:bg-dark-300 border border-dark-300 dark:text-light-100 bg-light-300 bg-light-300 text-light-high hover:bg-dark-high',
        );
      } else if (props.disable) {
        classes.push('dark:bg-dark-300 border border-dark-300 dark:text-dark-disabled bg-light-300');
      } else if (props.active) {
        classes.push(
          'dark:bg-dark-200 border border-dark-200 dark:text-light-100 dark:hover:bg-dark-200 bg-light-300 text-light-high hover:bg-dark-high',
        );
      } else {
        classes.push('bg-dark-300 text-dark-high border border-dark-300 hover:bg-dark-200 hover:border-dark-200');
      }
      break;

    case 'tertiary':
      if (props.loading) {
        classes.push(
          'relative before:-z-0 rounded-medium text-dark-high hover:before:bg-light-100/20 bg-dark-200 before:h-full before:w-full before:absolute before:top-0 before:left-0 before:z-[-1] before:rounded-medium',
        );
      } else if (props.disable) {
        classes.push('rounded-medium text-dark-high text-light-disabled');
      } else {
        classes.push(
          'relative before:z-0 rounded-medium text-dark-high hover:before:bg-light-100/20 bg-dark-200 before:h-full before:w-full before:absolute before:top-0 before:left-0 before:z-[-1] before:rounded-medium',
        );
      }
      break;
    case 'amber':
      if (props.loading) {
        classes.push(
          'relative before:!z-0 rounded-medium text-light-high hover:before:bg-light-100/20 bg-amber-300 before:h-full before:w-full before:absolute before:top-0 before:left-0 before:z-[-1] before:rounded-medium',
        );
      } else if (props.disable) {
        classes.push('rounded-medium text-light-disabled');
      } else {
        classes.push(
          'relative before:!z-0 rounded-medium text-light-high hover:before:bg-light-100/20 bg-amber-300 before:h-full before:w-full before:absolute before:top-0 before:left-0 before:z-[-1] before:rounded-medium',
        );
      }
      break;
    case 'darkGhost':
      if (props.loading) {
        classes.push(
          'relative before:-z-0 rounded-medium text-dark-high hover:before:bg-light-100/20 bg-dark-500 before:h-full before:w-full before:absolute before:top-0 before:left-0 before:z-[-1] before:rounded-medium',
        );
      } else if (props.disable) {
        classes.push('rounded-medium text-light-disabled');
      } else {
        classes.push(
          'relative before:z-0 rounded-medium text-dark-high hover:before:bg-light-100/20 bg-dark-500 before:h-full before:w-full before:absolute before:top-0 before:left-0 before:rounded-medium',
        );
      }
      break;
    case 'grey':
      if (props.loading) {
        classes.push('bg-light-300 border border-light-300 text-light-high');
      } else if (props.disable) {
        classes.push('bg-light-300 border border-light-300 text-light-disabled');
      } else {
        classes.push('bg-light-300 border border-light-300 text-light-high hover:bg-dark-high');
      }
      break;
    case 'outline':
      if (props.loading) {
        classes.push('border border-dark-100 bg-transparent text-light-high');
      } else if (props.disable) {
        classes.push('border border-dark-100 bg-transparent text-light-disabled');
      } else {
        classes.push('border border-dark-100 bg-transparent text-light-high hover:bg-dark-high');
      }
      break;
    case 'invert':
      if (props.loading) {
        classes.push(
          ' dark:bg-dark-400 dark:hover:bg-light-100  dark:hover:bg-opacity-20 hover:bg-dark-high  dark:text-dark-high bg-light-100 border border-light-100  text-light-high',
        );
      } else if (props.disable) {
        classes.push(
          'dark:bg-dark-400 border border-light-100  bg-light-100 dark:text-dark-disabled text-light-disabled',
        );
      } else {
        classes.push(
          'dark:bg-dark-400 dark:text-dark-high dark:hover:bg-light-100  dark:hover:bg-opacity-20  hover:bg-dark-high bg-light-100 border border-light-100 text-light-high',
        );
      }
      break;
    case 'ghost':
      if (props.loading) {
        classes.push('bg-transparent border border-transparent text-dark-high ');
      } else if (props.disable) {
        classes.push(
          'bg-transparent border border-transparent hover:bg-transparent hover:border-transparent text-dark-disabled',
        );
      } else {
        classes.push('bg-transparent border border-transparent text-dark-high hover:bg-light-100 hover:bg-opacity-20');
      }
      break;
    case 'danger':
      if (props.loading) {
        classes.push(
          'bg-red-300 relative before:z-0 text-light-100 before:h-full before:w-full before:absolute before:top-0 before:left-0 before:z-[-1] text-light-100 hover:before:bg-light-100/20',
        );
      } else if (props.disable) {
        classes.push('bg-light-high border border-light-high text-dark-disabled');
      } else {
        classes.push(
          'relative before:z-0 bg-red-300 before:h-full before:w-full before:absolute before:top-0 before:left-0 before:z-[-1] text-light-100 hover:before:bg-light-100/20 hover:text-dark-high',
        );
      }
      break;
    case 'dangerGhost':
      if (props.loading) {
        classes.push('bg-transparent border border-transparent text-red-300');
      } else if (props.disable) {
        classes.push('bg-transparent border border-transparent text-dark-disabled');
      } else {
        classes.push('bg-transparent border border-transparent text-red-300 hover:bg-red-400 hover:text-dark-high');
      }
      break;
  }
  switch (props.size) {
    case 'large':
      classes.push('h-40 text-14 leading-normal');

      if (!props.onlyIcon) {
        classes.push('px-16');
      }
      break;
    case 'medium':
      classes.push('h-36 text-14 leading-normal');

      if (!props.onlyIcon) {
        classes.push('px-16');
      }
      break;
    case 'small':
      classes.push('h-24 text-12 leading-normal');

      if (!props.onlyIcon) {
        classes.push('px-16');
      }
      break;
  }
  if (props.onlyIcon) {
    classes.push('aspect-square');
  }
  return classes.join(' ');
});

//=================== Methods =====================
const handleClick = (e: Event) => {
  if (props.loading) {
    return;
  }
  emit('click', e);
};
</script>
<template>
  <button
    :data-type="type"
    :type="buttonType"
    :class="
      cn(
        'cursor-pointer select-none outline-none disabled:cursor-not-allowed',
        computedClass,
        buttonClasses,
        props.class,
      )
    "
    :disabled="disable && !hideAttributeDisabled"
    @click="handleClick">
    <span
      v-if="loading"
      :class="
        cn(
          {
            'mr-8': !onlyIcon,
            'w-16': size == 'large',
            'w-14': size == 'medium',
            'w-12': size == 'small',
          },
          loadingClass,
        )
      ">
      <svg class="animate-spin" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M10 0C10.6497 0 11.1765 0.526724 11.1765 1.17647V4.11765C11.1765 4.76739 10.6497 5.29412 10 5.29412C9.35025 5.29412 8.82353 4.76739 8.82353 4.11765V1.17647C8.82353 0.526724 9.35025 0 10 0Z"
          fill="currentColor" />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M10 14.7059C10.6497 14.7059 11.1765 15.2326 11.1765 15.8824V18.8235C11.1765 19.4733 10.6497 20 10 20C9.35025 20 8.82353 19.4733 8.82353 18.8235V15.8824C8.82353 15.2326 9.35025 14.7059 10 14.7059Z"
          fill="currentColor" />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M2.93281 2.92497C3.39225 2.46553 4.13715 2.46553 4.59659 2.92497L6.67502 5.0034C7.13446 5.46284 7.13446 6.20774 6.67502 6.66718C6.21558 7.12662 5.47068 7.12662 5.01124 6.66718L2.93281 4.58875C2.47337 4.12931 2.47337 3.38441 2.93281 2.92497Z"
          fill="currentColor" />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M13.325 13.325C13.7844 12.8655 14.5293 12.8655 14.9887 13.325L17.0672 15.4034C17.5266 15.8628 17.5266 16.6077 17.0672 17.0672C16.6077 17.5266 15.8628 17.5266 15.4034 17.0672L13.325 14.9887C12.8655 14.5293 12.8655 13.7844 13.325 13.325Z"
          fill="currentColor" />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0 10C0 9.35025 0.526724 8.82353 1.17647 8.82353H4.11765C4.76739 8.82353 5.29412 9.35025 5.29412 10C5.29412 10.6497 4.76739 11.1765 4.11765 11.1765H1.17647C0.526724 11.1765 0 10.6497 0 10Z"
          fill="currentColor" />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M14.7059 10C14.7059 9.35025 15.2326 8.82353 15.8824 8.82353H18.8235C19.4733 8.82353 20 9.35025 20 10C20 10.6497 19.4733 11.1765 18.8235 11.1765H15.8824C15.2326 11.1765 14.7059 10.6497 14.7059 10Z"
          fill="currentColor" />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M6.67502 13.325C7.13446 13.7844 7.13446 14.5293 6.67502 14.9887L4.59659 17.0672C4.13715 17.5266 3.39225 17.5266 2.93281 17.0672C2.47337 16.6077 2.47337 15.8628 2.93281 15.4034L5.01124 13.325C5.47068 12.8655 6.21558 12.8655 6.67502 13.325Z"
          fill="currentColor" />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M17.0672 2.92497C17.5266 3.38441 17.5266 4.12931 17.0672 4.58875L14.9887 6.66718C14.5293 7.12662 13.7844 7.12662 13.325 6.66718C12.8655 6.20774 12.8655 5.46284 13.325 5.0034L15.4034 2.92497C15.8628 2.46553 16.6077 2.46553 17.0672 2.92497Z"
          fill="currentColor" />
      </svg>
    </span>
    <template v-if="!loading || !onlyIcon">
      <slot name="prefix"></slot>
      <slot></slot>
      <slot name="suffix"></slot>
    </template>
  </button>
</template>
