<template>
  <path
    d="M8.96428 7.89497C9.37294 7.27544 10.2903 7.30127 10.6634 7.94281L13.1609 12.2366C13.2998 12.4753 13.2188 12.7814 12.9801 12.9202C12.7414 13.0591 12.4354 12.9781 12.2965 12.7394L9.79903 8.44559L7.91994 11.2943C7.84024 11.4152 7.71208 11.4955 7.56859 11.5146C7.4251 11.5337 7.28037 11.4897 7.17181 11.394L5.87004 10.2457L4.16962 12.7676C4.01524 12.9965 3.70449 13.057 3.47553 12.9026C3.24657 12.7482 3.18611 12.4375 3.34049 12.2085L5.04091 9.68666C5.37835 9.1862 6.07888 9.09649 6.53154 9.49577L7.40193 10.2635L8.96428 7.89497Z"
    fill="currentColor" />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M5.00488 3.49991C4.03838 3.49991 3.25488 4.28341 3.25488 5.24991C3.25488 6.21641 4.03838 6.99991 5.00488 6.99991C5.97138 6.99991 6.75488 6.21641 6.75488 5.24991C6.75488 4.28341 5.97138 3.49991 5.00488 3.49991ZM4.25488 5.24991C4.25488 4.83569 4.59067 4.49991 5.00488 4.49991C5.4191 4.49991 5.75488 4.83569 5.75488 5.24991C5.75488 5.66412 5.4191 5.99991 5.00488 5.99991C4.59067 5.99991 4.25488 5.66412 4.25488 5.24991Z"
    fill="currentColor" />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M1 2.5C1 1.67157 1.67157 1 2.5 1H13.5C14.3284 1 15 1.67157 15 2.5V13.5C15 14.3284 14.3284 15 13.5 15H2.5C1.67157 15 1 14.3284 1 13.5V2.5ZM2.5 2C2.22386 2 2 2.22386 2 2.5V13.5C2 13.7761 2.22386 14 2.5 14H13.5C13.7761 14 14 13.7761 14 13.5V2.5C14 2.22386 13.7761 2 13.5 2H2.5Z"
    fill="currentColor" />
</template>
