<template>
  <path
    fill="currentColor"
    d="M14.2527 7.19204C14.4696 6.92351 14.4278 6.53 14.1592 6.31311C13.8907 6.09622 13.4972 6.13807 13.2803 6.4066L8.76646 11.995L6.63613 10.0592C6.38067 9.82705 5.98539 9.84595 5.75326 10.1014C5.52112 10.3569 5.54003 10.7522 5.79549 10.9843L8.41609 13.3656C8.54344 13.4813 8.71286 13.5394 8.88442 13.5262C9.05598 13.513 9.2145 13.4296 9.32262 13.2958L14.2527 7.19204Z" />
  <path
    fill="currentColor"
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M3.81282 3.81282C7.22991 0.395728 12.7701 0.395728 16.1872 3.81282C19.6043 7.22991 19.6043 12.7701 16.1872 16.1872C12.7701 19.6043 7.22991 19.6043 3.81282 16.1872C0.395728 12.7701 0.395728 7.22991 3.81282 3.81282ZM4.6967 4.6967C7.62563 1.76777 12.3744 1.76777 15.3033 4.6967C18.2322 7.62563 18.2322 12.3744 15.3033 15.3033C12.3744 18.2322 7.62563 18.2322 4.6967 15.3033C1.76777 12.3744 1.76777 7.62563 4.6967 4.6967Z" />
</template>
