<script lang="ts" setup>
import GLoadingPlaceholder from '../base/GLoadingPlaceholder.vue';
import GButton from '../base/GButton.vue';
import GBaseIcon from '../base/GBaseIcon.vue';
import GImage from '../base/GImage.vue';

const VELOCITY = 0.325;
const OFFSET_DISTANCE = 48;

defineProps<{
  isLoading?: boolean;
  isPreview?: boolean;
  section: {
    name: string;
    thumbnail: {
      src: string;
      width: number;
      height: number;
    };
  };
  isSelectLoading?: boolean;
  isPreviewLoading?: boolean;
}>();

const heightCalculator = {
  xl: 315,
  lg: 324,
  md: 324,
};

// Emit
const emit = defineEmits<{
  (e: 'select'): void;
  (e: 'preview'): void;
}>();

const handleSelect = () => {
  emit('select');
};
const handlePreview = () => {
  emit('preview');
};
</script>
<template>
  <div
    class="rounded-medium bg-light-100 z-5 group relative flex h-auto w-full flex-col overflow-hidden transition-all delay-[0.25s]">
    <g-image
      input-classes="w-full cursor-pointer min-h-[120px] z-[1] shrink grow max-h-[324px] xl:max-h-[315px] "
      width="100%"
      :src="section.thumbnail.src"
      :origin-width="section.thumbnail.width"
      :origin-height="section.thumbnail.height"
      :height-calculator="heightCalculator"
      :velocity="VELOCITY"
      :offset-distance="OFFSET_DISTANCE" />
    <div v-if="isLoading" class="absolute inset-0 flex items-center justify-center bg-white">
      <g-loading-placeholder width="100%" height="100%"></g-loading-placeholder>
    </div>
    <div
      v-if="!isLoading"
      class="bg-light-200 absolute bottom-[-48px] left-0 z-[2] flex h-[48px] w-full items-center overflow-hidden border-t py-8 px-12 transition-all duration-200 group-hover:bottom-0">
      <div
        class="text-light-high text-14 mt-8 inline-block h-32 max-w-[calc(100%-135px)] truncate align-middle font-medium">
        {{ section.name }}
      </div>
      <div class="ml-auto"></div>
      <g-button
        v-if="isPreview"
        class="mr-8"
        :loading="isPreviewLoading"
        type="grey"
        size="medium"
        :only-icon="true"
        @click.stop="handlePreview">
        <template #prefix>
          <g-base-icon
            name="library-eye-preview"
            class="text-light-high"
            width="16px"
            height="16px"
            view-box="0 0 16 16"></g-base-icon>
        </template>
      </g-button>
      <g-button type="primary" size="medium" :loading="isSelectLoading" @click.stop="handleSelect"
        >Add to page</g-button
      >
    </div>
  </div>
</template>
