<template>
  <path
    d="M4.63388 9.11612C5.12204 9.60427 5.12204 10.3957 4.63388 10.8839C4.14573 11.372 3.35427 11.372 2.86612 10.8839C2.37796 10.3957 2.37796 9.60427 2.86612 9.11612C3.35427 8.62796 4.14573 8.62796 4.63388 9.11612Z"
    fill="currentColor" />
  <path
    d="M17.1339 9.11612C17.622 9.60427 17.622 10.3957 17.1339 10.8839C16.6457 11.372 15.8543 11.372 15.3661 10.8839C14.878 10.3957 14.878 9.60427 15.3661 9.11612C15.8543 8.62796 16.6457 8.62796 17.1339 9.11612Z"
    fill="currentColor" />
  <path
    d="M10.8839 10.8839C11.372 10.3957 11.372 9.60427 10.8839 9.11612C10.3957 8.62796 9.60427 8.62796 9.11612 9.11612C8.62796 9.60427 8.62796 10.3957 9.11612 10.8839C9.60427 11.372 10.3957 11.372 10.8839 10.8839Z"
    fill="currentColor" />
</template>
