<template>
  <path
    fill="currentColor"
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M1.875 3.375C1.875 2.54657 2.54657 1.875 3.375 1.875H7.6875C8.51593 1.875 9.1875 2.54657 9.1875 3.375V7.6875C9.1875 8.51593 8.51593 9.1875 7.6875 9.1875H3.375C2.54657 9.1875 1.875 8.51593 1.875 7.6875V3.375ZM3.375 3.125H7.6875C7.82557 3.125 7.9375 3.23693 7.9375 3.375V7.6875C7.9375 7.82557 7.82557 7.9375 7.6875 7.9375H3.375C3.23693 7.9375 3.125 7.82557 3.125 7.6875V3.375C3.125 3.23693 3.23693 3.125 3.375 3.125Z" />
  <path
    fill="currentColor"
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M1.875 12.3127C1.875 11.4843 2.54657 10.8127 3.375 10.8127H7.6875C8.51593 10.8127 9.1875 11.4843 9.1875 12.3127V16.6252C9.1875 17.4537 8.51593 18.1252 7.6875 18.1252H3.375C2.54657 18.1252 1.875 17.4537 1.875 16.6252V12.3127ZM3.375 12.0627H7.6875C7.82557 12.0627 7.9375 12.1747 7.9375 12.3127V16.6252C7.9375 16.7633 7.82557 16.8752 7.6875 16.8752H3.375C3.23693 16.8752 3.125 16.7633 3.125 16.6252V12.3127C3.125 12.1747 3.23693 12.0627 3.375 12.0627Z" />
  <path
    fill="currentColor"
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M12.3125 1.875C11.4841 1.875 10.8125 2.54657 10.8125 3.375V7.6875C10.8125 8.51593 11.4841 9.1875 12.3125 9.1875H16.625C17.4534 9.1875 18.125 8.51593 18.125 7.6875V3.375C18.125 2.54657 17.4534 1.875 16.625 1.875H12.3125ZM16.625 3.125H12.3125C12.1744 3.125 12.0625 3.23693 12.0625 3.375V7.6875C12.0625 7.82557 12.1744 7.9375 12.3125 7.9375H16.625C16.7631 7.9375 16.875 7.82557 16.875 7.6875V3.375C16.875 3.23693 16.7631 3.125 16.625 3.125Z" />
  <path
    fill="currentColor"
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M10.8125 12.3127C10.8125 11.4843 11.4841 10.8127 12.3125 10.8127H16.625C17.4534 10.8127 18.125 11.4843 18.125 12.3127V16.6252C18.125 17.4537 17.4534 18.1252 16.625 18.1252H12.3125C11.4841 18.1252 10.8125 17.4537 10.8125 16.6252V12.3127ZM12.3125 12.0627H16.625C16.7631 12.0627 16.875 12.1747 16.875 12.3127V16.6252C16.875 16.7633 16.7631 16.8752 16.625 16.8752H12.3125C12.1744 16.8752 12.0625 16.7633 12.0625 16.6252V12.3127C12.0625 12.1747 12.1744 12.0627 12.3125 12.0627Z" />
</template>
