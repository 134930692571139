import type { Ref } from 'vue';
import { computed } from 'vue';
import type { DomainVerification } from '../../../types/domain';
import removeWWWOrHttps from '../utils/removeWWWOrHttps';
import { isFirstTLD, isSecondTLD } from '../utils/tld';
import type { Maybe } from '../../../types/common';
export const useDNSInfo = (
  domain: Ref<string>,
  errorVerification?: Ref<Maybe<DomainVerification>[] | undefined>,
  dnsInfo?: { dns?: string; ip?: string },
) => {
  const DEFAULT_DNS = 'stores.gempages.live';
  const DEFAULT_IP = '76.76.21.21';

  const domainInfo = computed(() => getDNSDomainInfo());

  const getDNSDomainInfo = () => {
    if (errorVerification?.value?.length) {
      return getConfigAddingNewDns();
    }
    const name = getDomainName();
    return [
      { label: 'Type', value: name === '@' ? 'A' : 'CNAME' },
      { label: 'Name', value: name },
      { label: 'Value', value: name === '@' ? `${dnsInfo?.ip || DEFAULT_IP}` : `${dnsInfo?.dns || DEFAULT_DNS}` },
    ];
  };

  const getConfigAddingNewDns = () => {
    const dnsConfig = errorVerification?.value?.[0];
    const splitDomains = dnsConfig?.domain?.split('.');
    const domainName = splitDomains?.length ? splitDomains[0] : '';
    return [
      { label: 'Type', value: dnsConfig?.type },
      { label: 'Name', value: domainName },
      { label: 'Value', value: dnsConfig?.value || DEFAULT_DNS },
    ];
  };

  const getDomainName = () => {
    const lowerCaseAndTrimDomain = domain.value.toLowerCase().trim();
    const parts = removeWWWOrHttps(lowerCaseAndTrimDomain).split('.');
    if (parts.length <= 2) {
      return '@';
    }
    const checkFirstTLD = isFirstTLD(parts[parts.length - 1]);
    const checkSecondTLD = isSecondTLD(parts[parts.length - 2]);
    if (checkFirstTLD && checkSecondTLD) {
      return getTwoTLDsDomainName(parts);
    }
    if ((checkFirstTLD && !checkSecondTLD) || !checkFirstTLD) {
      return getOneTLDDomainName(parts);
    }
    return '@';
  };

  const getTwoTLDsDomainName = (splitDots: string[]) => {
    if (splitDots.length === 3) {
      return '@';
    }
    splitDots.splice(splitDots.length - 3, 3);
    return splitDots.join('.');
  };

  const getOneTLDDomainName = (splitDots: string[]) => {
    splitDots.splice(splitDots.length - 2, 2);
    return splitDots.join('.');
  };

  return { domainInfo };
};
