<template>
  <rect
    x="17.375"
    y="2.625"
    width="14.75"
    height="14.75"
    transform="rotate(90 17.375 2.625)"
    stroke="#E2E2E2"
    stroke-width="1.25" />
</template>
